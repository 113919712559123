import React, { useState, useLayoutEffect } from "react";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Button,
  FormCheckbox,
  FormInput,
} from "shards-react";
import logo from "../../images/logo.png";
import IMAGE_ASSETS from "../../assets/image-list";
import Footer from "./Footer";
import { Link, useHistory } from "react-router-dom";
import { Jumbotron } from "react-bootstrap";

const Feature = () => {
  const history = useHistory();

  return (
    <div className="bg-white">
      <section id="section1" style={{ backgroundColor: "#FFF" }}>
        <div
          className="pt-5 pb-5"
          style={{
            backgroundImage: `url("${IMAGE_ASSETS.src.featuresBg}")`,
            backgroundSize: "cover",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div>
                  <h2
                    style={{
                      fontWeight: 700,
                      color: "#FFF",
                      lineHeight: "57px",
                      textAlign: "center",
                    }}
                  >
                    QuoteShark Features to Boost Your Business
                  </h2>
                  <h4
                    style={{
                      color: "#E5E5E5",
                      fontWeight: 300,
                      fontStyle: "normal",
                      textAlign: "center",
                    }}
                  >
                    <span>
                      Features that give you the tools to take your business{" "}
                      <br />
                      to the next level and help you be more productive!
                    </span>
                  </h4>

                  <center>
                    <button
                      className="call-to-action-main"
                      onClick={() => {
                        history.push("/sign-up");
                      }}
                    >
                      Sign Up Now
                    </button>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="section2"
        className="pt-4 pb-3"
        style={{ backgroundColor: "#FFF" }}
      >
        <br />
        <div className="container">
          <div className="row">
            <div
              className="col-12 col-md-6 col-lg-6  justify-content-center align-items-center"
              style={{ marginBottom: 30 }}
            >
              <img
                className=""
                src={IMAGE_ASSETS.src.lookProfessional2}
                style={{ width: "100%", marginTop: "-17%" }}
              ></img>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <div>
                <h2
                  style={{
                    fontWeight: 700,
                    color: "#007DC4",
                    lineHeight: "57px",
                  }}
                >
                  Stay On Brand, <br />
                  Look Professional
                </h2>
                <h6
                  style={{
                    color: "#000",
                    fontWeight: 300,
                    fontStyle: "normal",
                  }}
                >
                  <span>
                    Impress your customers with beautiful, branded quotes. A
                    quotes that designed for your business. Make your quotes
                    look as professional as your business. Our quotes are
                    designed to be easily understood and look great. Simply
                    choose an invoice template and add your logo.
                  </span>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="section3"
        className="pt-4 pb-3"
        style={{ backgroundColor: "#FFF" }}
      >
        <br />
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 col-md-6 col-sm-12 order-lg-2 order-md-2  justify-content-center align-items-center"
              style={{ marginBottom: 30 }}
            >
              <img
                className=""
                src={IMAGE_ASSETS.src.accessSecurely1}
                style={{ width: "100%", marginTop: "-17%" }}
              ></img>
            </div>
            <div className="col-12 col-md-6 col-lg-6 col-sm-12">
              <div>
                <h2
                  style={{
                    fontWeight: 700,
                    color: "#007DC4",
                    lineHeight: "57px",
                  }}
                >
                  Access Securely From <br /> Any Device, Anywhere
                </h2>
                <h6
                  style={{
                    color: "#000",
                    fontWeight: 300,
                    fontStyle: "normal",
                  }}
                >
                  <span>
                    Do your quoting wherever suits you—on your phone, tablet, or
                    computer. Your account is always connected and your quotes,
                    customers and settings are saved securely to our system.
                    Never lose business because of lost data. You’re free to
                    switch phones or devices any time.
                  </span>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="section2"
        className="pt-4 pb-3"
        style={{ backgroundColor: "#FFF" }}
      >
        <br />
        <div className="container">
          <div className="row">
            <div
              className="col-12 col-md-6 col-lg-6  justify-content-center align-items-center"
              style={{ marginBottom: 30 }}
            >
              <img
                className=""
                src={IMAGE_ASSETS.src.saveTimeInvoice}
                style={{ width: "100%", marginTop: "-17%" }}
              ></img>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <div>
                <h2
                  style={{
                    fontWeight: 700,
                    color: "#007DC4",
                    lineHeight: "57px",
                  }}
                >
                  Save Time With <br />
                  Saved Invoice Items
                </h2>
                <h6
                  style={{
                    color: "#000",
                    fontWeight: 300,
                    fontStyle: "normal",
                  }}
                >
                  <span>
                    Avoid typing the same things over and over. Save invoice
                    items and customer details as you go so you can re-use them
                    later. Edit them at any time. Get started fast by importing
                    your phone contacts and use predictive typing to quickly
                    fill in quotes.
                  </span>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="section4"
        className="pt-4 pb-3"
        style={{ backgroundColor: "#FFF" }}
      >
        <br />
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 col-md-6 col-sm-12 order-lg-2 order-md-2 justify-content-center align-items-center"
              style={{ marginBottom: 30 }}
            >
              <img
                className=""
                src={IMAGE_ASSETS.src.accessSecurely2}
                style={{ width: "100%", marginTop: "-17%" }}
              ></img>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <div>
                <h2
                  style={{
                    fontWeight: 700,
                    color: "#007DC4",
                    lineHeight: "57px",
                  }}
                >
                  Create Quotes Instantly
                </h2>
                <h6
                  style={{
                    color: "#000",
                    fontWeight: 300,
                    fontStyle: "normal",
                  }}
                >
                  <span>
                    Get your quoting done in minutes, not hours. Create quotes
                    on the road or at home. Quickly add details such as
                    discounts, payment instructions, due dates, shipping details
                    and more. Turn estimates into invoices with one click.
                  </span>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="section5"
        className="pt-4 pb-3"
        style={{ backgroundColor: "#FFF" }}
      >
        <br />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div>
                <h2
                  style={{
                    fontWeight: 700,
                    color: "#007DC4",
                    lineHeight: "57px",
                    textAlign: "center",
                  }}
                >
                  Instant Notifications
                </h2>
                <h4
                  style={{
                    color: "#676363",
                    fontWeight: 300,
                    fontStyle: "normal",
                    textAlign: "center",
                  }}
                >
                  <span>
                    Know when a quote is read, accpeted, rejected or <br />{" "}
                    expired, so you can stay on top of your cash flow <br />{" "}
                    better than ever.
                  </span>
                </h4>

                <img
                  className=""
                  src={IMAGE_ASSETS.src.instantNotification}
                  style={{ width: "100%" }}
                ></img>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="section6"
        className="pt-4 pb-3"
        style={{ backgroundColor: "#FFF" }}
      >
        <br />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div>
                <h2
                  style={{
                    fontWeight: 700,
                    color: "#007DC4",
                    lineHeight: "57px",
                    textAlign: "center",
                  }}
                >
                  Professional quoting, but that's not all!
                </h2>
                <h4
                  style={{
                    color: "#676363",
                    fontWeight: 300,
                    fontStyle: "normal",
                    textAlign: "center",
                  }}
                >
                  <span>
                    QuoteShark Quoting is great, but it’s even greater <br />{" "}
                    when used with the rest of QuoteShark.
                  </span>
                </h4>

                <img
                  className=""
                  src={IMAGE_ASSETS.src.professionalQuoting1}
                  style={{ width: "100%" }}
                ></img>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Feature;
