/* eslint-disable no-use-before-define */
import React, {useState, useEffect} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Field, useFormikContext } from 'formik'

import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

const filter = createFilterOptions();

export default function InlineSmartSearch(props) {
    const [value, setValue] = useState("");
    const { errors, touched, values, setFieldValue, handleChange } = useFormikContext()


    const textStyles = {fontSize: 13, color: "#495057", fontWeight: "normal"}
    const labelStyles = {fontSize: 13}
    useEffect(() => {
        setValue(values)
    }, [])

    return (
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
            console.log(newValue)
          if (typeof newValue === 'string') {
            setFieldValue("name", newValue)
            setValue({
              customer_name: newValue,
            });
          } else if (newValue && newValue.customer_name) {
            // Create a new value from the user input
            setFieldValue("customer_id", newValue.id)
            setFieldValue("name", newValue.customer_name)
            setFieldValue("email", newValue.email)
            setFieldValue("address", newValue.customer_address)
            setValue({
              customer_name: newValue.customer_name,
            });
          } else {
            setFieldValue("name", newValue)
            setValue(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          // Suggest the creation of a new value
          if (params.inputValue !== '') {
            filtered.push({
              inputValue: params.inputValue,
              customer_name: `${params.inputValue}`,
            });
          }

          return filtered;
        }}
        // selectOnFocus
        // clearOnBlur
        // handleHomeEndKeys
        id="free-solo-with-text-demo"
        options={props.customers ? props.customers : []}
        getOptionLabel={(option) => {
          return option.customer_name;
        }}
        renderOption={(option) => option.customer_name}
        style={{ width: "100%", ...textStyles }}
        freeSolo
        renderInput={(params) => (
          <>
           <input type="hidden" value="something"/>
          <Field {...params}  autoComplete="customer_name"  onChange={(e) => {
            setFieldValue("name", e.target.value)
            setFieldValue("customer_id", 0)
            setValue(e.target.value);
          }}/>
          </>
        )}
      />
    );
  }
