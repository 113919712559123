import React, {useContext ,useState, useEffect} from "react";
import { UserContext } from '../../providers/UserProvider';
import TextFieldPercentage from '../common/TextFieldPercentage';
import currencies from '../common/CurrenciesList';
import PropTypes from "prop-types";
import { Link, useHistory } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import MaskedInput from 'react-text-mask';
import MenuItem from '@mui/material/MenuItem';
import NumberFormat from 'react-number-format';
import { object, string, boolean, array, number } from 'yup'
import { ConnectedFocusError } from "focus-formik-error";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  FormCheckbox,
  Button
} from "shards-react";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import {Form as ReactForm} from 'react-bootstrap';

const UserAccountDetails = ({ title}) => {
  const {user, updateProfile, setCompleteProfileInformation} = useContext(UserContext)
  const [isChecked, setIsChecked] = useState(false)
  const history = useHistory();

  const setIsVat = (val) => {
    setIsChecked(val)
    return val;
  }

  useEffect(() => {
    setIsVat(user.data.is_vat)
  }, [])

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const ValidationSchema = object().shape({
    business_name: string().max(255, 'Text is Too Long!').required('Business Name is Required').typeError('Business name is required.'),
    name: string().max(255, 'Text is Too Long!').required('Name is Required').typeError('Name is required.'),
    email: string().max(255, 'Text is Too Long!').required('Email is Required'),
    contact_number: string().min(6, "Phone number is not valid").max(20, "Phone number is not valid").required('Phone is Required'),
    business_address: string().max(255, 'Text is Too Long!').required('Business Address is Required').typeError('Business Address is required.'),
  });

  return (
    <Formik
      data-testid="formikForm"
      id="profile-info"
      initialValues={{
        business_name: user.data.business_name ? user.data.business_name : "",
        name: user.data.name ? user.data.name : "",
        email: user.data.email,
        contact_number: user.data.contact_number ? user.data.contact_number: "",
        website: user.data.website ? user.data.website : "",
        business_address: user.data.business_address ? user.data.business_address : "",
        business_registration_number: user.data.business_registration_number,
        is_vat: isChecked,
        date_format_id: user.data.date_format_id ? user.data.date_format_id : 1,
        tax_label_id: user.data.tax_label_id ? user.data.tax_label_id : 1,
        vat_rate: user.data.vat_rate ? user.data.vat_rate : 0,
        currency_id: user.data.currency_id ? user.data.currency_id : 1,
        quote_expire_in_days: user.data.quote_expire_in_days ? user.data.quote_expire_in_days : 30,
        terms_is_deposit_required: user.data.terms_is_deposit_required  ? "true" : "false",
        terms_is_deposit_fixed: user.data.terms_is_deposit_fixed ? "true" : "false",
        terms_deposit_percentage: user.data.terms_deposit_percentage ? user.data.terms_deposit_percentage : 0,
        terms_deposit_amount: user.data.terms_deposit_amount ? user.data.terms_deposit_amount : 0,
        terms_other_conditions: user.data.terms_other_conditions ? user.data.terms_other_conditions : "",
        email_read: user.data.email_read,
        email_accepted: user.data.email_accepted,
        email_rejected: user.data.email_rejected,
        email_expired: user.data.email_expired,
        email_newsletter: user.data.email_newsletter
      }}
      validationSchema={ValidationSchema}
      onSubmit={async (values) => {
        setCompleteProfileInformation(true);
        var res = updateProfile(values, user.data.auth_token, isChecked);
        if(res){
          history.push("/dashboard")
        }
      }}
    >
    {({handleSubmit, handleChange, values, touched, errors, setFieldValue}) => (<Card className="mb-4">
      <CardHeader className="border-bottom">
        <h6 className="m-0">{title}</h6>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <Form className={"form"}>
              <ConnectedFocusError />
                <Row>
                  <Col className="form-group">
                  <Field
                    data-testid="inputBusinessName"
                    autoFocus={true}
                    variant="outlined"
                    type="text"
                    as={TextField}
                    id="business_name"
                    name="business_name"
                    label={<span>Business Name<span className="text-danger"> *</span></span>}
                    className={`form-control input ${errors.business_name ? "input-error" : ""}`}
                      onChange={(e) => {setFieldValue("business_name", e.target.value)}}
                  />
                  <small style={{ color: 'red' }}>
                    {touched.business_name && errors.business_name}
                  </small>
                  </Col>
                </Row>
                <Row>
                  <Col className="form-group">
                  <Field
                    variant="outlined"
                    type="text"
                    as={TextField}
                    id="name"
                    name="name"
                    label={<span>Name<span className="text-danger"> *</span></span>}
                    className={`form-control input ${errors.name ? "input-error" : ""}`}
                      onChange={(e) => {setFieldValue("name", e.target.value)}}
                  />
                  <small style={{ color: 'red' }}>
                    {touched.name && errors.name}
                  </small>
                  </Col>
                </Row>
                <Row>
                  <Col className="form-group">
                      <TextField  type="text" as={TextField} id="email" label={<span>Email<span className="text-danger"> *</span></span>}     className={`form-control input ${errors.name ? "input-error" : ""}`}  variant="outlined" readOnly disabled value={values.email ? values.email : ""} />
                      <small style={{ color: 'red' }}>
                        {touched.email && errors.email}
                      </small>
                  </Col>
                </Row>
                <Row>
                  <Col className="form-group">
                  <Field
                    variant="outlined"
                    type="text"
                    as={TextField}
                    id="contact_number"
                    name="contact_number"
                    label={<span>Phone<span className="text-danger"> *</span></span>}
                    className={`form-control input ${errors.contact_number ? "input-error" : ""}`}
                      onChange={(e) => {setFieldValue("contact_number", e.target.value)}}
                  />
                      <small style={{ color: 'red' }}>
                        {touched.contact_number && errors.contact_number}
                      </small>
                  </Col>
                </Row>
                <Row>
                  <Col className="form-group">
                      <TextField  id="website" label="Website"   className={`form-control input ${errors.name ? "input-error" : ""}`}  variant="outlined" value={values.website ? values.website : ""}   onChange={(e) => {setFieldValue("website", e.target.value)}}/>
                  </Col>
                </Row>
                <Row>
                  <Col className="form-group">
                  <Field
                    variant="outlined"
                    type="text"
                    as={TextField}
                    id="business_address"
                    name="business_address"
                    label={<span>Business Address<span className="text-danger"> *</span></span>}
                    className={`form-control input ${errors.business_address ? "input-error" : ""}`}
                      onChange={(e) => {setFieldValue("business_address", e.target.value)}}
                  />
                  <small style={{ color: 'red' }}>
                    {touched.business_address && errors.business_address}
                  </small>
                  </Col>
                </Row>
                <Row>
                  <Col className="form-group">
                      <TextField  id="business_registration_number" label="Business Registration Number"   className={`form-control input ${errors.name ? "input-error" : ""}`}  variant="outlined" value={values.business_registration_number ? values.business_registration_number : ""}   onChange={(e) => {setFieldValue("business_registration_number", e.target.value)}}/>
                  </Col>
                </Row>
                <p className="text-muted m-0" style={{fontSize: 12, paddingBottom: 3}}>Please complete all required fields (<span className="text-danger">*</span>)</p>
                <h6 className="mt-3">Date, Currency and Tax</h6>
                <hr />
                    <Row>
                      <Col lg={12}  className="form-group">
                        <Row >
                            <Col lg={12}>
                              <Row style={{marginLeft: 1}}>
                              <span className="mr-1">Default Date Format </span>
                                <select defaultValue={values.date_format_id ? values.date_format_id : 1} className="form-select form-select-lg" aria-label=".form-select-lg example" style={{padding: 6, marginTop: -5}}
                                onChange={(e) => {setFieldValue("date_format_id", e.target.value)}}>
                                      <option style={{fontSize: 13}} value={1}>DD/MM/YYYY</option>
                                      <option style={{fontSize: 13}} value={2}>MM/DD/YYYY</option>
                                </select>
                              </Row>
                            </Col>
                        </Row>
                      </Col>
                   </Row>
                <Row>
                  <Col lg={12}  className="form-group">
                    <Row >
                        <Col lg={12}>
                          <Row style={{marginLeft: 1}}>
                            <span className="mr-1">Select </span>
                            <select className="form-select form-select-lg" aria-label=".form-select-lg example" style={{padding: 6, marginTop: -5}}  onChange={(e) => {setFieldValue("currency_id", e.target.value)}} defaultValue={user.data.currency_id ? user.data.currency_id : 5}>
                                  <option style={{fontSize: 13}} value={1}>AUD</option>
                                  <option style={{fontSize: 13}} value={2}>CAD</option>
                                  <option style={{fontSize: 13}} value={3}>GBP</option>
                                  <option style={{fontSize: 13}} value={4}>NZD</option>
                                  <option style={{fontSize: 13}} value={5}>USD</option>
                            </select>
                            <span style={{marginLeft: 4}}>as Currency</span>
                          </Row>
                        </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6} sm={6} xs={6} className="form-group">
                    <FormCheckbox
                      id="is_vat"
                      name="is_vat"
                      value={isChecked}
                      checked={isChecked}
                      onChange={(e) => {
                        setIsChecked(!isChecked)
                        handleChange(e)
                      }}
                    >Add Sales Tax
                    </FormCheckbox>
                  </Col>
                </Row>
                <Row>
                  <Col lg={5}  className="form-group">
                    <Row >
                        <Col lg={12}>
                          <Row style={{marginLeft: 1}}>
                            <span className="mr-1">Sales Tax Label </span>
                            <select defaultValue={values.tax_label_id ? values.tax_label_id : 2} className="form-select form-select-lg" aria-label=".form-select-lg example" style={{padding: 6, marginTop: -5}}
                            onChange={(e) => {setFieldValue("tax_label_id", e.target.value)}}>
                                  <option style={{fontSize: 13}} value={1}>GST</option>
                                  <option style={{fontSize: 13}} value={2}>VAT</option>
                            </select>
                          </Row>
                        </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col lg={5}  className="form-group">
                    <Row >
                        <Col lg={12}>
                          <Row style={{marginLeft: 1}}>
                            <span className="mr-1">Sales Tax</span>
                            <Field
                              type="number"
                              id="vat_rate"
                              name="vat_rate"
                              value={values.vat_rate}
                              onChange={(e) => {setFieldValue("vat_rate", e.target.value)}}
                              disabled={!isChecked}
                              style={{width: 50, height: 30, marginTop: -5}}
                            />
                            <span style={{marginLeft: 4}}>&#x25;</span>
                          </Row>
                        </Col>
                    </Row>
                  </Col>
                </Row>
                <h6 className="mt-3">Terms and Conditions</h6>
                <hr />
                <Row >
                    <Col lg={12}>
                      <Row style={{marginLeft: 1}}>
                        <span className="mr-1">Quote to expire in </span><Field type="number" name="quote_expire_in_days" value={values.quote_expire_in_days} onChange={(e) => {setFieldValue("quote_expire_in_days", e.target.value)}} style={{width: 50, height: 30, marginTop: -5}} /><span style={{marginLeft: 4}}>Days.</span>
                      </Row>
                    </Col>
                </Row>
                  <Row className="mt-4">
                    <Col lg={3}> <span>Is a deposit required?</span></Col>
                    <Col lg={6} className="">
                      <div role="group" aria-labelledby="my-radio-group">
                        <label>
                            <Field type="radio" name="terms_is_deposit_required" value="false" checked="checked"  />
                            <span> No Deposit</span>
                          </label>
                          <label className="ml-2">
                            <Field type="radio" name="terms_is_deposit_required" value="true" />
                            <span> Deposit Required</span>
                          </label>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-4" hidden={values.terms_is_deposit_required == "true" ? false: true} >
                    <Col lg={3}> <span>Deposit method</span></Col>
                    <Col lg={6} className="">
                      <div role="group" aria-labelledby="my-radio-group">
                        <label>
                          <Field type="radio" name="terms_is_deposit_fixed" value="false" checked="checked" />
                          <span> Percentage Total</span>
                        </label>
                        <label className="ml-2">
                        <Field type="radio" name="terms_is_deposit_fixed" value="true" />
                        <span> Fixed Amount</span>
                        </label>
                      </div>
                    </Col>
                  </Row>
      <div className="mt-4"  hidden={values.terms_is_deposit_required == "true" ? false: true}>
        <Row hidden={values.terms_is_deposit_fixed == "true" ? true: false}>
          <Col lg={12}>
            <Row  style={{marginLeft: 1}}>
              <span className="mr-1">Percentage deposit </span><Field type="number" name="terms_deposit_percentage" style={{width: 50, height: 30, marginTop: -5}} /><span style={{marginLeft: 4}}>&#x25;</span>
            </Row>
          </Col>
        </Row>
        <Row hidden={values.terms_is_deposit_fixed == "true" ? false: true}>
          <Col lg={12}>
            <Row  style={{marginLeft: 1}}>
              <span className="mr-1">Fixed deposit </span><span style={{marginLeft: 4}}>&#x24;</span><Field type="number" name="terms_deposit_amount" style={{width: 80, height: 30, marginTop: -5}} />
            </Row>
          </Col>
        </Row>
      </div>


      <Row className="mt-4" >
          <Col lg={6}>
            <ReactForm.Group controlId="exampleForm.ControlTextarea1">
              <ReactForm.Label>Other Conditions</ReactForm.Label>

              <ReactForm.Control as="textarea" rows={3} value={values.terms_other_conditions}  onChange={(e) => {
                setFieldValue('terms_other_conditions', e.target.value)
              }}/>
            </ReactForm.Group>
          </Col>
      </Row>

                <Button theme="accent" className="" type="submit">Save Changes</Button>
                <span className="text-primary" style={{ cursor: "pointer", marginLeft: 12, fontWeight: 600}} onClick={() => {
                  history.push('/dashboard')
                }}>Cancel</span>
              </Form>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>)}
    </Formik>
  );
};

UserAccountDetails.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

UserAccountDetails.defaultProps = {
  title: "Account Details"
};

export default UserAccountDetails;
