import React, {useEffect, useContext} from "react";
import PropTypes from "prop-types";
import { Container, Col,
  Card,
  CardHeader,
  CardFooter,
  Button,
  ListGroupItem, Row} from "shards-react";
  import { Link, useHistory} from "react-router-dom";
  import InfoIcon from '@mui/icons-material/Info';
  import WarningIcon from '@mui/icons-material/Warning';
  import DraftsIcon from '@mui/icons-material/Drafts';
  import CancelIcon from '@mui/icons-material/Cancel';
  import CheckCircleIcon from '@mui/icons-material/CheckCircle';
  import EventBusyIcon from '@mui/icons-material/EventBusy';


import { orange } from '@mui/material/colors';


export default function NotificationCard({notifications, completeProfileInformation, getQuotesDetails, user}) {
  return (
     <Row >
          <Col lg={7} md={7} sm={12} data-testid="cardNotification">
            <Card className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Notifications</h6>
              </CardHeader>
              <Row>
                <Col style={{paddingTop: 3, paddingBottom: 15}}>
                  {notifications && notifications.length === 0 ? (<Row style={{marginLeft: 28, marginTop: 12}}>
                    <InfoIcon color="primary" style={{ fontSize: 30, marginRight: 10, marginTop: 6  }} />
                    <p className="m-0">
                      You haven't quoted yet! Create and send your first quote. <br/>
                      <Link data-testid="linkCreateQuote" to={"/quotes/create"} onClick={() => {}} style={{}} ><span>Click here</span></Link>
                    </p>
                    </Row>) : null}
                    {notifications && notifications.map((notif, index) => {
                      return (
                        <div style={{marginLeft:28, marginTop: 12, marginBottom: 0}} key={index}>
                          {getIcon(notif.message)}
                          <Link style={{}} to={"/quotes/show/" + notif.quotation_id } onClick={() => {
                          getQuotesDetails(user.data.auth_token, notif.quotation_id);
                          localStorage.setItem("showQuoteDetails", JSON.stringify(notif.quotation_id))
                          }}>{notif.transaction_number}</Link>
                          <span> {notif.message} <Link style={{}} to={"/customer/view" } onClick={() => {
                            getQuotesDetails(user.data.auth_token, notif.quotation_id);
                            localStorage.setItem("showQuoteDetails", JSON.stringify(notif.quotation_id))
                            // getQuotesDetails(user.data.auth_token, notif.quotation_id)
                              // localStorage.setItem("viewCustomer", JSON.stringify(data))
                              // history.push(`/customers/edit/${data.id}`)
                          }}>{notif.customer_name}</Link></span>
                          {/* <span className="text-muted">{notif.customer_name}</span> */}
                        </div>

                      )
                    })}

                    {completeProfileInformation === false ? (<Row style={{marginLeft: 28, marginTop: 12}}>
                    <WarningIcon style={{ fontSize: 30, marginRight: 10, marginTop: 6, color: orange[500]  }} />
                    <p className="m-0">
                      Your business profile is incomplete. <br/>
                      <Link data-testid="linkProfile" to={"/settings/profile"} onClick={() => {}} style={{color: "red"}} ><span>Click here</span></Link>
                    </p>
                    </Row>) : null}
                </Col>
              </Row>
            </Card>
          </Col>
          <Col lg={5} md={5} sm={12} className="d-none d-md-block d-lg-block d-xl-block"  data-testid="cardReachOut">
             <Card className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Reach Out</h6>
              </CardHeader>
              <div style={{padding: "15px 31px"}}>
              <p className="m-0">
                  Need Help? Please Email <a className="">support@quoteshark.co</a>
                </p>
              </div>
            </Card>
          </Col>
        </Row>
  )
}



const getIcon = (message) => {

  if(message == "was accepted by"){
    return (<CheckCircleIcon style={{ fontSize: 21, marginRight: 10, color: "#808080"  }} />);
  }else if(message == "was read by"){
    return (<DraftsIcon style={{ fontSize: 21, marginRight: 10, color: "#808080"  }} />);
  }else if(message == "was rejected by"){
    return (<CancelIcon style={{ fontSize: 21, marginRight: 10, color: "#808080"  }} />);
  }
  return (<EventBusyIcon style={{ fontSize: 21, marginRight: 10, color: "#808080"  }} />);
}
