import { object, string } from 'yup'

import ProfileDetailsComponent from './ProfileDetailsComponent'
import CurrencyTaxDetailComponent from './CurrencyTaxDetailComponent'
import TermsAndConditionDetails from './TermsAndConditionDetails'
import {createCustomer, updateCustomer} from './../../../providers/QuoteApiResource'

export default [
  {
    id: 'profileDetails',
    component: ProfileDetailsComponent,
    initialValues: {
      customer_id: 0,
      business_name: "",
      name: "",
      email: "",
      contact_number: "",
      website: "",
      business_address: "",
      business_registration_number: "",
    },
    validationSchema: object().shape({
      business_name: string().max(255, 'Text is Too Long!').required('Business Name is Required').typeError('Business name is required.'),
      name: string().max(255, 'Text is Too Long!').required('Name is Required').typeError('Name is required.'),
      email: string().max(255, 'Text is Too Long!').required('Email is Required'),
      contact_number: string().min(6, "Phone number is not valid").max(20, "Phone number is not valid").required('Phone is Required'),
      business_address: string().max(255, 'Text is Too Long!').required('Business Address is Required').typeError('Business Address is required.'),
      }),
    onAction: (sectionValues, formValues) => {
      console.log('sectionValues', sectionValues);
      if(sectionValues && formValues.customerDetails){
        if(sectionValues.customer_id == 0){
          const response =  createCustomer(sectionValues);
          console.log("response ", response)
          console.log("Clicked Next")
          console.log(sectionValues)
        }else{
          const response =  updateCustomer(sectionValues);
          console.log("response ", response)
          console.log("Clicked Next")
          console.log(sectionValues)
        }
      }
    },
  },
  {
    id: 'currencyTaxDetails',
    component: CurrencyTaxDetailComponent,
    initialValues: {
      is_checked: "",
      is_vat: "",
      vat_rate: "",
      currency_id: 1,
    },
    validationSchema: object().shape({
      }),
    onAction: async (sectionValues, formValues) => {
      // if(sectionValues && formValues.customerDetails){
      //   if(sectionValues.customer_id == 0){
      //     const response = await createCustomer(sectionValues);
      //     console.log("response ", response)
      //     console.log("Clicked Next")
      //     console.log(sectionValues)
      //   }else{
      //     const response = await updateCustomer(sectionValues);
      //     console.log("response ", response)
      //     console.log("Clicked Next")
      //     console.log(sectionValues)
      //   }
      // }
    },
  },
  {
    id: 'termsAndConditionDetails',
    component: TermsAndConditionDetails,
    initialValues: {
      quote_expire_in_days:  30,
      terms_is_deposit_required:  "",
      terms_is_deposit_fixed:  "",
      terms_deposit_percentage:  "",
      terms_deposit_amount:  "",
      terms_other_conditions:  "",
    },
    validationSchema: object().shape({
      }),
    onAction: async (sectionValues, formValues) => {
      // if(sectionValues && formValues.customerDetails){
      //   if(sectionValues.customer_id == 0){
      //     const response = await createCustomer(sectionValues);
      //     console.log("response ", response)
      //     console.log("Clicked Next")
      //     console.log(sectionValues)
      //   }else{
      //     const response = await updateCustomer(sectionValues);
      //     console.log("response ", response)
      //     console.log("Clicked Next")
      //     console.log(sectionValues)
      //   }
      // }
    },
  },
]
