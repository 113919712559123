import React, { useState, useLayoutEffect } from "react";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Button,
  FormCheckbox,
  FormInput,
} from "shards-react";
import logo from "../../images/logo.png";
import IMAGE_ASSETS from "../../assets/image-list";
import { Link, useHistory } from "react-router-dom";
import { Jumbotron } from "react-bootstrap";

const Footer = () => {
  const history = useHistory();
  const [windowSize, setWindowSize] = useState();
  const [userDevice, setUserDevice] = useState();
  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth >= 1200) {
        setUserDevice("l");
      } else if (window.innerWidth < 1200 && window.innerWidth >= 760) {
        setUserDevice("m");
      } else {
        setUserDevice("s");
      }
      setWindowSize(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <>
      <section
        id="explore"
        className="pt-4 pb-3"
        style={{ backgroundColor: "#004E74", marginTop: 50 }}
      >
        <br />
        <div className="container">
          <div className="row">
            <div
              className="col-12 col-md-6 col-lg-6 order-lg-2 order-md-2 justify-content-center align-items-center"
              style={{ marginBottom: 30 }}
            >
              <img
                className=""
                src={IMAGE_ASSETS.src.dashboard2}
                style={{ width: "100%", marginTop: "-17%" }}
              ></img>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <div>
                <h2
                  style={{
                    fontWeight: 700,
                    color: "#FFF",
                    lineHeight: "57px",
                  }}
                >
                  Get Started with <br />
                  QuoteShark Dashboard
                </h2>
                <h4
                  style={{
                    color: "#FFF",
                    fontWeight: 300,
                    fontStyle: "normal",
                  }}
                >
                  <span>
                    Make quote to your customers, the simplest thing you do all
                    day
                  </span>
                </h4>
              </div>
              <div>
                <button
                  className="call-to-action-main"
                  onClick={() => {
                    history.push("/sign-up");
                  }}
                >
                  Sign Up Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="pt-4 pb-3"
        style={{ backgroundColor: "#003956", marginLeft: 0 }}
      >
        <footer className="container">
          <Row style={{ paddingTop: 60 }}>
            <Col lg={5}>
              {/* <p style={{fontSize: 32, fontWeight: 700, color: 'white'}}> &nbsp;</p> */}

              <img
                className="mb-4 img-fluid"
                src={IMAGE_ASSETS.src.footerLogoWhite}
                alt="QuoteShark Logo"
              />
              <p style={{ margin: 0 }}>&nbsp;</p>
              <p className="text-white" style={{ fontWeight: 600, margin: 0 }}>
                Get in Touch with Us
              </p>
              <p
                className="text-white"
                style={{ margin: 0, paddingTop: 14, paddingRight: "20%" }}
              >
                <i className="fa fa-map-marker" aria-hidden="true"></i> Suite 2,
                348 Argyle Street, Moss Vale, NSW, Australia, 2577
              </p>
              {/* <p className="text -white" style={{ margin: 0, paddingTop: 7}}><i className="fa fa-phone" aria-hidden="true"></i> +123 1234 567</p> */}
              <p className="text-white" style={{ margin: 0, paddingTop: 7 }}>
                <i className="fa fa-envelope" aria-hidden="true"></i>{" "}
                support@quoteshark.co
              </p>
            </Col>
            <Col lg={3} style={{}}>
              <p
                className="text-white"
                style={{ fontWeight: 600, margin: 0, paddingTop: 18 }}
              >
                QuoteShark Menu
              </p>
              <p style={{ margin: 0 }}>&nbsp;</p>
              <p
                className="text-white pointer"
                style={{ margin: 0, paddingTop: 14 }}
                onClick={() => {
                  history.push("/");
                }}
              >
                Overview
              </p>
              <p
                className="text-white pointer"
                style={{ margin: 0, paddingTop: 7 }}
                onClick={() => {
                  history.push("/features");
                }}
              >
                Features
              </p>
              <p
                className="text-white pointer"
                style={{ margin: 0, paddingTop: 7 }}
                onClick={() => {
                  history.push("/about-us");
                }}
              >
                About Us
              </p>
              {/* <p className="text-white pointer" style={{ margin: 0, paddingTop: 7}}>Contact Us</p> */}
            </Col>
            <Col lg={3} style={{}}>
              <p
                className="text-white"
                style={{ fontWeight: 600, margin: 0, paddingTop: 18 }}
              >
                Resources
              </p>
              <p style={{ margin: 0 }}>&nbsp;</p>
              <p
                className="text-white pointer"
                style={{ margin: 0, paddingTop: 14 }}
              >
                Privacy
              </p>
              <p
                className="text-white pointer"
                style={{ margin: 0, paddingTop: 7 }}
              >
                Terms & Conditions
              </p>
              <p
                className="text-white pointer"
                style={{ margin: 0, paddingTop: 7 }}
              >
                Partner
              </p>
              {/* <p className="text-white pointer" style={{ margin: 0, paddingTop: 7}}>Help</p> */}
            </Col>
          </Row>

          <Row style={{}}>
            <Col>
              <p className="text-white" style={{ margin: 0, padding: 14 }}>
                <hr style={{ backgroundColor: "#004D73", width: "100%" }} />
                QuoteShark.co - &copy; 2021 All Rights Reserved
              </p>
            </Col>
          </Row>
        </footer>
      </section>
    </>
  );
};

export default Footer;
