import React, {useContext} from "react";
import { Container, Row, Col,
  Card,
  CardBody,
  CardFooter,
  Badge,
  Button } from "shards-react";


import {Image} from "react-bootstrap"
import PageTitle from "../../../components/common/PageTitle";
import RightBar from "../../../components/layout/RightBar";
import UserAccountDetails from "../../../components/user-profile-lite/UserAccountDetails";
import { UserContext } from '../../../providers/UserProvider';
import IMAGE_ASSETS from '../../../assets/image-list';

const Profile = () => {
  const {user} = useContext(UserContext)

  if(user){
    let avatar = user.data.image ? user.data.image : IMAGE_ASSETS.url.avatarDefault;

    return (
          <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle title="QuoteShark" subtitle="Profile" md="12" className="ml-sm-auto mr-sm-auto" />
          </Row>
          <Row>
            <Col lg="9">
              {/* <UserDetails /> */}

                  <div className="card mb-3" style={{height: 123}} >
                    <div className="c d-flex">
                      <Image src={avatar} roundedCircle width={100} height={100} style={{marginLeft: 15, marginTop: 12}}/>
                      <div style={{paddingTop: 50}}>
                      <p className="m-0"><span className="text-primary ml-3">{user.data.name}</span></p>
                      <p style={{marginTop: -5}}><span className="ml-3">{user.data.email}</span></p>
                      </div>
                    </div>
                  </div>
              <UserAccountDetails title="Profile Information" user={user} />
            </Col>
          </Row>
        </Container>
      );
  }else{
    return (
      <Container>
      <p></p>
      </Container>
    );
  }
};

export default Profile;
