import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Col } from "shards-react";
import { Link, useHistory} from "react-router-dom";

const PageTitle = ({ title, subtitle, viewTitle = null, className, ...attrs }) => {
  const classes = classNames(
    className,
    "text-center",
    "text-md-left",
    "mb-sm-0"
  );

  return (
    <Col xs="12" sm="4" className={classes} { ...attrs }>
      <span className="page-subtitle">{title} / {viewTitle && (<Link to={"/quotes/list/"} className="page-subtitle" >{viewTitle + " / "}</Link>)} </span><span className="page-subtitle text-primary">{subtitle}</span>
      {/* <h3 className="page-title">{title}</h3> */}
    </Col>
  )
};

PageTitle.propTypes = {
  /**
   * The page title.
   */
  title: PropTypes.string,
  /**
   * The page subtitle.
   */
  subtitle: PropTypes.string
};

export default PageTitle;
