/* eslint-disable no-use-before-define */
import React, {useState, useEffect} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

const filter = createFilterOptions();

export default function CustomerSmartSearch(props) {
    const [value, setValue] = useState("");


    const textStyles = {fontSize: 13, color: "#495057", fontWeight: "normal"}
    const labelStyles = {fontSize: 13}
    useEffect(() => {
        setValue(props.value)
    }, [])

    return (
      <Autocomplete
        disablePortal
        value={value}
        sx={{ width: "100%" }}
        onChange={(event, newValue) => {
            console.log(newValue)
          if (typeof newValue === 'string') {
            props.setFieldValue("name", newValue)
            setValue({
              customer_name: newValue,
            });
          } else if (newValue && newValue.customer_name) {
            // Create a new value from the user input
            props.setFieldValue("customer_id", newValue.id)
            props.setFieldValue("name", newValue.customer_name)
            props.setFieldValue("email", newValue.email)
            props.setFieldValue("address", newValue.customer_address)
            props.setFieldValue("contact_number", newValue.contact_number)
            setValue({
              customer_name: newValue.customer_name,
            });
          } else {
            props.setFieldValue("name", newValue)
            setValue(newValue);
          }
        }}
        options={props.customers ? props.customers : []}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          return option.customer_name;
        }}
        renderInput={(params) => (
          <>
           <input type="hidden" value="something"/>
          <TextField {...params}  autoComplete="customer_name"  label={<span>Customer Name<span className="text-danger"> *</span></span>} variant="outlined" onChange={(e) => {
            props.setFieldValue("name", e.target.value)
            props.setFieldValue("customer_id", 0)
            setValue(e.target.value);
          }}/>
          </>
        )}
      />
    );
  }
