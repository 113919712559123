import React, { useEffect, useContext, useState } from 'react';
// import './Login.css'
import { signInWithGoogle, signInWithFacebook } from '../../api/firebase';
import { UserContext } from '../../providers/UserProvider';
import { Redirect, Link } from 'react-router-dom';
import {Button} from 'react-bootstrap'
import logo from '../../images/logo.png'
import styled from 'styled-components';
import PropTypes from "prop-types";
import * as Yup from "yup";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  FormInput,
  FormFeedback
} from "shards-react";
import { Formik, Form } from 'formik';

export default function ForgotPassword() {
  const {user, requestResetPassword, resetPassword} = useContext(UserContext)
  const [email, setemail] = useState("")
  const [password, setpassword] = useState("")

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email format').required('Email is required'),
  })

    const BtnFacebook = styled.button`
    width: 100%;
    height:35px;
    border-radius: 4px;
    background: #3b5998;
    color:white;
    border:0px transparent;
    text-align: center;
    margin:5px;
    display: inline-block;

    &:hover{
        background: #3b5998;
        opacity: 0.6;
    }
    `;
    const BtnGoogle = styled.button`
    margin:5px;
    width: 100%;
    height:35px;
    border-radius: 4px;
    background: #db3236;
    color:white;
    border:0px transparent;
    text-align: center;

    &:hover{
        background: #3b5998;
        opacity: 0.6;
    }
    `

  return (
    <div className="container mt-lg-5" data-testid="mainLayout">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-5 col-md-7 col-sm-12 col-xs-12 p-0">
        <div className="w-100 bg-primary p-5">
        <img className="mb-4 img-fluid " src={logo} alt="QuoteShark Logo"/>
        {/* <pre>{user}</pre> */}
        <h5 className="h5 mb-3 fw-normal text-center text-white">Forgot Password</h5>
          <Formik
                initialValues={{
                  email: "",
                }}
                validationSchema={validationSchema}
                onSubmit={async (values, {resetForm}) => {
                  requestResetPassword(values.email);
                  resetForm({
                      values: {
                        email: "",
                      },
                    });
                }}
              >
              {({handleSubmit, handleChange, touched, errors}) => (
                        <Form>
                          <Row>
                            <Col className="form-group">
                              <label className="text-white d-none d-sm-block d-md-block" htmlFor="email">Email</label>
                              <FormInput
                                data-testid="inputEmail"
                                className="form-control"
                                id="email"
                                name="email"
                                invalid={touched.email && errors.email}
                                onChange={handleChange}
                                placeholder="Email"
                                type="text"
                              />
                              {/* {errors.email? <FormFeedback>{errors.email}</FormFeedback> : null} */}

                            <small style={{ color: 'red' }}>
                              {touched.email && errors.email}
                            </small>
                            </Col>
                          </Row>
                            <Button variant="light" type="submit" className="mt-1" block onClick={() => {
                            }} data-testid="buttonSubmit">
                          Send Reset Password
                          </Button>
                        </Form>)}
            </Formik>

        </div>
        </div>
      </div>
    </div>
  );
}
