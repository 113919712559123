import React, { useState, useEffect, createContext, useContext } from "react";
import { auth } from "../api/firebase";
import { backend_url } from "../config/endpoints";
import axios from 'axios';
import NotyfContext from './NotificationContext';

export const UserContext = createContext({ user: null });

const UserProvider = (props) => {
  const [user, setuser] = useState(JSON.parse(localStorage.getItem("user")));
  const [completeProfileInformation, setCompleteProfileInformation] = useState(false);
  const [loading, setLoading] = useState(false)
  const notyf = useContext(NotyfContext);

  const logIn = (data) => {
    axios.post(backend_url + `/login`, { data })
    .then(res => {
      if(res.data.status){
        notyf.success('Login Successful')
        console.log(res.data);
        localStorage.setItem("user", JSON.stringify(res.data));
        setuser(res.data);
      }else{
        notyf.error(res.data.message)
      }
    }).catch((error) => {
      notyf.error("Something went wrong")
      console.log(error);
    })
  }

  const emailSubscribe = (customer_id) => {
    setLoading(true)
    axios.post(backend_url + `/customers/${customer_id}/subscribe`)
    .then(res => {
      if(res.data.status){
        notyf.success(res.data.message)
      }else{
        notyf.error(res.data.message)
      }

      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      console.log(error);
    })
  }

  const emailUnSubscribe = (customer_id) => {
    setLoading(true)
    axios.post(backend_url + `/customers/${customer_id}/unsubscribe`)
    .then(res => {
      if(res.data.status){
        notyf.success(res.data.message)
      }else{
        notyf.error(res.data.message)
      }

      setLoading(false)
    }).catch((error) => {
      setLoading(false)
      console.log(error);
    })
  }

  const signUp = (data) => {
    console.log(data)

    axios.post(backend_url + `/signup`, data)
    .then(res => {
      if(res.data.status){
        notyf.success(res.data.message)
        console.log(res.data);
        localStorage.setItem("user", JSON.stringify(res.data));
        setuser(res.data);
      }else{
        notyf.error(res.data.message)
      }
    }).catch((error) => {
      notyf.error("Something went wrong")
      console.log(error);
    })

  }

  const updateProfile = async (user, auth_token, isChecked) => {
    console.log(user)
    let _user
    user.terms_is_deposit_required = user.terms_is_deposit_required === "true" ? true : false;
    user.terms_is_deposit_fixed = user.terms_is_deposit_fixed === "true" ? true : false;
    if(isChecked){
      user.is_vat = true;
      _user = {
        "data" : user
      }
    }else{
      user.is_vat = false;
      _user = {
        "data" : user
      }
    }
    console.log(_user)
    var config = {
      method: 'post',
      url: backend_url + '/update_profile',
      headers: {
        'Authorization': auth_token,
        'Content-Type': 'application/json'
      },
      data: _user
    };

    return await axios(config)
    .then(function (res) {
      console.log(JSON.stringify(res.data));
      if(res.data.status){
        notyf.success("Profile updated successfully")
        localStorage.setItem("user", JSON.stringify(res.data));
        setuser(res.data);

        if(res.data.data.name !== null && res.data.data.business_name !== null && res.data.data.contact_number !== null && res.data.data.business_address !== null){
          setCompleteProfileInformation(true)
        }
      }else{
        notyf.error(res.data.message)
      }
      return res.data.status;
    })
    .catch(function (error) {
      notyf.error("Something went wrong")
      console.log(error);
    });

  }
  const changePassword = (data, auth_token) => {
    console.log(data)

    if(data.new_password !== data.confirm_password){
      notyf.error("Confirm password does not match.")
    }else{
      let _data = {
        "data" : data
      }
      console.log(_data)
      var config = {
        method: 'post',
        url: backend_url + '/change_password',
        headers: {
          'Authorization': auth_token,
          'Content-Type': 'application/json'
        },
        data: _data
      };

      axios(config)
      .then(function (res) {
        console.log(JSON.stringify(res.data));
        if(res.data.status){
          notyf.success(res.data.message)
        }else{
          notyf.error(res.data.message)
        }
      })
      .catch(function (error) {
        notyf.error("Something went wrong")
        console.log(error);
      });
    }

  }

  const requestResetPassword = (email) => {

      let _data = {
        "data" : {
          "email" : email
        }
      }

      var config = {
        method: 'post',
        url: backend_url + '/request_reset_password',
        headers: {
          'Content-Type': 'application/json'
        },
        data: _data
      }

      axios(config)
      .then(function (res) {
        console.log(JSON.stringify(res.data));
        if(res.data.status){
          notyf.success(res.data.message)
        }else{
          notyf.error(res.data.message)
        }
      })
      .catch(function (error) {
        notyf.error("Something went wrong")
        console.log(error);
      });

  }

  const resetPassword = (email, reset_password_token, new_password ) => {

      let _data = {
        "data" : {
          "email" : email,
          "reset_password_token" : reset_password_token,
          "new_password" : new_password,
        }
      }

      var config = {
        method: 'post',
        url: backend_url + '/reset_password',
        headers: {
          'Content-Type': 'application/json'
        },
        data: _data
      }

      axios(config)
      .then(function (res) {
        console.log(JSON.stringify(res.data));
        if(res.data.status){
          notyf.success(res.data.message)
        }else{
          notyf.error(res.data.message)
        }
      })
      .catch(function (error) {
        notyf.error("Something went wrong")
        console.log(error);
      });

  }

  const getUserProfile = (auth_token) => {
    var config = {
      method: 'get',
      url: backend_url + '/view_profile',
      headers: {
        'Authorization': auth_token
      }
    };

    return axios(config)
    .then(function (res) {
      console.log(JSON.stringify(res.data));
      if(res.data.status){
        localStorage.setItem("user", JSON.stringify(res.data));
        setuser(res.data);
        return res.data;
        // notyf.success("Profile updated successfully")
      }else{
        notyf.error(res.data.message)
      }
    })
    .catch(function (error) {
      notyf.error("Something went wrong")
      console.log(error);
      return null;
    });

  }

  const logOut = (user) => {
    console.log(user)

    if(user.data && user.data.provider){
      auth.signOut().then(()=> {
        console.log('social provider logged out')
      }).catch((error) => {
        console.log(error.message)
      })
    }

    if(user.data){
      var config = {
        method: 'post',
        url: backend_url + '/logout',
        headers: {
          'Authorization': user.data.auth_token
        }
      };

      axios(config)
      .then(function (res) {
        console.log(JSON.stringify(res.data));
        if(res.data.status){
          notyf.success(res.data.message)
        }else{
          notyf.error(res.data.message)
        }
      })
      .catch(function (error) {
        notyf.error("Something went wrong")
        console.log(error);
      });
    }
    localStorage.setItem("admin-pathname", "/dashboard");
    localStorage.setItem("user", null);

  }

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const { displayName, email, photoURL, uid, providerData} = user;
        let data = {
          name: displayName,
          email: email,
          image: photoURL,
          password: uid,
          provider: providerData[0].providerId
        }
        if(JSON.parse(localStorage.user) == null){
          axios.post(backend_url + `/social_login`, { data })
          .then(res => {
            console.log(res.data);
            localStorage.setItem("user", JSON.stringify(res.data));
            setuser(res.data);
          }).catch((error) => {
            console.log("error http://localhost:3001/api/v1/social_login");
            console.log(error);
          })
        }else{
          console.log("Already logged in")
        }

      }
    });

    if(localStorage.user != null){
      setuser(JSON.parse(localStorage.user))
      return function cleanup() {

     }
    }else{
      return auth.onAuthStateChanged(async (user) => {
        if (user) {
          const { displayName, email, photoURL, uid, providerData} = user;
          let data = {
            name: displayName,
            email: email,
            image: photoURL,
            password: uid,
            provider: providerData[0].providerId
          }
          axios.post(backend_url + `/social_login`, { data })
          .then(res => {
            console.log(res.data);
            localStorage.setItem("user", JSON.stringify(res.data));
            setuser(res.data);
          }).catch((error) => {
            console.log("error http://localhost:3001/api/v1/social_login");
            console.log(error);
          })

          // setuser(data);
        }
      });
    }

  }, []);


  return (
    <UserContext.Provider value={{user: user, setUser: setuser, logOut: logOut, logIn: logIn, signUp: signUp, updateProfile: updateProfile, getUserProfile: getUserProfile, changePassword: changePassword, requestResetPassword: requestResetPassword, resetPassword: resetPassword, completeProfileInformation: completeProfileInformation, setCompleteProfileInformation: setCompleteProfileInformation, emailUnSubscribe: emailUnSubscribe, emailSubscribe: emailSubscribe, loading: loading, setLoading: setLoading}}>{props.children}</UserContext.Provider>
  );
};

export default UserProvider;
