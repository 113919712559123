import React, { useState, useContext, useEffect, useRef } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import { red } from "@mui/material/colors";

import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { UserContext } from "../../providers/UserProvider";
import { QuoteContext } from "../../providers/QuoteProvider";
import { firestoreDb } from "../../api/firebase";
import firebase from "firebase/app";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useLocation } from "react-router-dom";
import {
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  FormInput,
  Button,
} from "shards-react";
import moment from "moment";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function ViewQuotePublicMessage() {
  const { user } = useContext(UserContext);
  const {
    sendMessage,
    publicQuoteDetail,
    messageConversationList,
    getConversationListCustomerWithoutLoading,
  } = useContext(QuoteContext);
  const [message, setMessage] = useState("");
  const query = useQuery();
  var messagesEndRef = useRef(null);
  var convoEnd = useRef();

  const scrollToBottom = () => {
    messagesEndRef.scrollIntoView();
    convoEnd.current.scrollIntoView();
  };

  const messagesRef = firestoreDb
    .collection("messages")
    .doc(
      `${publicQuoteDetail.user_profile_details.email}-${publicQuoteDetail.id}`
    )
    .collection("conversation");

  const sendMessageToFireStore = async () => {
    // const { uid, photoURL } = auth.currentUser;

    await messagesRef.add({
      message: message,
      createdAt: new Date().toLocaleString(),
      quotation_id: publicQuoteDetail.id,
      is_customer: true,
    });
  };

  const queryFirestore = messagesRef.orderBy("createdAt").limitToLast(25);

  const [messagesFromFireStore] = useCollectionData(queryFirestore, {
    idField: "id",
  });

  useEffect(() => {
    try {
      scrollToBottom();
    } catch (e) {}
  }, [messagesFromFireStore]);

  useEffect(() => {
    // setInterval(() => {
    //   getConversationListCustomerWithoutLoading(
    //     publicQuoteDetail.id,
    //     publicQuoteDetail.token
    //   );
    // }, 10000);
  }, []);

  const renderBusinessMessage = (
    index,
    message,
    created_at,
    customer = false
  ) => {
    if (customer) {
      return (
        <ListItem key={"message" + index}>
          <ListItemText
            primary={
              <p
                style={{
                  textAlign: "right",
                  margin: 0,
                  paddingLeft: "20%",
                }}
              >
                <span
                  style={{
                    backgroundColor: "#5BC772",
                    padding: 7,
                    borderRadius: "15px 15px 0px 15px",
                    color: "white",
                    textAlign: "left",
                    display: "inline-block",
                  }}
                >
                  {message}
                </span>
                <br />
                <span
                  style={{
                    color: "#8A8B8C",
                    fontSize: 10,
                  }}
                >
                  {moment(created_at).fromNow()}
                </span>
              </p>
            }
          />
        </ListItem>
      );
    }
    return (
      <ListItem
        key={"message" + index}
        style={{
          width: "85%",
        }}
      >
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
            {publicQuoteDetail.user_profile_details.business_name.charAt(0)}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <p
              style={{
                textAlign: "left",
                marginBottom: -15,
              }}
            >
              <span
                style={{
                  backgroundColor: "#8A8B8C",
                  padding: 7,
                  borderRadius: "0px 15px 15px 15px",
                  color: "white",
                  textAlign: "left",
                  display: "inline-block",
                }}
              >
                {message}
              </span>
              <br />
              <span
                style={{
                  color: "#8A8B8C",
                  fontSize: 10,
                }}
              >
                {moment(created_at).fromNow()}
              </span>
            </p>
          }
        />
      </ListItem>
    );
  };

  return (
    <Card className="m-4" style={{}}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
            {publicQuoteDetail.user_profile_details.business_name.charAt(0)}
          </Avatar>
        }
        // action={
        //   <IconButton aria-label="settings">
        //     <MoreVertIcon />
        //   </IconButton>
        // }
        title={
          <p
            style={{
              color: "#FFFFFF",
              fontSize: 15,
              fontWeight: 600,
              marginBottom: 0,
            }}
          >
            {`${publicQuoteDetail.user_profile_details.name}`}
          </p>
        }
        subheader={
          <p
            style={{
              color: "#FFFFFF",
              fontSize: 15,
              fontWeight: 600,
              marginBottom: 0,
            }}
          >
            {`(${publicQuoteDetail.transaction_number})`}
          </p>
        }
        style={{ color: "white" }}
        className="bg-primary"
      />
      <Divider />
      <CardContent style={{ padding: 0 }}>
        <List
          style={{
            overflowY: "scroll",
            height: 300,
          }}
          sx={{ width: "100%", bgcolor: "background.paper" }}
        >
          {messagesFromFireStore &&
            messagesFromFireStore.map((message, index) => {
              if (message.is_customer) {
                return renderBusinessMessage(
                  message.id,
                  message.message,
                  message.created_at,
                  true
                );
              } else {
                return renderBusinessMessage(
                  message.id,
                  message.message,
                  message.created_at,
                  false
                );
              }
            })}

          <ListItem
            ref={(el) => {
              messagesEndRef = el;
            }}
          ></ListItem>
          <ListItem ref={convoEnd}></ListItem>
        </List>
      </CardContent>
      <CardActions disableSpacing>
        <InputGroup seamless>
          <FormInput
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key == "Enter") {
                e.defaultMuiPrevented = true;
                e.preventDefault();
                setMessage("");
                sendMessageToFireStore();
                sendMessage(
                  publicQuoteDetail.id,
                  publicQuoteDetail.token,
                  message
                );
              }
            }}
            value={message}
            placeholder="Type Message"
            style={{ paddingTop: 10, paddingBottom: 10 }}
          />
          <InputGroupAddon type="append">
            <InputGroupText>
              <Button
                outline={true}
                theme="primary"
                onClick={() => {
                  if (message != "") {
                    console.log(message);
                    setMessage("");
                    sendMessageToFireStore();
                    sendMessage(
                      publicQuoteDetail.id,
                      publicQuoteDetail.token,
                      message
                    );
                  }
                }}
              >
                Send
              </Button>
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
      </CardActions>
    </Card>
  );
}

export default ViewQuotePublicMessage;
