import React, { useState, useContext, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import { red } from "@mui/material/colors";

import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Typography from "@mui/material/Typography";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ImageIcon from "@mui/icons-material/Image";
import WorkIcon from "@mui/icons-material/Work";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import { UserContext } from "../../providers/UserProvider";
import { QuoteContext } from "../../providers/QuoteProvider";
import { useLocation } from "react-router-dom";
import ChatIcon from "@mui/icons-material/Chat";

import {
  Container,
  Col,
  Card,
  CardHeader,
  CardFooter,
  Button,
  ListGroupItem,
  Row,
} from "shards-react";
import Modal from "react-bootstrap/Modal";
import { roundToNearestMinutesWithOptions } from "date-fns/fp";

const AdminMessageQuoteList = () => {
  const { user } = useContext(UserContext);
  const {
    quoteList,
    getQuotes,
    setSelectedConversation,
    getMessageConversationList,
  } = useContext(QuoteContext);
  const [openModal, setOpenModal] = useState(false);
  const [businessFirstLetter, setBusinessFirstLetter] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(1);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const handleClose = () => setOpenModal(false);
  const handleShow = () => setOpenModal(true);

  const showModal = () => {
    return (
      <Modal
        show={openModal}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ zIndex: 99999 }}
      >
        <Card>
          <CardHeader className="border-bottom">
            <p
              className="text-center"
              style={{ fontWeight: 700, fontSize: 22, marginBottom: 0 }}
            >
              Select a Quote
            </p>
          </CardHeader>
          <List
            component="nav"
            sx={{ width: "100%", maxHeight: 400, overflowY: "scroll" }}
          >
            {quoteList &&
              quoteList.map((quote, index) => {
                return (
                  <ListItemButton
                    key={"quote" + index}
                    selected={selectedIndex === index}
                    onClick={(event) => {
                      handleListItemClick(event, index);
                      var selected = {
                        customer_name: quote.customer_name,
                        quotation_id: quote.id,
                        qToken: quote.token,
                        transaction_number: quote.transaction_number,
                      };
                      setSelectedConversation(selected);
                      getMessageConversationList(
                        quote.id,
                        quote.token,
                        user.data.auth_token
                      );
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: red[500] }}>
                        {quote.customer_name.charAt(0)}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={quote.transaction_number}
                      secondary={quote.customer_name}
                    />
                  </ListItemButton>
                );
              })}
          </List>
        </Card>
      </Modal>
    );
  };

  useEffect(() => {
    if (user.data.business_name) {
      setBusinessFirstLetter(user.data.business_name.charAt(0));
    }
    if (user) {
      getQuotes(user.data.auth_token);
    }
    console.log("user", user);
    console.log("user.data.business_name", user.data.business_name);
  }, []);

  return (
    <>
      <Card className="m-4" style={{ padding: 15 }}>
        <Row>
          <Col className="col-6">
            <Row just>
              <Avatar style={{ marginLeft: 15 }} sx={{ bgcolor: red[500] }}>
                {businessFirstLetter}
              </Avatar>
              <span style={{ paddingLeft: 5, paddingTop: 10 }}>
                {user.data.business_name}
              </span>
            </Row>
          </Col>
          <Col className="col">
            <Button onClick={handleShow} pill style={{ float: "right" }}>
              <ChatIcon /> + New
            </Button>
          </Col>
        </Row>
      </Card>
      {showModal()}
    </>
  );
};

export default AdminMessageQuoteList;
