const IMAGE_ASSETS = {
  src: {
    banner: require("./designs/QS LaptopMobile 1.png"),
    saveTime: require("./designs/save time 1.png"),
    getPaid: require("./designs/get paid 1.png"),
    lookProfessional: require("./designs/look professional 1.png"),
    process: require("./designs/process 1.png"),
    horizontal1: require("./designs/horizontal bg 1.png"),
    quickAccess: require("./designs/quick access 1.png"),
    footerLogoWhite: require("./designs/quoteshark white logo 1.png"),
    dashboard2: require("./designs/Dashboard 2.png"),
    emailPdfPrint: require("./designs/email pdf print 1.png"),
    estimateQuote: require("./designs/estimate quote 1.png"),
    mobileQuote: require("./designs/mobile quoting 1.png"),
    partialPayments: require("./designs/partial payments 1.png"),
    genericBackground1: require("./Generic Picture 01.jpg"),
    genericBackground2: require("./Generic Picture 02.jpg"),
    genericBackground3: require("./Generic Picture 03.jpg"),
    genericBackground4: require("./Generic Picture 04.jpg"),
    genericBackground5: require("./Generic Picture 05.jpg"),
    avatarDefault: require("./avatardefault.png"),
    featuresBg: require("./designs/features bg.png"),
    lookProfessional2: require("./designs/looks professional 2.png"),
    accessSecurely1: require("./designs/Access Securely 1.png"),
    accessSecurely2: require("./designs/Access Securely 2.png"),
    instantNotification: require("./designs/Instant Notifications 1.png"),
    professionalQuoting1: require("./designs/professional quoting 1.png"),
    saveTimeInvoice: require("./designs/Save Time With Saved Invoice Items 1.png"),
    signUpDashboard2: require("./designs/signup dashboard 2.png"),
    facebookIcon: require("./designs/facebook.png"),
    googleIcon: require("./designs/google.png"),
    productBanner: require("./designs/products banner 1.png"),
    mobileHand1: require("./designs/quoteshark mobile hand 1.png"),
    quoteDeposit1: require("./designs/quotes deposits 1.png"),
    invoicingProcess1: require("./designs/the-invoicing-process 1.png"),
  },
  status: {
    accepted: require("./status/Accepted Icon.png"),
    draft: require("./status/Draft Icon.png"),
    read: require("./status/Read Icon.png"),
    rejected: require("./status/Rejected Icon.png"),
    sent: require("./status/Sent Icon.png"),
  },
  iconBtn: {
    archive: require("./icon-btn/archive icons.png"),
    duplicate: require("./icon-btn/duplicate icons.png"),
    edit: require("./icon-btn/edit icons.png"),
    email: require("./icon-btn/email icons.png"),
    pdf: require("./icon-btn/pdf icons.png"),
  },
  pdfIcons: {
    building: require("./pdf-icons/building.png"),
    mail: require("./pdf-icons/mail.png"),
    user: require("./pdf-icons/user.png"),
    phone: require("./pdf-icons/phone.png"),
    map: require("./pdf-icons/map.png"),
  },
  url: {
    genericBackground1:
      "https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/public%2FGeneric%20Picture%2001.jpg?alt=media&token=55696478-97d7-48ed-9c9f-2642cbf6eb27",
    genericBackground2: "./Generic Picture 02.jpg",
    genericBackground3: "./Generic Picture 03.jpg",
    genericBackground4: "./Generic Picture 04.jpg",
    genericBackground5: "./Generic Picture 05.jpg",
    avatarDefault:
      "https://firebasestorage.googleapis.com/v0/b/quote-shark.appspot.com/o/public%2Favatardefault.png?alt=media&token=4b4e02fd-8038-4acf-b256-a8e2f414a6b5",
  },
};
export default IMAGE_ASSETS;
