import React, {useContext} from "react";
import { Container, Row, Col} from "shards-react";

import PageTitle from "../../../components/common/PageTitle";
import RightBar from "../../../components/layout/RightBar";
import ChangePasswordForm from "../../../components/user-profile-lite/ChangePasswordForm";
import { UserContext } from '../../../providers/UserProvider';
import IMAGE_ASSETS from '../../../assets/image-list';

const ChangePassword = () => {
  const {user} = useContext(UserContext)

  if(user){

    return (
          <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle title="QuoteShark" subtitle="Change Password" md="12" className="ml-sm-auto mr-sm-auto" />
          </Row>
          <Row>
            <Col lg="9">
              <ChangePasswordForm title="Change Password" user={user} />
            </Col>
            {/* <Col lg="3">
              <RightBar />
            </Col> */}
          </Row>
        </Container>
      );
  }else{
    return (
      <Container>
      <p></p>
      </Container>
    );
  }
};

export default ChangePassword;
