import React, { useState, useEffect, useCallback, useContext } from "react";
import { useFormikContext } from "formik";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import { DataGrid } from "@mui/x-data-grid";
import makeStyles from "@mui/styles/makeStyles";
import TextField from "@mui/material/TextField";
import { Button, Row, Col } from "shards-react";
import Autocomplete from "@mui/material/Autocomplete";
import { QuoteContext } from "../../../providers/QuoteProvider";
import { UserContext } from "../../../providers/UserProvider";

const useStyles = makeStyles({
  root: {
    width: "100%",
    "& .font-tabular-nums": {
      fontVariantNumeric: "tabular-nums",
    },
  },
  padding: {
    width: "100%",
    height: "100%",
    paddingTop: 5,
  },
});

function EditLineItemsComponent(props) {
  const {
    errors,
    touched,
    values,
    resetForm,
    setFieldValue,
  } = useFormikContext();
  const [tableItems, setTableItems] = useState(props.values.lineItems);
  const [editRowsModel, setEditRowsModel] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [subtotal, setsubtotal] = useState(0.0);
  const [vat, setvat] = useState(0.0);
  const [total, settotal] = useState(0.0);
  const [updateIndex, setUpdateIndex] = useState(0);
  const [rowId, setRowId] = useState(0);
  const { getItems, items, localeCurrencyFormat } = useContext(QuoteContext);
  const { user } = useContext(UserContext);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [vatMargin, setVatMargin] = useState(14);
  const classes = useStyles();

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const NameEditCell = (props) => {
    const { id, value, api, field, row, event } = props;

    const handleKeyDown = useCallback(
      (event) => {
        if (event.key == "Enter" || event.key == "Tab") {
          event.defaultMuiPrevented = true;
          event.preventDefault();
          var totalItems = tableItems.length;
          if (event.target.value) {
            console.log("event.target.value", event.target.value);
            const editPropsDescription = {
              value: event.target.value ? event.target.value : "",
            };
            let items = [...tableItems];
            let item = { ...tableItems[totalItems - 1] };
            item.description = editPropsDescription.value;
            items[totalItems - 1] = item;
            setTableItems(items);
            setFieldValue("lineItems", items);
            api.commitCellChange({
              id: id,
              field: "description",
              props: editPropsDescription,
            });
            api.setCellMode(id, field, "view");
            api.setCellMode(id, "description", "view");
            console.log("event handleKeyDown", event);
            console.log("event.key", event.key);
          } else {
            console.log("event handleKeyDown", event);
            console.log("event.key", event.key);
            api.setCellMode(id, field, "view");
            api.setCellMode(id, "description", "view");
          }
          api.setCellMode(id, field, "view");
          api.setCellMode(id, "description", "view");
        }
      },
      [api, field, id]
    );

    return (
      <div className={classes.root}>
        <Autocomplete
          disableClearable={true}
          sx={{ width: "100%" }}
          value={value || ""}
          onChange={(event, newValue) => {
            // event.persist();

            var totalItems = tableItems.length;
            let _items = [...tableItems];
            let item = { ...tableItems[totalItems - 1] };

            if (typeof newValue === "object" && newValue.description !== null) {
              item.description = newValue.description;
              item.unit_cost = newValue.unit_cost ? newValue.unit_cost : 0;
              if (newValue.id) {
                item.item_id = newValue.id;
              }
              const editPropsDescription = {
                value: item.description ? item.description : "",
              };
              const editPropsUnitCost = {
                value: item.unit_cost ? item.unit_cost : 0,
              };
              api.setCellMode(id, "unit_cost", "edit");
              api.commitCellChange({
                id: id,
                field: "description",
                props: editPropsDescription,
              });
              api.commitCellChange({
                id: id,
                field: "unit_cost",
                props: editPropsUnitCost,
              });
              api.setCellMode(id, field, "view");
              api.setCellMode(id, "unit_cost", "view");

              _items[totalItems - 1] = item;
              setTableItems(_items);
              setFieldValue("lineItems", _items);
              console.log("onChange event", event.key);
              console.log("onChange event newValue", newValue);
              api.setCellMode(id, field, "view");
              api.setCellMode(id, "unit_cost", "view");
            }

            if (typeof newValue === "string" || newValue instanceof String) {
              item.description = newValue ? newValue : "";
              if (items && items.length > 0) {
                items.forEach((searchItem, index) => {
                  item.description = item.description.toLowerCase();
                  searchItem.description = searchItem.description.toLowerCase();
                  if (searchItem.description == item.description) {
                    item.unit_cost = searchItem.unit_cost;
                  }
                });
              }
              console.log("instanceof String", newValue);
              const editPropsDescription = {
                value: newValue ? newValue : "",
              };
              api.setCellMode(id, field, "edit");
              api.commitCellChange({
                id: id,
                field: "description",
                props: editPropsDescription,
              });
              api.setCellMode(id, field, "view");
              api.setCellMode(id, field, "edit");
              api.setCellMode(id, field, "view");
              api.setCellMode(id, "unit_cost", "view");
              item.description = capitalizeFirstLetter(item.description);
              _items[totalItems - 1] = item;
              setTableItems(_items);
              setFieldValue("lineItems", _items);
            }
            api.setCellMode(id, "description", "view");

            //   console.log("newValue", newValue)
            //   console.log("event", event)
            //   api.commitCellChange({ id: id, field: "description", props: newValue });

            // else if(typeof newValue === 'object' || newValue !== null){
            //   item.description = newValue.description
            //   item.unit_cost = newValue.unit_cost ? newValue.unit_cost : 0
            //   if(newValue.id){
            //     item.item_id = newValue.id;
            //   }
            //   api.setCellMode(id, field, 'view');
            // }
          }}
          freeSolo={true}
          autoSelect={true}
          clearOnBlur={false}
          options={items}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === "string") {
              return option;
            }
            // Regular option
            return option.description;
          }}
          renderInput={(params) => {
            params.inputProps.onKeyDown = handleKeyDown;
            return (
              <TextField
                {...params}
                variant="outlined"
                className={classes.padding}
                size="medium"
                autoFocus={true}
              />
            );
          }}
        />
      </div>
    );
  };

  const renderRemoveButton = (params) => {
    const { id, value, api, field, row } = params;

    return (
      <IconButton
        aria-label="delete"
        style={{ marginLeft: -10 }}
        onClick={() => {
          var _index = id - 1;
          console.log("id ", id);
          console.log("_index ", _index);
          console.log("value ", value);
          console.log("params ", params);
          console.log("field", field);
          const newData = tableItems.filter(function (item) {
            return item !== row;
          });

          newData.map((value, index) => {
            value.id = index + 1;
          });

          setTableItems(newData);
          setFieldValue("lineItems", newData);
          console.log(newData);
        }}
      >
        <Icon className="fa fa-times" color="error" style={{ fontSize: 17 }} />
      </IconButton>
    );
  };

  const renderUnitCost = (params) => {
    const { id, value, api, field, row } = params;

    const saveUnitCost = (unit_cost) => {
      const editPropsUnitCost = {
        value: unit_cost ? unit_cost : 0,
      };

      api.commitCellChange({
        id: id,
        field: "unit_cost",
        props: editPropsUnitCost,
      });
      api.setCellMode(id, field, "view");

      let _items = [...tableItems];
      let item = { ...tableItems[id - 1] };

      item.unit_cost = unit_cost ? unit_cost : 0;

      _items[id - 1] = item;
      setTableItems(_items);
      setFieldValue("lineItems", _items);
    };

    return (
      <TextField
        variant="outlined"
        onBlur={(e) => {
          console.log("onBlur unit_cost");
          console.log(e.target.value);
          saveUnitCost(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key == "Enter" || e.key == "Tab") {
            e.defaultMuiPrevented = true;
            e.preventDefault();

            console.log("onKeyDown unit_cost");
            console.log(e.target.value);
            saveUnitCost(e.target.value);
          }
        }}
        // onChange={(e) => {
        //   //  var unit_cost =  currencyFormatter.format(Number(e.target.value))
        // }}
        className={classes.padding}
        size="medium"
        autoFocus={true}
      />
    );
  };

  const renderDescriptionEditCell = (params) => {
    return <NameEditCell {...params} style={{ width: "100%" }} />;
  };

  // Prevent from committing on blur
  const handleCellBlur = useCallback(
    ({ id, value, api, field, row, cellMode }, e) => {
      if (cellMode === "edit") {
        console.log("handleCellBlur row", row);
        console.log("row", row);
        console.log("id", id);
        console.log("field", field);
        console.log("value", value);
        console.log("event", e.target.value);
        api.setCellMode(id, field, "view");
      }
    },
    []
  );

  const handleEditCellChangeCommitted = React.useCallback(
    ({ api, id, field, props }) => {
      console.log("handleEditCellChangeCommitted");
      if (field === "unit_cost") {
        const data = props; // Fix eslint value is missing in prop-types for JS files
        const unit_cost = data.value;
        console.log("handleEditCellChangeCommitted", unit_cost);
        const updatedRows = tableItems.map((row) => {
          if (row.id === id) {
            return { ...row, unit_cost };
          }
          return row;
        });
        console.log("updatedRows", updatedRows);

        setTableItems(updatedRows);
        setFieldValue("lineItems", updatedRows);
      }
      api.setCellMode(id, "description", "view");
      api.setCellMode(id, "unit_cost", "view");
    },
    [tableItems]
  );

  const currencyFormatter = new Intl.NumberFormat(
    localeCurrencyFormat[
      user.data.currency_name ? user.data.currency_name : "en-US"
    ],
    {
      style: "currency",
      currency: user.data.currency_name ? user.data.currency_name : "USD",
    }
  );

  const usdPrice = {
    // type: 'number',
    width: 130,
    renderEditCell: renderUnitCost,
    valueFormatter: (props) => {
      return currencyFormatter.format(Number(props.value));
    },
  };

  const columns = [
    {
      field: "id",
      sortable: false,
      headerName: "#",
      flex: 0.2,
      sortable: false,
    },
    {
      field: "description",
      sortable: false,
      headerName: "Item",
      renderEditCell: renderDescriptionEditCell,
      flex: 1,
      editable: true,
      sortable: false,
    },
    {
      field: "unit_cost",
      sortable: false,
      headerName: "Unit Cost",
      flex: 0.5,
      editable: true,
      ...usdPrice,
      sortable: false,
    },
    {
      field: "",
      sortable: false,
      headerName: "",
      flex: 0.2,
      renderCell: renderRemoveButton,
      sortable: false,
    },
  ];

  const getVatMargin = () => {};

  const calculate = () => {
    var sub = 0.0;
    var vat = 0.0;
    var total = 0.0;
    var percentage = 0;
    tableItems.map((item) => {
      sub += parseFloat(item.unit_cost);
      sub = parseFloat(sub);
    });

    if (user.data.is_vat) {
      percentage = user.data.vat_rate / 100;
      vat = sub * percentage;
    }

    if (parseInt(sub) > 0 && sub.toString().length != 7) {
      var _sub = sub.toString().length;
      console.log("sub.length", _sub);
      setVatMargin(_sub * 6);
    }

    total = sub + vat;
    setsubtotal(sub.toFixed(2));
    settotal(total.toFixed(2));
    setvat(vat.toFixed(2));
    setFieldValue("subtotal", sub);
    setFieldValue("vat", vat);
    setFieldValue("total", total);
  };

  useEffect(() => {
    getItems(user.data.auth_token);

    if (values.lineItems.length > 0) {
      setTableItems(values.lineItems);
      var lastItem = values.lineItems[values.lineItems.length - 1];
      setRowId(lastItem.id);
    }
    calculate();

    return () => {
      setTableItems(props.values.lineItems);
    };
    // setFieldValue("auth_token", user.data.auth_token)
  }, [tableItems]);

  return (
    <div>
      <div style={{ height: 250, width: "100%" }}>
        <DataGrid
          rows={tableItems}
          columns={columns}
          // editRowsModel={editRowsModel}
          disableSelectionOnClick={true}
          hideFooter={true}
          disableColumnMenu={true}
          onEditCellChangeCommitted={handleEditCellChangeCommitted}
          // onCellBlur={handleCellBlur}
        />
      </div>
      <Button
        theme="success"
        className="mt-3 mb-3"
        style={{ width: 100, height: 40 }}
        type="button"
        onClick={() => {
          const newState2 = {
            id: rowId + 1,
            description: "",
            unit_cost: 0,
          };

          setRowId((state) => state + 1);
          const newData = [...tableItems, { ...newState2 }];
          console.log("Before Add Item", tableItems);
          setTableItems(newData);
          console.log("Add Item", newData);
          setFieldValue("lineItems", newData);
        }}
      >
        Add Item
      </Button>
      <Row>
        <Col lg={8}></Col>
        <Col lg={4}>
          {user.data.is_vat && (
            <div>
              <Row>
                <Col>
                  <span className="text-center text-semibold" style={{}}>
                    Subtotal
                  </span>
                </Col>
                <Col>
                  <span className="float-right" style={{ marginRight: 12 }}>
                    {currencyFormatter.format(Number(subtotal))}
                  </span>
                </Col>
              </Row>
              <hr style={{ marginTop: 8, marginBottom: 10 }} />
              <Row>
                <Col>
                  <span className="text-center text-semibold" style={{}}>
                    {user.data.tax_label_name
                      ? user.data.tax_label_name
                      : "VAT"}
                    ({user.data.is_vat ? parseInt(user.data.vat_rate) : 0}%)
                  </span>
                </Col>
                <Col>
                  <span className="float-right" style={{ marginRight: 12 }}>
                    {currencyFormatter.format(Number(vat))}
                  </span>
                </Col>
              </Row>
            </div>
          )}
          <Row
            className="bg-primary"
            style={{
              paddingTop: 10,
              paddingBottom: 10,
              marginLeft: 1,
              marginRight: 1,
              marginTop: 10,
            }}
          >
            <Col>
              <span
                className="text-center text-semibold"
                style={{ fontWeight: 600, color: "white" }}
              >
                Total
              </span>
            </Col>
            <Col>
              <span
                className="float-right"
                style={{ fontWeight: 600, color: "white" }}
              >
                {currencyFormatter.format(Number(total))}
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default EditLineItemsComponent;
