import React from "react";
import PropTypes from "prop-types";

import PublicNavbar from "../components/layout/MainNavbar/PublicNavbar";
// import MainFooter from "../components/layout/MainFooter";

const PublicLayout = ({ children, noNavbar, noFooter }) => (
  <div
    className="justify-content-center align-items-center"
    style={{ margin: "auto" }}
  >
    {!noNavbar && <PublicNavbar />}
    {children}
  </div>
);

PublicLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool,
};

PublicLayout.defaultProps = {
  noNavbar: false,
  noFooter: false,
};

export default PublicLayout;
