import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../../providers/UserProvider";
import { QuoteContext } from "../../../providers/QuoteProvider";
import PageTitle from "../../../components/common/PageTitle";
import PropTypes from "prop-types";
import IMAGE_ASSETS from "../../../assets/image-list";
import { Link, useHistory } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Container,
  Button,
} from "shards-react";
import { Form, Badge } from "react-bootstrap";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import DraftsIcon from "@mui/icons-material/Drafts";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import NotyfContext from "../../../providers/NotificationContext";
import moment from "moment";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import SavePdfQuote from "./pdf/SavePdfQuote";
import PdfViewQuote from "./pdf/PdfViewQuote";
import { PDFDownloadLink, pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import ReactPDF from "@react-pdf/renderer";
import { jsPDF } from "jspdf";

const ShowQuote = ({ title }) => {
  const { user } = useContext(UserContext);
  const {
    loading,
    resendQuote,
    restoreQuote,
    updateQuoteStatus,
    quoteListDetails,
    getQuotesDetails,
    archiveQuote,
    localeCurrencyFormat,
  } = useContext(QuoteContext);
  const [showEditStatus, setShowEditStatus] = useState(false);
  const [showRejectQuote, setShowRejectQuote] = useState(false);
  const [rejectQuoteReason, setRejectQuoteReason] = useState("");
  const currentDate = moment().startOf("day");
  const history = useHistory();
  const MySwal = withReactContent(Swal);

  const notyf = useContext(NotyfContext);

  const currencyFormatter = new Intl.NumberFormat(
    localeCurrencyFormat[
      user.data.currency_name ? user.data.currency_name : "en-US"
    ],
    {
      style: "currency",
      currency: user.data.currency_name ? user.data.currency_name : "USD",
    }
  );

  useEffect(() => {
    if (!quoteListDetails) {
      getQuotesDetails(
        user.data.auth_token,
        JSON.parse(localStorage.getItem("showQuoteDetails"))
      );
    }
  }, []);

  useEffect(() => {
    // getQuotesDetails(user.data.auth_token ,JSON.parse(localStorage.getItem('showQuoteDetails')))
  }, [updateQuoteStatus]);

  const getTerms = () => {
    if (quoteListDetails.terms_is_deposit_required) {
      if (quoteListDetails.terms_is_deposit_fixed == true) {
        return (
          <span>
            A deposit of{" "}
            <b>
              {currencyFormatter.format(
                Number(quoteListDetails.terms_deposit_amount)
              )}
            </b>{" "}
            is payable on acceptance of this quote.
          </span>
        );
      } else {
        return (
          <span>
            A deposit of{" "}
            <b>
              {currencyFormatter.format(
                Number(
                  parseInt(quoteListDetails.total) *
                    (parseInt(quoteListDetails.terms_deposit_percentage) / 100)
                )
              )}
            </b>{" "}
            ({quoteListDetails.terms_deposit_percentage}%) is payable on
            acceptance of this quote.
          </span>
        );
      }
    }
  };

  const getIcon = (status) => {
    if (status == "Sent") {
      return (
        <img
          className="mr-2"
          //  style={{width: "100%"}}
          src={IMAGE_ASSETS.status.sent}
          alt="sent"
        />
      );
    } else if (status == "Read") {
      return (
        <img
          className="mr-2"
          //  style={{width: "100%"}}
          src={IMAGE_ASSETS.status.read}
          alt="read"
        />
      );
    } else if (status == "Accepted") {
      return (
        <img
          className="mr-2"
          //  style={{width: "100%"}}
          src={IMAGE_ASSETS.status.accepted}
          alt="Accepted"
        />
      );
    } else if (status == "Draft") {
      return (
        <img
          className="mr-2"
          //  style={{width: "100%"}}
          src={IMAGE_ASSETS.status.draft}
          alt="draft"
        />
      );
    }
    return (
      <img
        className="mr-2"
        //  style={{width: "100%"}}
        src={IMAGE_ASSETS.status.rejected}
        alt="rejected"
      />
    );
  };

  const getColorStatus = (status) => {
    if (status === "Sent") {
      return "#007DC4";
    } else if (status === "Draft") {
      return "#007DC4";
    } else if (status === "Read") {
      return "#ff9800";
    } else if (status === "Accepted") {
      return "#5BC772";
    } else if (status === "Rejected") {
      return "#ED4134";
    }
  };

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="QuoteShark"
          viewTitle="Quote List"
          subtitle={
            quoteListDetails ? quoteListDetails.transaction_number : null
          }
          md="12"
          className="ml-sm-auto mr-sm-auto"
        />
      </Row>
      {quoteListDetails && loading === false && (
        <Row className="justify-content-center align-items-center">
          <Col lg={8}>
            {quoteListDetails.is_archived ? (
              <div
                className="float-right"
                style={{ paddingTop: 6, marginBottom: 5 }}
              >
                <Badge pill variant="danger">
                  Archived
                </Badge>
              </div>
            ) : null}
            <Card small className="mb-4 mt-5" id="printToPdf">
              {user && (
                <Row
                  style={{ border: "1px solid gray", backgroundColor: "#fff" }}
                >
                  <Col
                    lg={12}
                    style={{
                      borderBottom: "1px solid gray",
                      padding: 5,
                      backgroundColor: "#fff",
                    }}
                  >
                    <Row>
                      <Col
                        hidden={!showEditStatus}
                        lg={5}
                        md={12}
                        sm={12}
                        xs={12}
                        className="col-6"
                      >
                        <span
                          className=""
                          style={{
                            fontWeight: 600,
                            marginLeft: 10,
                            color: "black",
                          }}
                        >
                          Status
                        </span>
                        <select
                          defaultValue={quoteListDetails.status_id}
                          className="form-select form-select-lg"
                          style={{ padding: 6, marginLeft: 20, marginTop: 5 }}
                          onChange={(e) => {
                            if (e.target.value !== 0) {
                              updateQuoteStatus(
                                user.data.auth_token,
                                quoteListDetails.id,
                                e.target.value
                              );
                              getQuotesDetails(
                                user.data.auth_token,
                                quoteListDetails.id
                              );
                              setShowEditStatus(false);
                            }
                          }}
                        >
                          <option style={{ fontSize: 13 }} value={0} disabled>
                            Draft
                          </option>
                          <option style={{ fontSize: 13 }} value={1} disabled>
                            Sent
                          </option>
                          <option style={{ fontSize: 13 }} value={2} disabled>
                            Read
                          </option>
                          <option
                            style={{ fontSize: 13 }}
                            value={3}
                            disabled={
                              quoteListDetails.status_id == 0 ? true : false
                            }
                          >
                            Rejected
                          </option>
                          <option
                            style={{ fontSize: 13 }}
                            value={4}
                            disabled={
                              quoteListDetails.status_id == 0 ? true : false
                            }
                          >
                            Accepted
                          </option>
                        </select>
                        <span
                          className="text-primary"
                          style={{
                            cursor: "pointer",
                            marginLeft: 12,
                            fontWeight: 600,
                          }}
                          onClick={() => {
                            setShowEditStatus(false);
                          }}
                        >
                          Cancel
                        </span>
                      </Col>
                      <Col
                        hidden={showEditStatus}
                        lg={5}
                        md={12}
                        sm={12}
                        xs={12}
                        className="col-6"
                        style={{ paddingTop: 12 }}
                      >
                        <span
                          className=""
                          style={{
                            fontWeight: 600,
                            marginLeft: 10,
                            color: "black",
                          }}
                        >
                          Status
                        </span>
                        <span
                          className=""
                          style={{
                            marginLeft: 12,
                            color: getColorStatus(quoteListDetails.status),
                          }}
                        >
                          {getIcon(quoteListDetails.status)}
                          {quoteListDetails.status}
                        </span>
                        {quoteListDetails.status_id != 0 ? (
                          <span
                            className="text-primary"
                            style={{
                              cursor: "pointer",
                              marginLeft: 25,
                              fontWeight: 600,
                            }}
                            onClick={() => {
                              setShowEditStatus(true);
                            }}
                          >
                            Edit Status
                          </span>
                        ) : null}
                      </Col>
                      <Col
                        lg={7}
                        md={12}
                        sm={12}
                        xs={12}
                        className="col-6"
                        style={{ paddingTop: 7, paddingRight: 21 }}
                      >
                        <div
                          className="float-lg-right"
                          style={{ marginBottom: 5 }}
                        >
                          {/* {quoteListDetails.is_archived ? <Badge pill variant="danger">
                          Archived
                        </Badge>: null} */}
                          <Tooltip title={"Email Quote"}>
                            <IconButton
                              size="small"
                              pill
                              className="ml-2"
                              style={{}}
                              type="button"
                              onClick={(e) => {
                                if (quoteListDetails.status_id != 0) {
                                  MySwal.fire({
                                    title: "Confirmation",
                                    text: `Do you want to resend quotation to ${quoteListDetails.customer_email} now?`,
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    cancelButtonColor: "#d33",
                                    confirmButtonText: "Send Quote",
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      notyf.success("Sending quotation...");
                                      // setSendDisabled(true)
                                      resendQuote(
                                        user.data.auth_token,
                                        quoteListDetails.id
                                      ).then((response) => {
                                        console.log("from response ", response);
                                      });
                                    }
                                  });
                                } else {
                                  MySwal.fire({
                                    title: "Info",
                                    text: `Edit quote to send email.`,
                                    showCancelButton: true,
                                    confirmButtonColor: "#3085d6",
                                    confirmButtonText: "Edit Quote",
                                  }).then((result) => {
                                    if (result) {
                                      console.log(
                                        `/quotes/edit/${quoteListDetails.transaction_number}`
                                      );
                                      history.push(
                                        `/quotes/edit/${quoteListDetails.transaction_number}`
                                      );
                                    }
                                  });
                                }
                              }}
                            >
                              <img
                                className="mr-2"
                                src={IMAGE_ASSETS.iconBtn.email}
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Download PDF">
                            <IconButton
                              size="small"
                              pill
                              className="ml-2"
                              style={{}}
                              type="button"
                              onClick={async () => {
                                const doc = (
                                  <SavePdfQuote
                                    quoteListDetails={quoteListDetails}
                                    user={user}
                                    currencyFormatter={currencyFormatter}
                                    filename={
                                      "QuoteShark-" +
                                      quoteListDetails.transaction_number
                                    }
                                  />
                                );
                                const asPdf = pdf([]); // {} is important, throws without an argument
                                asPdf.updateContainer(doc);
                                const blob = await asPdf.toBlob();
                                saveAs(
                                  blob,
                                  "QuoteShark-" +
                                    quoteListDetails.transaction_number
                                );
                              }}
                            >
                              <img
                                className="mr-2"
                                src={IMAGE_ASSETS.iconBtn.pdf}
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Edit Quote">
                            <IconButton
                              size="small"
                              onClick={() => {
                                console.log(
                                  `/quotes/edit/${quoteListDetails.transaction_number}`
                                );
                                history.push(
                                  `/quotes/edit/${quoteListDetails.transaction_number}`
                                );
                              }}
                            >
                              <img
                                className="mr-2"
                                src={IMAGE_ASSETS.iconBtn.edit}
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Duplicate Quote">
                            <IconButton
                              size="small"
                              onClick={() => {
                                console.log(
                                  `/quotes/duplicate/${quoteListDetails.transaction_number}`
                                );
                                history.push(
                                  `/quotes/duplicate/${quoteListDetails.transaction_number}`
                                );
                              }}
                            >
                              <img
                                className="mr-2"
                                src={IMAGE_ASSETS.iconBtn.duplicate}
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Archive Quote">
                            <IconButton
                              size="small"
                              onClick={() => {
                                archiveQuote(
                                  user.data.auth_token,
                                  quoteListDetails.id
                                );
                                getQuotesDetails(
                                  user.data.auth_token,
                                  quoteListDetails.id
                                );
                              }}
                            >
                              <img
                                className="mr-2"
                                src={IMAGE_ASSETS.iconBtn.archive}
                              />
                            </IconButton>
                          </Tooltip>

                          {/* <select defaultValue={0} className="form-select form-select-lg" style={{padding: 6,  marginLeft: 7}}  onChange={(e) => {

                            console.log(`Select Value ${e.target.value}`)

                            if(e.target.value == 1){
                              console.log(`/quotes/edit/${quoteListDetails.transaction_number}`)
                              history.push(`/quotes/edit/${quoteListDetails.transaction_number}`)
                            }
                            if(e.target.value == 2){
                              console.log(`/quotes/duplicate/${quoteListDetails.transaction_number}`)
                              history.push(`/quotes/duplicate/${quoteListDetails.transaction_number}`)
                            }
                            if(e.target.value == 3){
                              archiveQuote(user.data.auth_token, quoteListDetails.id)
                              getQuotesDetails(user.data.auth_token , quoteListDetails.id)
                            }
                            if(e.target.value == 4){
                              restoreQuote(user.data.auth_token, quoteListDetails.id)
                              getQuotesDetails(user.data.auth_token , quoteListDetails.id)
                            }
                              // var res = updateQuoteStatus(user.data.auth_token, quote.id, e.target.value)
                            }}>
                                <option style={{fontSize: 13}} value={0}>Quote Options</option>
                                <option style={{fontSize: 13}} value={1} disabled={quoteListDetails.status_id != 4 ? false : true}>Edit Quote</option>
                                <option style={{fontSize: 13}} value={2}>Duplicate Quote</option>
                                {quoteListDetails.is_archived == false ? <option style={{fontSize: 13}} value={3}>Archive Quote</option>: null}
                               {quoteListDetails.is_archived == true ? <option style={{fontSize: 13}} value={4}>Restore Quote</option> : null}
                          </select> */}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={12} className="pt-4">
                    <span className="" style={{ fontWeight: 600 }}>
                      <i className="fa fa-building" aria-hidden="true"></i>{" "}
                      {user.data.business_name}
                      <br />
                    </span>
                    <span className="">
                      <i className="fa fa-user" aria-hidden="true"></i>{" "}
                      {user.data.name}
                    </span>
                    <br />
                    {user.data.contact_number && (
                      <span className="">
                        <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                        {user.data.contact_number}
                        <br />
                      </span>
                    )}
                    <span className="">
                      <i className="fa fa-envelope" aria-hidden="true"></i>{" "}
                      {user.data.email}
                    </span>
                    <br />
                    <span className="">
                      <i className="fa fa-map-pin" aria-hidden="true"></i>{" "}
                      {user.data.business_address}
                      <br />
                    </span>
                  </Col>

                  <Col
                    lg={12}
                    className="bg-primary"
                    style={{ marginTop: 20, height: 30 }}
                  >
                    <span>&nbsp;</span>
                    <span
                      style={{
                        fontSize: 40,
                        fontWeight: 700,
                        backgroundColor: "#FFF",
                        color: "#404144",
                        position: "absolute",
                        right: 83,
                        top: -15,
                        paddingLeft: 9,
                        paddingRight: 9,
                      }}
                    >
                      QUOTE
                    </span>
                  </Col>

                  {quoteListDetails && (
                    <>
                      <Col lg={8} className="pt-lg-4 pt-2">
                        <span style={{ fontWeight: 600, fontSize: 16 }}>
                          Quote To:
                        </span>
                        <br />
                        <span style={{ fontWeight: 600 }}>
                          <i className="fa fa-user" aria-hidden="true"></i>{" "}
                          {quoteListDetails.customer_name}
                        </span>
                        <br />
                        {quoteListDetails.customer_contact_number && (
                          <span className="">
                            <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                            {quoteListDetails.customer_contact_number}
                            <br />
                          </span>
                        )}
                        <span>
                          <i className="fa fa-envelope" aria-hidden="true"></i>{" "}
                          {quoteListDetails.customer_email}
                        </span>
                        <br />
                        <span>
                          <i className="fa fa-map-pin" aria-hidden="true"></i>{" "}
                          {quoteListDetails.customer_address}
                        </span>
                        <br />
                      </Col>
                      <Col lg={4} className="pt-lg-4 pt-2">
                        <br />
                        <span style={{ fontWeight: "bold" }}>Ref #:</span>
                        <span> {quoteListDetails.transaction_number}</span>
                        <br />
                        <span style={{ fontWeight: "bold" }}>Quote Date:</span>
                        <span>
                          {" "}
                          {moment(quoteListDetails.created_at).format(
                            user.data.date_format_name
                              ? user.data.date_format_name
                              : "DD/MM/YYYY"
                          )}
                        </span>
                        <br />
                        <span style={{ fontWeight: "bold" }}>Expiry Date:</span>
                        <span>
                          {" "}
                          {quoteListDetails.expiration_date
                            ? moment(quoteListDetails.expiration_date).format(
                                user.data.date_format_name
                                  ? user.data.date_format_name
                                  : "DD/MM/YYYY"
                              )
                            : moment(
                                moment(currentDate, "DD-MM-YYYY").add(
                                  user.data.quote_expire_in_days,
                                  "days"
                                )
                              ).format(
                                user.data.date_format_name
                                  ? user.data.date_format_name
                                  : "DD/MM/YYYY"
                              )}
                        </span>
                        <br />
                      </Col>
                    </>
                  )}

                  <Col lg={12} className="pt-lg-5 pt-3">
                    <table
                      className="custom-table w-100"
                      style={{ border: "1px solid #404144", marginBottom: 20 }}
                    >
                      <thead>
                        <tr
                          style={{ backgroundColor: "#404144", color: "white" }}
                        >
                          <th colSpan="1">#</th>
                          <th colSpan="5">Description</th>
                          <th colSpan="1">
                            <p className="text-right  m-0">Unit Cost</p>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {quoteListDetails &&
                          quoteListDetails.items.map((lineItem, index) => {
                            return (
                              <tr key={"item" + lineItem.id}>
                                <td colSpan="1">{index + 1}</td>
                                <td colSpan="5">{lineItem.item_description}</td>
                                <td colSpan="1">
                                  <p className="text-right  m-0">
                                    {currencyFormatter.format(
                                      Number(lineItem.item_unit_cost)
                                    )}
                                  </p>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </Col>
                  <Col lg={8}></Col>
                  {quoteListDetails && (
                    <Col lg={4}>
                      {user.data.is_vat && (
                        <div>
                          <Row>
                            <Col>
                              <span
                                className="text-center text-semibold"
                                style={{}}
                              >
                                Subtotal
                              </span>
                            </Col>
                            <Col>
                              <span
                                className="float-right"
                                style={{ marginRight: 12 }}
                              >
                                {currencyFormatter.format(
                                  Number(quoteListDetails.subtotal)
                                )}
                              </span>
                            </Col>
                          </Row>
                          <hr style={{ marginTop: 8, marginBottom: 10 }} />
                          <Row>
                            <Col>
                              <span
                                className="text-center text-semibold"
                                style={{}}
                              >
                                {user.data.tax_label_name
                                  ? user.data.tax_label_name
                                  : "VAT"}
                                (
                                {user.data.is_vat
                                  ? parseInt(user.data.vat_rate)
                                  : 0}
                                %)
                              </span>
                            </Col>
                            <Col>
                              <span
                                className="float-right"
                                style={{ marginRight: 12 }}
                              >
                                {currencyFormatter.format(
                                  Number(quoteListDetails.vat_amount)
                                )}
                              </span>
                            </Col>
                          </Row>
                        </div>
                      )}
                      <Row
                        className="bg-primary"
                        style={{
                          paddingTop: 10,
                          paddingBottom: 10,
                          marginLeft: 1,
                          marginRight: 1,
                          marginTop: 10,
                        }}
                      >
                        <Col>
                          <span
                            className="text-center text-semibold"
                            style={{ fontWeight: 600, color: "white" }}
                          >
                            Total
                          </span>
                        </Col>
                        <Col>
                          <span
                            className="float-right"
                            style={{ fontWeight: 600, color: "white" }}
                          >
                            {currencyFormatter.format(
                              Number(quoteListDetails.total)
                            )}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  )}

                  {quoteListDetails.terms_is_deposit_required && (
                    <Col lg={12} className="pt-4">
                      <span className="" style={{ fontWeight: 600 }}>
                        <u>TERMS AND CONDITIONS</u>
                      </span>
                      <br />
                      {getTerms()}
                    </Col>
                  )}

                  {quoteListDetails ? (
                    <Col className="mb-5">
                      {quoteListDetails.terms_other_conditions !== "" ? (
                        <Row>
                          <Col lg={12} className="pt-4">
                            <span className="text-semibold ">
                              Other conditions
                            </span>
                            <br />
                            <span className="">
                              {quoteListDetails.terms_other_conditions}
                            </span>
                            <br />
                          </Col>
                        </Row>
                      ) : null}
                    </Col>
                  ) : null}
                </Row>
              )}
            </Card>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default ShowQuote;
