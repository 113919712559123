import React, { useEffect, useContext} from 'react'
import { Field, useFormikContext } from 'formik'
import {  Row, Col} from 'shards-react'
import TextField from '@mui/material/TextField';
import { UserContext } from '../../../providers/UserProvider';


function ProfileDetailsComponent() {
  const { errors, touched, values, setFieldValue } = useFormikContext()
  const {user, changePassword} = useContext(UserContext)

  useEffect(() => {
    if(user.data && user.data.auth_token){
      setFieldValue('email', user.data.email)
    }
  }, [])

  useEffect(() => {
  }, [])

  return (
    <div className="w-100">
          <Row>
            <Col lg="6" md="12">
            <Row>
                  <Col className="form-group">
                  <Field
                    autoFocus={true}
                    variant="outlined"
                    type="text"
                    as={TextField}
                    id="business_name"
                    name="business_name"
                    label={<span>Business Name<span className="text-danger"> *</span></span>}
                    className={`form-control input ${errors.business_name ? "input-error" : ""}`}
                      onChange={(e) => {setFieldValue("business_name", e.target.value)}}
                  />
                  <small style={{ color: 'red' }}>
                    {touched.business_name && errors.business_name}
                  </small>
                  </Col>
                </Row>
                <Row>
                  <Col className="form-group">
                  <Field
                    variant="outlined"
                    type="text"
                    as={TextField}
                    id="name"
                    name="name"
                    label={<span>Name<span className="text-danger"> *</span></span>}
                    className={`form-control input ${errors.name ? "input-error" : ""}`}
                      onChange={(e) => {setFieldValue("name", e.target.value)}}
                  />
                  <small style={{ color: 'red' }}>
                    {touched.name && errors.name}
                  </small>
                  </Col>
                </Row>
                <TextField  type="text" as={TextField} id="email" label={<span>Email<span className="text-danger"> *</span></span>}     className={`form-control input ${errors.name ? "input-error" : ""}`}  variant="outlined" readOnly disabled hidden value={values.email} />
                <Row>
                  <Col className="form-group">
                  <Field
                    variant="outlined"
                    type="text"
                    as={TextField}
                    id="contact_number"
                    name="contact_number"
                    label={<span>Phone<span className="text-danger"> *</span></span>}
                    className={`form-control input ${errors.contact_number ? "input-error" : ""}`}
                      onChange={(e) => {setFieldValue("contact_number", e.target.value)}}
                  />
                      <small style={{ color: 'red' }}>
                        {touched.contact_number && errors.contact_number}
                      </small>
                  </Col>
                </Row>
                <Row>
                  <Col className="form-group">
                      <TextField  id="website" label="Website"   className={`form-control input ${errors.name ? "input-error" : ""}`}  variant="outlined" value={values.website}   onChange={(e) => {setFieldValue("website", e.target.value)}}/>
                  </Col>
                </Row>
                <Row>
                  <Col className="form-group">
                  <Field
                    variant="outlined"
                    type="text"
                    as={TextField}
                    id="business_address"
                    name="business_address"
                    label={<span>Business Address<span className="text-danger"> *</span></span>}
                    className={`form-control input ${errors.business_address ? "input-error" : ""}`}
                      onChange={(e) => {setFieldValue("business_address", e.target.value)}}
                  />
                  <small style={{ color: 'red' }}>
                    {touched.business_address && errors.business_address}
                  </small>
                  </Col>
                </Row>
                <Row>
                  <Col className="form-group">
                      <TextField  id="business_registration_number" label="Business Registration Number"   className={`form-control input ${errors.name ? "input-error" : ""}`}  variant="outlined" value={values.business_registration_number}   onChange={(e) => {setFieldValue("business_registration_number", e.target.value)}}/>
                  </Col>
                </Row>
                <p className="text-muted m-0" style={{fontSize: 12, paddingBottom: 3}}>Please complete all required fields (<span className="text-danger">*</span>)</p>
            </Col>
          </Row>
    </div>
  )
}

export default ProfileDetailsComponent
