import React, {useContext ,useState, useEffect} from "react";
import {PDFViewer, PDFDownloadLink, Page, Text, View, Document, StyleSheet, Svg } from '@react-pdf/renderer';
import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Container,
  Button
} from "shards-react";
import { UserContext } from '../../../../providers/UserProvider';
import { QuoteContext } from '../../../../providers/QuoteProvider';
import moment from "moment";
// Create styles
const styles = StyleSheet.create({

  page: {
    width: "100%",
    height: "100%",
    backgroundColor: '#fff',
    fontSize: 11,
    color: "#495057",
    fontWeight: "normal"
  },
  section: {
    border: '1px solid gray',
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  bigDivider: {fontSize: 40, fontWeight: 700, backgroundColor: "#FFF", color: "#404144", position: "absolute", right: 90, top: -9, paddingLeft: 9, paddingRight: 9},
  spanHeader : {
    fontSize: 12,
    fontWeight: 800
  },

  customTable: {
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "100%",
    border: "1px solid #ddd",
  },

  cell: {
    textAlign: "left",
    padding: 16,
  },

  table: {
  display: "table", width: "auto",
  borderCollapse: "collapse",
  borderSpacing: 0,
  width: "100%",
  border: "1px solid #ddd"
},

  tableRow: { margin: "auto", flexDirection: "row" },

  tableCol: { width: "25%"},

  tableCell: { margin: "auto", marginTop: 5, fontSize: 10, textAlign: "left",
  paddingTop: 5, paddingBottom: 5, }
});

const PdfViewQuote = (props) => {
  const {user} = useContext(UserContext)
  const {resendQuote, restoreQuote, updateQuoteStatus, quoteListDetails, getQuotesDetails, archiveQuote, localeCurrencyFormat} = useContext(QuoteContext)

  useEffect(() => {

    // if(!quoteListDetails){
    //   getQuotesDetails(user.data.auth_token ,JSON.parse(localStorage.getItem('showQuoteDetails')))
    // }

  }, [])

  return (<Row>
    <Col>
    <Text>ets</Text>
    {/* <PDFViewer width={"100%"} height={700}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
              <Text style={styles.spanHeader, {marginBottom: 3}}>{props.user.data.business_name}</Text>
              <Text style={{marginBottom: 3}}><span className="" style={{fontWeight: 600}}> <i className="fa fa-user" aria-hidden="true"></i>{props.user.data.email}</span></Text>
              <Text style={{marginBottom: 3}}><span className="" style={{fontWeight: 600}}> <i className="fa fa-user" aria-hidden="true"></i>{props.user.data.contact_number}</span></Text>
              <Text style={{marginBottom: 3}}><span className="" style={{fontWeight: 600}}> <i className="fa fa-user" aria-hidden="true"></i>{props.user.data.business_address}</span></Text>

            <View  style={{marginTop: 20, height: 30, backgroundColor: "#167AF6"}}>
                    <Text style={styles.bigDivider}>QUOTE</Text>
            </View>
            <View  style={{flexDirection: 'row'}}>
              <View style={{flex: 1}}>
                <Text><span style={{fontWeight: 600, fontSize: 16}}>Quote To:</span></Text>
                <Text><span style={{fontWeight: 600}}><i className="fa fa-user" aria-hidden="true"></i> {props.quoteListDetails.customer_name}</span></Text>
                <Text><span><i className="fa fa-envelope" aria-hidden="true"></i> {props.quoteListDetails.customer_email}</span></Text>
                <Text><span><i className="fa fa-map-pin" aria-hidden="true"></i> {props.quoteListDetails.customer_address}</span></Text>
              </View>
              <View style={{flex: 1}}>
                <Text> <span style={{fontWeight: 'bold'}}>Ref #:</span><span> {props.quoteListDetails.transaction_number}</span></Text>
                <Text><span style={{fontWeight: 'bold'}}>Quote Date:</span><span> {moment(props.quoteListDetails.created_at).format(props.user.data.date_format_name ? props.user.data.date_format_name : "DD/MM/YYYY")}</span></Text>
                <Text><span style={{fontWeight: 'bold'}}>Expiry Date:</span><span> {moment(props.quoteListDetails.expiration_date).format(props.user.data.date_format_name ? props.user.data.date_format_name : "DD/MM/YYYY")}</span></Text>
              </View>
            </View>

            <View style={styles.table}>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                <Text style={styles.tableCell}>#</Text>
                </View>

                <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Description</Text>
                </View>

                <View style={styles.tableCol}><Text style={styles.tableCell}>Unit Cost</Text></View>
              </View>
              {props.quoteListDetails && props.quoteListDetails.items.map((lineItem, index) => {
                return  (
                  <View style={styles.tableRow}>

                  <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{index + 1}</Text>
                  </View>

                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{lineItem.item_description}</Text>
                  </View>

                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{props.currencyFormatter.format(Number(lineItem.item_unit_cost))}</Text>
                  </View>
                </View>)
              })}
          </View>



        </View>
        </Page>
      </Document>
    </PDFViewer> */}
    </Col>
    </Row>);
};

export default PdfViewQuote;
