import React from "react";
import PropTypes from "prop-types";
import { Link, useHistory } from 'react-router-dom';

import PublicNavbar from "../components/layout/MainNavbar/PublicNavbar";
// import MainFooter from "../components/layout/MainFooter";

const NoNavLayout = ({ children, noNavbar, noFooter }) => (
 <div className="justify-content-center align-items-center" style={{maxWidth: 1300, margin: 'auto'}}>
        {children}
      <div>
        <p style={{textAlign: 'center'}}>This quote was sent using QuoteShark.co - click here for our <Link to="/terms-and-condition" style={{}}>
        terms of use
        </Link></p>
      </div>
 </div>
);

NoNavLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool
};

NoNavLayout.defaultProps = {
  noNavbar: false,
  noFooter: false
};


export default NoNavLayout;

