import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import { Navbar, Nav, Image } from "react-bootstrap";
import { Button } from "shards-react";
import { UserContext } from "../../../providers/UserProvider";
const PublicNavbar = ({ layout, stickyTop }) => {
  const { user, setUser, logOut } = useContext(UserContext);
  const history = useHistory();

  return (
    <div className="container">
      <Navbar collapseOnSelect expand="lg" variant="light">
        <Navbar.Brand href="#home" style={{ color: "black" }}>
          {" "}
          <Image
            src={require("../../../assets/rsz_colored-logo.png")}
            style={{ height: "100%", width: 170 }}
          />{" "}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="" style={{ margin: "auto" }}>
            <Nav.Link
              as={Link}
              to="/"
              style={{
                marginRight: 16,
                color: history.location.pathname == "/" ? "#007DC4" : "#5C5C69",
                fontWeight: 600,
              }}
            >
              Overview
            </Nav.Link>
            {/* <Nav.Link
              as={Link}
              to="#how-it-works"
              style={{
                marginRight: 8,
                width: 100,
                color: "#5C5C69",
                fontWeight: 600,
              }}
            >
              How It Works
            </Nav.Link> */}
            <Nav.Link
              as={Link}
              to="features"
              style={{
                marginRight: 16,
                color:
                  history.location.pathname == "/features"
                    ? "#007DC4"
                    : "#5C5C69",
                fontWeight: 600,
              }}
            >
              Features
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="product"
              style={{
                marginRight: 16,
                color:
                  history.location.pathname == "/product"
                    ? "#007DC4"
                    : "#5C5C69",
                fontWeight: 600,
              }}
            >
              Product
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="about-us"
              style={{
                marginRight: 16,
                color:
                  history.location.pathname == "/about-us"
                    ? "#007DC4"
                    : "#5C5C69",
                fontWeight: 600,
              }}
            >
              About Us
            </Nav.Link>
          </Nav>
          <Nav>
            {user ? (
              <>
                <button
                  className="btn btn-block login-btn"
                  onClick={() => history.push("/dashboard")}
                >
                  DASHBOARD
                </button>
                <button
                  className="btn btn-block sign-up-btn"
                  onClick={() => {
                    logOut(user);
                    setUser(null);
                  }}
                  style={{ margin: 0 }}
                >
                  LOGOUT
                </button>
              </>
            ) : (
              <>
                <button
                  className="btn btn-block login-btn"
                  onClick={() => history.push("/login")}
                >
                  LOGIN NOW
                </button>
                <button
                  className="btn btn-block sign-up-btn"
                  onClick={() => history.push("/sign-up")}
                  style={{ margin: 0 }}
                >
                  SIGN UP
                </button>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

PublicNavbar.propTypes = {
  /**
   * The layout type where the PublicNavbar is used.
   */
  layout: PropTypes.string,
  /**
   * Whether the main navbar is sticky to the top, or not.
   */
  stickyTop: PropTypes.bool,
};

PublicNavbar.defaultProps = {
  stickyTop: true,
};

export default PublicNavbar;
