import React, {useState, useEffect, useContext} from 'react'
import { Field, useFormikContext } from 'formik'
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  FormCheckbox,
  Button
} from "shards-react";
import TextField from '@mui/material/TextField';
import {Form as ReactForm} from 'react-bootstrap';
import { UserContext } from '../../../providers/UserProvider';


function EditTermsAndConditionDetails(props) {
  const { errors, touched, values, setFieldValue, handleChange } = useFormikContext()
  const {user, changePassword} = useContext(UserContext)
  const [isChecked, setIsChecked] = useState(false)


  const setIsVat = (val) => {
    setIsChecked(val)
    return val;
  }

  useEffect(() => {
    if(user.data && user.data.auth_token){
      // setFieldValue('email', user.data.email)
    }
  }, [])

  useEffect(() => {
  }, [])

  return (
            <Col lg="12" md="12">
                     {/* <Row >
                        <Col lg={12}>
                          <Row style={{marginLeft: 1}}>
                            <span className="mr-1">Quote to expire in </span><Field type="number" name="quote_expire_in_days" value={values.quote_expire_in_days} onChange={(e) => {setFieldValue("quote_expire_in_days", e.target.value)}} style={{width: 50, height: 30, marginTop: -5}} /><span style={{marginLeft: 4}}>Days.</span>
                          </Row>
                        </Col>
                    </Row> */}
                      <Row className="mt-4">
                        <Col lg={3}> <span>Is a deposit required?</span></Col>
                        <Col lg={6} className="">
                          <div role="group" aria-labelledby="my-radio-group">
                            <label>
                                <Field type="radio" name="terms_is_deposit_required" value={"false"} checked="checked" />
                                <span> No Deposit</span>
                              </label>
                              <label className="ml-2">
                                <Field type="radio" name="terms_is_deposit_required" value={"true"} />
                                <span> Deposit Required</span>
                              </label>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-4" hidden={values.terms_is_deposit_required == "true" ? false: true} >
                        <Col lg={3}> <span>Deposit method</span></Col>
                        <Col lg={6} className="">
                          <div role="group" aria-labelledby="my-radio-group">
                            <label>
                              <Field type="radio" name="terms_is_deposit_fixed" value={"false"}  checked="checked"/>
                              <span> Percentage Total</span>
                            </label>
                            <label className="ml-2">
                            <Field type="radio" name="terms_is_deposit_fixed" value={"true"} />
                            <span> Fixed Amount</span>
                            </label>
                          </div>
                        </Col>
                      </Row>
          <div className="mt-4"  hidden={values.terms_is_deposit_required == "true" ? false: true}>
            <Row hidden={values.terms_is_deposit_fixed == "true" ? true: false}>
              <Col lg={12}>
                <Row  style={{marginLeft: 1}}>
                  <span className="mr-1">Percentage deposit </span><Field type="number" name="terms_deposit_percentage" style={{width: 50, height: 30, marginTop: -5}} /><span style={{marginLeft: 4}}>&#x25;</span>
                </Row>
              </Col>
            </Row>
            <Row hidden={values.terms_is_deposit_fixed == "true" ? false: true}>
              <Col lg={12}>
                <Row  style={{marginLeft: 1}}>
                  <span className="mr-1">Fixed deposit </span><span style={{marginLeft: 4}}>&#x24;</span><Field type="number" name="terms_deposit_amount" style={{width: 80, height: 30, marginTop: -5}} />
                </Row>
              </Col>
            </Row>
          </div>


          <Row className="mt-4" >
              <Col lg={6}>
                <ReactForm.Group controlId="exampleForm.ControlTextarea1">
                  <ReactForm.Label>Other Conditions</ReactForm.Label>

                  <ReactForm.Control as="textarea" rows={3} value={values.terms_other_conditions}  onChange={(e) => {
                    setFieldValue('terms_other_conditions', e.target.value)
                  }}/>
                </ReactForm.Group>
              </Col>
          </Row>
     </Col>
  )
}

export default EditTermsAndConditionDetails;
