import React, { useContext, useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import CreateIcon from "@mui/icons-material/Create";
import { Spinner } from "react-bootstrap";
import LinearProgress from "@mui/material/LinearProgress";
import { Card, ListGroup, ListGroupItem, Row, Col } from "shards-react";
import IMAGE_ASSETS from "../../../../assets/image-list";

const ListTableComponent = ({ user, customers, loading, getQuotesDetails }) => {
  const history = useHistory();
  const getIcon = (status) => {
    if (status == "Sent") {
      return (
        <img
          className="ml-2"
          //  style={{width: "100%"}}
          src={IMAGE_ASSETS.status.sent}
          alt="sent"
        />
      );
    } else if (status == "Read") {
      return (
        <img
          className="ml-2"
          //  style={{width: "100%"}}
          src={IMAGE_ASSETS.status.read}
          alt="read"
        />
      );
    } else if (status == "Accepted") {
      return (
        <img
          className="ml-2"
          //  style={{width: "100%"}}
          src={IMAGE_ASSETS.status.accepted}
          alt="Accepted"
        />
      );
    } else if (status == "Draft") {
      return (
        <img
          className="ml-2"
          //  style={{width: "100%"}}
          src={IMAGE_ASSETS.status.draft}
          alt="draft"
        />
      );
    }
    return (
      <img
        className="ml-2"
        //  style={{width: "100%"}}
        src={IMAGE_ASSETS.status.rejected}
        alt="rejected"
      />
    );
  };

  const getColorStatus = (status) => {
    if (status === "Sent") {
      return "#007DC4";
    } else if (status === "Draft") {
      return "#007DC4";
    } else if (status === "Read") {
      return "#ff9800";
    } else if (status === "Accepted") {
      return "#5BC772";
    } else if (status === "Rejected") {
      return "#ED4134";
    }
  };

  return (
    <Card small className="mb-4">
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col className="col-12 d-none d-xl-block d-lg-block d-md-none">
              <div className="table-responsive">
                <table className="table table-hover ">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th colSpan={3}>Customer</th>
                      <th>Recent Quote</th>
                      <th>Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading == true ? (
                      <tr>
                        <td colSpan={6} align="center">
                          <Spinner animation="border" variant="primary" />
                        </td>
                      </tr>
                    ) : null}
                    {customers &&
                      customers.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td colSpan={3}>{index + 1}</td>
                            <td>
                              <p>
                                <Link
                                  style={{}}
                                  to={"/customer/view"}
                                  onClick={() => {
                                    getQuotesDetails(
                                      user.data.auth_token,
                                      data.recent_quote_id
                                    );
                                    localStorage.setItem(
                                      "showQuoteDetails",
                                      JSON.stringify(data.recent_quote_id)
                                    );
                                  }}
                                >
                                  <b>{data.customer_name}</b>
                                </Link>{" "}
                                <br />
                                <span>
                                  {data.email}
                                  <br />
                                </span>
                                <span>{data.contact_number}</span>
                              </p>
                            </td>
                            <td>
                              <Link
                                to={"/quotes/show/" + data.recent_quote_number}
                                onClick={() => {
                                  getQuotesDetails(
                                    user.data.auth_token,
                                    data.recent_quote_id
                                  );
                                  localStorage.setItem(
                                    "showQuoteDetails",
                                    JSON.stringify(data.recent_quote_id)
                                  );
                                }}
                              >
                                {" "}
                                {data.recent_quote_number}
                              </Link>
                            </td>
                            <td>{data.recent_quote_status}</td>
                            <td>
                              <IconButton
                                aria-label="edit"
                                style={{ marginTop: -10 }}
                                onClick={() => {
                                  localStorage.setItem(
                                    "editCustomer",
                                    JSON.stringify(data)
                                  );
                                  history.push(`/customers/edit/${data.id}`);
                                }}
                              >
                                <CreateIcon
                                  color="primary"
                                  style={{ fontSize: 17 }}
                                />
                              </IconButton>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </Col>

            <Col
              className="col-12 d-xs-block d-sm-block d-md-block d-lg-none d-xl-none"
              style={{ marginTop: 22 }}
            >
              <ListGroup flush>
                {loading == true ? <LinearProgress /> : null}
                {customers && customers.length <= 0 ? (
                  <p
                    style={{
                      fontWeight: 600,
                      textAlign: "center",
                      paddingTop: 10,
                    }}
                  >
                    No Item
                  </p>
                ) : null}

                {customers &&
                  customers.map((data, index) => {
                    return (
                      <div
                        key={index}
                        style={{ paddingLeft: 10, paddingRight: 10 }}
                      >
                        <hr style={{ color: "#7C7D7E" }} />
                        <Row key={index}>
                          <Col className="col-8">
                            <Link
                              style={{}}
                              to={"/customer/view"}
                              onClick={() => {
                                getQuotesDetails(
                                  user.data.auth_token,
                                  data.recent_quote_id
                                );
                                localStorage.setItem(
                                  "showQuoteDetails",
                                  JSON.stringify(data.recent_quote_id)
                                );
                              }}
                            >
                              <b>{data.customer_name}</b>
                            </Link>
                            <br />
                            <span style={{ fontSize: 12 }}>{data.email}</span>
                            <br />
                            {data.recent_quote_status && (
                              <p>
                                <span style={{ color: "" }}>Status:</span>{" "}
                                {getIcon(data.recent_quote_status)}
                                <span
                                  style={{
                                    color: getColorStatus(
                                      data.recent_quote_status
                                    ),
                                  }}
                                >
                                  {data.recent_quote_status}
                                </span>
                                <br />
                              </p>
                            )}
                          </Col>

                          <Col className="col-4" style={{ paddingTop: 10 }}>
                            <div style={{ position: "absolute", right: 0 }}>
                              <IconButton
                                aria-label="edit"
                                style={{ marginTop: -20, float: "right" }}
                                onClick={() => {
                                  localStorage.setItem(
                                    "editCustomer",
                                    JSON.stringify(data)
                                  );
                                  history.push(`/customers/edit/${data.id}`);
                                }}
                              >
                                <CreateIcon
                                  color="primary"
                                  style={{ fontSize: 17 }}
                                />
                              </IconButton>
                            </div>
                            {data.recent_quote_number && (
                              <div
                                style={{
                                  float: "left",
                                  position: "absolute",
                                  bottom: 25,
                                }}
                              >
                                <span style={{ fontSize: 12 }}>
                                  Recent Quote:
                                </span>
                                <br />
                                <Link
                                  to={
                                    "/quotes/show/" + data.recent_quote_number
                                  }
                                  onClick={() => {
                                    getQuotesDetails(
                                      user.data.auth_token,
                                      data.recent_quote_id
                                    );
                                    localStorage.setItem(
                                      "showQuoteDetails",
                                      JSON.stringify(data.recent_quote_id)
                                    );
                                  }}
                                >
                                  {" "}
                                  {data.recent_quote_number}
                                </Link>
                              </div>
                            )}
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
              </ListGroup>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
};

ListTableComponent.propTypes = {
  customers: PropTypes.array,
};

ListTableComponent.defaultProps = {
  customers: [],
};

export default ListTableComponent;
