import React, {useMemo, useEffect} from 'react';
import {PDFViewer, PDFDownloadLink,Font, Image, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import ReactPDF from '@react-pdf/renderer';
import moment from "moment";
import IMAGE_ASSETS from '../../../../assets/image-list';



Font.register({
  family: 'Open Sans',
  fonts: [
  { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
  { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-700.ttf', fontWeight: 700 }
  ]
  });

// Create styles
const styles = StyleSheet.create({
  page: {
    width: "100%",
    height: "100%",
    backgroundColor: '#fff',
    fontSize: 11,
    color: "#495057",
    fontWeight: "normal",
    fontFamily: "Open Sans"
  },
  section: {
    border: '1px solid gray',
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  bigDivider: {fontSize: 40, fontWeight: 700, backgroundColor: "#FFF", color: "#404144", position: "absolute", right: 90, top: -12, paddingLeft: 9, paddingRight: 9},
  spanHeader : {
    fontSize: 12,
    fontWeight: 800
  },

  customTable: {
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "100%",
    border: "1px solid #ddd",
  },

  cell: {
    textAlign: "left",
    padding: 16,
  },

  table: {
    display: "table",
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "100%",
    border: "1px solid #ddd",
    marginTop: 20
  },

  tableFooter: {
    display: "table",
    borderCollapse: "collapse",
    borderSpacing: 0,
    width: "100%",
    marginTop: 10
  },

  tableRow: {
    flexDirection: "row"
  },

  tableRowSecond: {
    flexDirection: "row",
    backgroundColor: "#F2F2F2"
  },

  tableCol: {
    textAlign: "left",
  },

  tableCell: {
    marginTop: 5,
    fontSize: 10,
    textAlign: "left",
    paddingTop: 5,
    paddingBottom: 5,
  },

  tableCellRight: {
    marginTop: 5,
    fontSize: 10,
    textAlign: "right",
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 5,
  },

  tableHeader: {
    backgroundColor: "#404144", color: 'white',
    flexDirection: "row",
  },
});

const getTerms = (quoteListDetails, currencyFormatter) => {

  if(quoteListDetails.terms_is_deposit_required){
    if(quoteListDetails.terms_is_deposit_fixed == true){
      return (<View><Text>A deposit of <b>{currencyFormatter.format(Number(quoteListDetails.terms_deposit_amount))}</b> is payable on acceptance of this quote.</Text></View>)
    }else{
      return (<View><Text>A deposit of <b>{currencyFormatter.format(Number(parseInt(quoteListDetails.total) * (parseInt(quoteListDetails.terms_deposit_percentage) / 100)))}</b> ({quoteListDetails.terms_deposit_percentage}%) is payable on acceptance of this quote.</Text></View>)
    }
  }
}

// Create Document Component
const SavePdfQuote = ({quoteListDetails, user, currencyFormatter, filename}) => {


  var deposit_amount = 0;
  var terms_method_percentage = quoteListDetails.terms_is_deposit_fixed === true ? false : true;

  if(quoteListDetails.terms_is_deposit_required){
    if(quoteListDetails.terms_is_deposit_fixed == true){
      deposit_amount = currencyFormatter.format(Number(quoteListDetails.terms_deposit_amount));
    }else{
      deposit_amount = currencyFormatter.format(Number(parseInt(quoteListDetails.total) * (parseInt(quoteListDetails.terms_deposit_percentage) / 100)));
    }
  }
  // useEffect(() => {
  //   if(quoteListDetails.terms_is_deposit_required){
  //     if(quoteListDetails.terms_is_deposit_fixed == true){
  //       deposit_amount = currencyFormatter.format(Number(quoteListDetails.terms_deposit_amount));

  //       return (<View></View>)
  //     }else{
  //       deposit_amount = currencyFormatter.format(Number(parseInt(quoteListDetails.total) * (parseInt(quoteListDetails.terms_deposit_percentage) / 100)));
  //       return (<View></View>)
  //     }
  //   }

  //   return () => {
  //     if(quoteListDetails.terms_is_deposit_required){
  //       if(quoteListDetails.terms_is_deposit_fixed == true){
  //         deposit_amount = currencyFormatter.format(Number(quoteListDetails.terms_deposit_amount));

  //         return (<View></View>)
  //       }else{
  //         deposit_amount = currencyFormatter.format(Number(parseInt(quoteListDetails.total) * (parseInt(quoteListDetails.terms_deposit_percentage) / 100)));
  //         return (<View></View>)
  //       }
  //     }
  //   }
  // }, [])


  return (
    <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>

          <View style={{flexDirection: 'row', marginBottom: 3}}>
            <Image
              style={{width: 15, height: 15}}
              src={IMAGE_ASSETS.pdfIcons.building}
            />
             <Text style={styles.spanHeader, {fontWeight: 700}}><b>{user.data.business_name}</b></Text>
          </View>
          <View style={{flexDirection: 'row', marginBottom: 3}}>
            <Image
              style={{width: 15, height: 15}}
              src={IMAGE_ASSETS.pdfIcons.user}
            />
             <Text ><span className="" style={{fontWeight: 500}}>{user.data.name}</span></Text>
          </View>
          <View style={{flexDirection: 'row', marginBottom: 3}}>
            <Image
              style={{width: 15, height: 15}}
              src={IMAGE_ASSETS.pdfIcons.phone}
            />
             <Text ><span className="" style={{fontWeight: 500}}>{user.data.contact_number}</span></Text>
          </View>
          <View style={{flexDirection: 'row', marginBottom: 3}}>
            <Image
              style={{width: 15, height: 15}}
              src={IMAGE_ASSETS.pdfIcons.mail}
            />
             <Text ><span className="" style={{fontWeight: 500}}>{user.data.email}</span></Text>
          </View>
          <View style={{flexDirection: 'row', marginBottom: 3}}>
            <Image
              style={{width: 15, height: 15}}
              src={IMAGE_ASSETS.pdfIcons.map}
            />
             <Text ><span className="" style={{fontWeight: 500}}>{user.data.business_address}</span></Text>
          </View>


        <View  style={{marginTop: 20, height: 30, backgroundColor: "#167AF6"}}>
                <Text style={styles.bigDivider}>QUOTE</Text>
        </View>



        <View  style={{flexDirection: 'row', marginTop: 20}}>
          <View style={{flex: 1}}>
            <Text style={{fontWeight: 700, fontSize: 13, marginBottom: 5, marginLeft: 2}}>Quote To:</Text>

            <View style={{flexDirection: 'row', marginBottom: 3}}>
              <Image
                style={{width: 15, height: 15}}
                src={IMAGE_ASSETS.pdfIcons.user}
              />
              <Text><span className="" style={{fontWeight: 500}}>{quoteListDetails.customer_name}</span></Text>
            </View>
            {quoteListDetails.customer_contact_number  !== "" &&  quoteListDetails.customer_contact_number  !== null ? (<View style={{flexDirection: 'row', marginBottom: 3}}>
              <Image
                style={{width: 15, height: 15}}
                src={IMAGE_ASSETS.pdfIcons.phone}
              />
              <Text><span className="" style={{fontWeight: 500}}>{quoteListDetails.customer_contact_number}</span></Text>
            </View>) : <View></View>}
            <View style={{flexDirection: 'row', marginBottom: 3}}>
              <Image
                style={{width: 15, height: 15}}
                src={IMAGE_ASSETS.pdfIcons.mail}
              />
              <Text><span className="" style={{fontWeight: 500}}>{quoteListDetails.customer_email}</span></Text>
            </View>
            <View style={{flexDirection: 'row', marginBottom: 3}}>
              <Image
                style={{width: 15, height: 15}}
                src={IMAGE_ASSETS.pdfIcons.map}
              />
              <Text><span className="" style={{fontWeight: 500}}>{quoteListDetails.customer_address}</span></Text>
            </View>
          </View>


          <View style={{flex: 1, marginLeft: 56}}>
            <Text style={{fontWeight: 500, fontSize: 13, marginBottom: 5, marginLeft: 2}}>&nbsp;</Text>
            <Text style={{marginBottom: 3}}> <span style={{fontWeight: 'bold'}}>Ref #:</span><span> {quoteListDetails.transaction_number}</span></Text>
            <Text style={{marginBottom: 3}}><span style={{fontWeight: 'bold'}}>Quote Date:</span><span> {moment(quoteListDetails.created_at).format(user.data.date_format_name ? user.data.date_format_name : "DD/MM/YYYY")}</span></Text>
            <Text style={{marginBottom: 3}}><span style={{fontWeight: 'bold'}}>Expiry Date:</span><span> {moment(quoteListDetails.expiration_date).format(user.data.date_format_name ? user.data.date_format_name : "DD/MM/YYYY")}</span></Text>
          </View>
        </View>

        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <View style={styles.tableCol, {width: "10%"}}>
              <Text style={styles.tableCell,{paddingLeft: 5, marginTop: 5, paddingTop: 5, paddingBottom: 5}}>#</Text>
            </View>

            <View style={styles.tableCol, {width: "70%"}}>
             <Text style={styles.tableCell}>Description</Text>
            </View>

            <View style={styles.tableCol, {width: "20%"}}>
             <Text style={styles.tableCellRight}>Unit Cost</Text>
            </View>
          </View>
          {quoteListDetails && quoteListDetails.items.map((lineItem, index) => {
            return  (

              <View style={{flexDirection: "row", backgroundColor: ((index + 1) % 2) == 0 ? "#F2F2F2" : "#FFF" }} key={index}>
                <View style={styles.tableCol, {width: "10%"}}>
                <Text style={styles.tableCell,{paddingLeft: 5, marginTop: 6, paddingTop: 5, paddingBottom: 5}}>{index + 1}</Text>
                </View>

                <View style={styles.tableCol, {width: "70%"}}>
                  <Text style={styles.tableCell}>{lineItem.item_description}</Text>
                </View>

                <View style={styles.tableCol, {width: "20%"}}>
                  <Text style={styles.tableCellRight}>{currencyFormatter.format(Number(lineItem.item_unit_cost))}</Text>
                </View>
            </View>

            )
          })}
      </View>



      <View style={styles.tableFooter}>
          {user.data.is_vat && <View>
            <View style={styles.tableRow}>
            <View style={styles.tableCol, {width: "70%"}}>
              <Text style={styles.tableCell}>&nbsp;</Text>
            </View>

            <View style={styles.tableCol, {width: "10%", borderBottom: "1px solid #ddd"}}>
             <Text style={styles.tableCell}>Subtotal</Text>
            </View>

            <View style={styles.tableCol, {width: "20%", borderBottom: "1px solid #ddd"}}>
             <Text style={styles.tableCellRight}>{currencyFormatter.format(Number(quoteListDetails.subtotal))}</Text>
            </View>
          </View>
          <View style={styles.tableRow}>
            <View style={styles.tableCol, {width: "70%"}}>
              <Text style={styles.tableCell}>&nbsp;</Text>
            </View>

            <View style={styles.tableCol, {width: "10%", borderBottom: "1px solid #ddd"}}>
             <Text style={styles.tableCell}>{user.data.tax_label_name ? user.data.tax_label_name: "VAT"}({user.data.is_vat ? parseInt(user.data.vat_rate) : 0}%)</Text>
            </View>

            <View style={styles.tableCol, {width: "20%", borderBottom: "1px solid #ddd"}}>
             <Text style={styles.tableCellRight}>{currencyFormatter.format(Number(quoteListDetails.vat_amount))}</Text>
            </View>
          </View>
          </View>}
          <View style={styles.tableRow}>
            <View style={styles.tableCol, {width: "70%"}}>
              <Text style={styles.tableCell}>&nbsp;</Text>
            </View>

            <View style={styles.tableCol, {width: "10%", backgroundColor: "#167AF6", color: "white", border: "0px", marginRight: -1}}>
             <Text style={styles.tableCell, {fontWeight: 700, paddingLeft: 5, paddingTop: 10}}>Total</Text>
            </View>

            <View style={styles.tableCol, {width: "20%", backgroundColor: "#167AF6", color: "white", border: "0px"}}>
             <Text style={styles.tableCellRight}>{currencyFormatter.format(Number(quoteListDetails.total))}</Text>
            </View>
          </View>
      </View>


        {quoteListDetails.terms_is_deposit_required && <View style={{ marginTop: 20}}>

        <Text style={{fontWeight: 700}}>TERMS AND CONDITIONS</Text>
        {getTerms(quoteListDetails, currencyFormatter)}
        {/* <Text>A deposit of <b>{deposit_amount}</b> {terms_method_percentage ? } is payable on acceptance of this quote.</Text> */}
        {/* {quoteListDetails.terms_is_deposit_fixed === true ? (<Text>A deposit of <b>{deposit_amount}</b> is payable on acceptance of this quote.</Text>) : (
          <View>
          <Text>A deposit of <b>{deposit_amount}</b></Text><Text>({quoteListDetails.terms_deposit_percentage}%) is payable on acceptance of this quote.</Text>
          </View>
        )} */}

        </View>}

        {quoteListDetails.terms_other_conditions !== "" ? <View style={{ marginTop: 20}}>

        <Text style={{fontWeight: 500}}>Other Conditions</Text>
        <Text style={{fontWeight: 500}}>{quoteListDetails.terms_other_conditions}</Text>


        </View> : <Text>&nbsp;</Text>}


    </View>
    </Page>
  </Document>
  )
};

export default SavePdfQuote;
