import React, {useContext ,useState, useEffect} from "react";
import { UserContext } from '../../../providers/UserProvider';
import { QuoteContext } from '../../../providers/QuoteProvider';
import PageTitle from "../../../components/common/PageTitle";
import { Link, useHistory} from "react-router-dom";
import {
  Card,
  CardHeader,
  Row,
  Col,
  Container,
  Button
} from "shards-react";
import { Spinner } from "react-bootstrap";

import ViewCustomerComponent from './components/ViewCustomerComponent';

const ViewCustomer = () => {
  const {user} = useContext(UserContext)
  const {quoteListDetails, getQuotesDetails, getQuotesOfCustomer, loading, customerQuotesList} = useContext(QuoteContext)
  const history = useHistory();
  const [customerInfo, setCustomerInfo] = useState(null)


  useEffect(() => {
    if(!quoteListDetails){
      getQuotesDetails(user.data.auth_token ,JSON.parse(localStorage.getItem('showQuoteDetails')))
    }
  }, [])

  useEffect(() => {
    if(quoteListDetails){
      getQuotesOfCustomer(user.data.auth_token, quoteListDetails.customer_name)
      setCustomerInfo({
        customer_name: quoteListDetails.customer_name,
        customer_email: quoteListDetails.customer_email,
        customer_address: quoteListDetails.customer_address,
        customer_contact_number: quoteListDetails.customer_contact_number,
      })
    }
  }, [quoteListDetails])

  return (
    <div>
    {customerInfo && <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title="QuoteShark" title="QuoteShark" viewTitle="Customer Info" subtitle="View"  md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>

    <Row >
    <Col lg={7}>
        <ViewCustomerComponent customerQuotesList={customerQuotesList} customerInfo={customerInfo} loading={loading} user={user} getQuotesDetails={getQuotesDetails}  />
       </Col>
    </Row>
  </Container>}
  </div>
  );
};

export default ViewCustomer;
