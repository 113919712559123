import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../../providers/UserProvider";
import { QuoteContext } from "../../../providers/QuoteProvider";
import PageTitle from "../../../components/common/PageTitle";
import makeStyles from "@mui/styles/makeStyles";
import {
  Button as CustomButton,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CreateIcon from "@mui/icons-material/Create";
import Icon from "@mui/material/Icon";
import { Spinner } from "react-bootstrap";
import LinearProgress from "@mui/material/LinearProgress";
import ListTableComponent from "./components/ListTableComponent";
import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Container,
  FormInput,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "shards-react";
import NotyfContext from "../../../providers/NotificationContext";
import IMAGE_ASSETS from "../../../assets/image-list";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    color: "#7F7F7F",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
  },
}));

const ListCustomers = () => {
  const { user, changePassword } = useContext(UserContext);
  const {
    getQuotes,
    quoteList,
    setCustomers,
    getCustomers,
    customers,
    loading,
    getQuotesDetails,
    getItems,
    items,
    deleteItem,
  } = useContext(QuoteContext);

  const [searchString, setSearchString] = useState(null);
  const [allCustomers, setAllCustomers] = useState([]);
  const [noMatchQuery, setNoMatchQuery] = useState(false);
  const dateToday = new Date();
  const history = useHistory();

  const getIcon = (status) => {
    if (status == "Sent") {
      return (
        <img
          className="ml-2"
          //  style={{width: "100%"}}
          src={IMAGE_ASSETS.status.sent}
          alt="sent"
        />
      );
    } else if (status == "Read") {
      return (
        <img
          className="ml-2"
          //  style={{width: "100%"}}
          src={IMAGE_ASSETS.status.read}
          alt="read"
        />
      );
    } else if (status == "Accepted") {
      return (
        <img
          className="ml-2"
          //  style={{width: "100%"}}
          src={IMAGE_ASSETS.status.accepted}
          alt="Accepted"
        />
      );
    } else if (status == "Draft") {
      return (
        <img
          className="ml-2"
          //  style={{width: "100%"}}
          src={IMAGE_ASSETS.status.draft}
          alt="draft"
        />
      );
    }
    return (
      <img
        className="ml-2"
        //  style={{width: "100%"}}
        src={IMAGE_ASSETS.status.rejected}
        alt="rejected"
      />
    );
  };

  const getColorStatus = (status) => {
    if (status === "Sent") {
      return "#007DC4";
    } else if (status === "Draft") {
      return "#007DC4";
    } else if (status === "Read") {
      return "#ff9800";
    } else if (status === "Accepted") {
      return "#5BC772";
    } else if (status === "Rejected") {
      return "#ED4134";
    }
  };

  const searchValue = (value) => {
    setNoMatchQuery(false);
    value = value.toLowerCase();
    value = value.replace(/[^\w\s]/gi, "");
    console.log("search value: " + value);
    var newData = [];
    var newData2 = [];
    if (value != "") {
      if (allCustomers.length == 0) {
        setAllCustomers(customers);
        newData = customers.filter(function (data) {
          return (
            data.customer_name.toLowerCase().search(value) !== -1 ||
            data.email.search(value) !== -1 ||
            data.customer_address.toLowerCase().search(value) !== -1
          );
        });
        newData2 = customers.filter(function (data) {
          if (data.contact_number) {
            data.contact_number.replace(/[^\w\s]/gi, "");
            return data.contact_number.search(value) !== -1;
          }
        });
        if (newData2.length > 0) {
          newData.concat(newData2);
        }
        if (newData.length === 0) {
          setNoMatchQuery(true);
        }
      } else {
        newData = allCustomers.filter(function (data) {
          return (
            data.customer_name.toLowerCase().search(value) !== -1 ||
            data.email.search(value) !== -1 ||
            data.customer_address.toLowerCase().search(value) !== -1
          );
        });
        newData2 = allCustomers.filter(function (data) {
          if (data.contact_number) {
            data.contact_number.replace(/[^\w\s]/gi, "");
            return data.contact_number.search(value) !== -1;
          }
        });
        if (newData2.length > 0) {
          newData.concat(newData2);
        }
        if (newData.length === 0) {
          setNoMatchQuery(true);
        }
      }
    } else {
      setCustomers(allCustomers);
    }
    console.log(newData);
    if (newData.length > 0) {
      setCustomers(newData);
    }
  };

  useEffect(() => {
    if (user.data && user.data.auth_token) {
      getCustomers(user.data.auth_token);
    }
  }, []);

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="QuoteShark"
          subtitle="Customer List"
          md="12"
          className="ml-sm-auto mr-sm-auto"
        />
      </Row>

      <Row>
        <Col lg={3} md={5} sm={5} xs={5}>
          <div className="form-group">
            <Button
              theme="accent"
              className="p-3"
              style={{ fontSize: 10, width: "100%" }}
              tag={(props) => <Link {...props} />}
              to="/customers/create"
              type="button"
            >
              {" "}
              <i className="fa fa-plus" aria-hidden="true"></i> New Customer
            </Button>
          </div>
        </Col>

        <Col lg={4} md={7} sm={7} xs={7}>
          <Row>
            <input
              style={{ height: 45, width: "70%" }}
              placeholder="Search Customer"
              className="form-control"
              onChange={(e) => {
                setSearchString(e.target.value);
              }}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  searchValue(searchString);
                }
              }}
            />
            <IconButton
              size="medium"
              aria-label="SearcIcon"
              style={{
                fontSize: 19,
                height: 40,
                width: "12%",
                marginTop: 2,
                marginLeft: 2,
              }}
              onClick={() => {
                searchValue(searchString);
              }}
            >
              <SearchIcon color="primary" style={{ fontSize: 36 }} />
            </IconButton>
            {noMatchQuery && (
              <p
                className="text-danger text-center"
                style={{ width: "70%", fontSize: 12, margin: 0 }}
              >
                No customer match your query
              </p>
            )}
          </Row>
        </Col>
      </Row>

      <Row>
        <Col>
          <ListTableComponent
            user={user}
            customers={customers}
            loading={loading}
            getQuotesDetails={getQuotesDetails}
          />
        </Col>
        {/* <Col lg="3">
        <RightBar />
      </Col> */}
      </Row>
    </Container>
  );
};

ListCustomers.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
};

ListCustomers.defaultProps = {
  title: "Account Details",
};

export default ListCustomers;
