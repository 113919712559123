import React, {useContext ,useState, useEffect} from "react";
import { UserContext } from '../../../providers/UserProvider';
import { QuoteContext } from '../../../providers/QuoteProvider';
import PageTitle from "../../../components/common/PageTitle";
import makeStyles from '@mui/styles/makeStyles';
import { Input, AccordionDetails, AccordionSummary, Accordion, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from "prop-types";
import { Link, useHistory} from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Container,
  FormInput,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button
} from "shards-react";
import NotyfContext from '../../../providers/NotificationContext';
import { ConnectedFocusError } from "focus-formik-error";
import { object, string, boolean, array, number } from 'yup'
import { Formik, Field, Form } from 'formik';

const CreateItems = () => {
  const {user} = useContext(UserContext)
  const {createItem} = useContext(QuoteContext)
  const history = useHistory();

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const ValidationSchema = object().shape({
    description: string().max(255, 'Text is Too Long!').required('Item Description is Required'),
    unit_cost: number().required('Unit Cost is Required'),
  });

  return (
    <Formik
    initialValues={{
      description: "",
      unit_cost: "",
    }}
    validationSchema={ValidationSchema}
    onSubmit={ async (values) => {
      console.log("submit")
      var result = await createItem(user.data.auth_token ,values);
      console.log("result", result)
      if(result){
        history.push("/items/list");
      }
    }}
  >
  {({handleSubmit, handleChange, values, touched, errors, setFieldValue}) => (
    <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title="QuoteShark" title="QuoteShark" viewTitle="Items List" subtitle="Create"  md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>

    <Form>
     <ConnectedFocusError />
    <Row >
    <Col>
      <Card small className="" >
          <CardHeader className="border-bottom">
            <h6 className="m-0">Create Item</h6>
          </CardHeader>
          <Row>
            <Col lg={6} md={12}>
              <div  className="mb-4 p-4">
              <div className="form-group">

                    <Field
                      autoFocus={true}
                      variant="outlined"
                      type="text"
                      as={TextField}
                      id="description"
                      name="description"
                      label={<span>Item Description<span className="text-danger"> *</span></span>}
                      className={`form-control input ${errors.description ? "input-error" : ""}`}
                      onChange={(e) => {setFieldValue("description", e.target.value)}}
                    />

                <small style={{ color: 'red' }}>
                  {touched.description && errors.description}
                </small>
              </div>
              <div className="form-group">
                    <Field type=""
                      variant="outlined"
                      type="text"
                      as={TextField}
                      id="unit_cost"
                      name="unit_cost"
                      label={<span>Unit Cost<span className="text-danger"> *</span></span>}
                      className={`form-control input ${errors.unit_cost ? "input-error" : ""}`}
                      onChange={(e) => {setFieldValue("unit_cost", e.target.value)}}
                    />
                <small style={{ color: 'red' }}>
                  {touched.unit_cost && errors.unit_cost}
                </small>
              </div>
              <div style={{marginBottom: -23}}>

            <p className="text-muted m-0" style={{fontSize: 12, paddingBottom: 3}}>Please complete all required fields (<span className="text-danger">*</span>)</p>

                  <Button theme="secondary" type="button" tag={Link} to="/items/list">Cancel</Button>
                  <Button theme="success" className="ml-2" type="submit">Save</Button>
              </div>
              </div>
            </Col>
          </Row>
            </Card>
          </Col>
    </Row>
    </Form>
  </Container>)}
  </Formik>
  );
};

export default CreateItems;
