import React, { useState, useLayoutEffect } from "react";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Button,
  FormCheckbox,
  FormInput,
} from "shards-react";
import logo from "../../images/logo.png";
import IMAGE_ASSETS from "../../assets/image-list";
import Footer from "./Footer";
import { Link, useHistory } from "react-router-dom";
import { Jumbotron } from "react-bootstrap";

const Product = () => {
  const history = useHistory();

  return (
    <div className="bg-white">
      <section id="section1" style={{ backgroundColor: "#FFF" }}>
        <div
          className="pt-5 pb-5"
          style={{
            backgroundImage: `url("${IMAGE_ASSETS.src.productBanner}")`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            width: "100%",
          }}
        >
          <div
            className="container"
            style={{ marginTop: "5%", marginBottom: "8%" }}
          >
            <div className="row">
              <div className="col-12 col-md-6 col-lg-6 ">
                <div>
                  <h2
                    style={{
                      fontWeight: 700,
                      color: "#FFF",
                      lineHeight: "57px",
                      maxWidth: 500,
                    }}
                  >
                    Quotes Software That Saves You Time
                  </h2>
                  <h4
                    style={{
                      color: "#E5E5E5",
                      fontWeight: 300,
                      fontStyle: "normal",
                    }}
                  >
                    <span style={{ maxWidth: 500 }}>
                      QuoteShark makes small Business quoting so simple, you’ll
                      be amazed at the time you have to focus on doing what you
                      love and how much faster you get paid.
                    </span>
                  </h4>

                  <center>
                    <button
                      className="call-to-action-main"
                      onClick={() => {
                        history.push("/sign-up");
                      }}
                    >
                      Sign Up Now
                    </button>
                    <p style={{ color: "#EAD0D0", margin: 0 }}>
                      No credit card required. Cancel anytime.
                    </p>
                  </center>
                </div>
              </div>
              <div
                className="col-12 col-md-6 col-lg-6  justify-content-center align-items-center"
                style={{ marginBottom: 30 }}
              >
                <img
                  className="img-fluid"
                  src={IMAGE_ASSETS.src.mobileHand1}
                  style={{ width: "100%" }}
                ></img>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="section2"
        className="pt-4 pb-3"
        style={{ backgroundColor: "#FFF" }}
      >
        <br />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div>
                <h2
                  style={{
                    fontWeight: 700,
                    color: "#007DC4",
                    lineHeight: "57px",
                    textAlign: "center",
                  }}
                >
                  Create Professional Looking Quotes in Seconds
                </h2>
                <h4
                  style={{
                    color: "#676363",
                    fontWeight: 300,
                    fontStyle: "normal",
                    textAlign: "center",
                  }}
                >
                  <span>
                    You’re the real deal, be sure you look it. QuoteShark makes
                    creating professional looking quotes for your business
                    ridiculously easy. Your customer will be wowed.
                  </span>
                </h4>
                <center>
                  <button
                    className="call-to-action-main"
                    onClick={() => {
                      history.push("/sign-up");
                    }}
                  >
                    Try it Free
                  </button>
                </center>

                <img
                  className=""
                  src={IMAGE_ASSETS.src.professionalQuoting1}
                  style={{ width: "100%" }}
                ></img>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="section3"
        className="pt-4 pb-3"
        style={{ backgroundColor: "#FFF" }}
      >
        <br />
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 col-md-6 col-sm-12 justify-content-center align-items-center"
              style={{ marginBottom: 30 }}
            >
              <img
                className=""
                src={IMAGE_ASSETS.src.quoteDeposit1}
                style={{ width: "100%", paddingRight: "10%" }}
              ></img>
            </div>
            <div className="col-12 col-md-6 col-lg-6 col-sm-12 pt-lg-5">
              <div style={{ marginBottom: "-10%" }}>
                <h2
                  style={{
                    fontWeight: 700,
                    color: "#007DC4",
                    lineHeight: "57px",
                    maxWidth: 500,
                  }}
                >
                  Get Paid Upfront with Deposits
                </h2>
                <h6
                  style={{
                    color: "#000",
                    fontWeight: 300,
                    fontStyle: "normal",
                    maxWidth: 500,
                  }}
                >
                  <span>
                    No more paying expenses out of pocket or waiting until the
                    end of a project to get paid. Request a deposit on your
                    quote and you’ll sleep peacefully knowing you’ll get the
                    money you need at the start.
                  </span>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="section2"
        className="pt-4 pb-3"
        style={{ backgroundColor: "#FFF" }}
      >
        <br />
        <div className="container">
          <div className="row">
            <div
              className="col-12 col-md-6 col-lg-6  order-lg-2 order-md-2  justify-content-center align-items-center"
              style={{ marginBottom: 30 }}
            >
              <img
                className=""
                src={IMAGE_ASSETS.src.invoicingProcess1}
                style={{ width: "100%", marginTop: "-17%" }}
              ></img>
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <div>
                <h2
                  style={{
                    fontWeight: 700,
                    color: "#007DC4",
                    lineHeight: "57px",
                    maxWidth: 500,
                  }}
                >
                  The Quoting Process
                </h2>
                <h6
                  style={{
                    color: "#000",
                    fontWeight: 300,
                    fontStyle: "normal",
                    maxWidth: 500,
                  }}
                >
                  <span>
                    The quoting process should be secure, professional and get
                    you paid for your work as quickly as possible. Send your
                    customer a quote requesting payment. <br />
                    <br />
                    When your customer receives your quote, they can pay you
                    directly. Otherwise, you can input payment instructions in
                    the additional information section of each quote template.
                  </span>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Product;
