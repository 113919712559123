import React, {useContext ,useState, useEffect} from "react";
import { UserContext } from '../../providers/UserProvider';
import TextFieldPercentage from '../common/TextFieldPercentage';
import currencies from '../common/CurrenciesList';
import PropTypes from "prop-types";
import { Link, useHistory } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import MaskedInput from 'react-text-mask';
import MenuItem from '@mui/material/MenuItem';
import NumberFormat from 'react-number-format';
import { object, string, boolean, array, number } from 'yup'
import { ConnectedFocusError } from "focus-formik-error";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  FormCheckbox,
  Button,
  Container
} from "shards-react";
import PageTitle from "../common/PageTitle";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import {Form as ReactForm} from 'react-bootstrap';

const NotificationSetting = ({ title}) => {
  const {user, updateProfile, setCompleteProfileInformation} = useContext(UserContext)
  const [isChecked, setIsChecked] = useState(false)
  const history = useHistory();

  const setIsVat = (val) => {
    setIsChecked(val)
    return val;
  }

  useEffect(() => {
    setIsVat(user.data.is_vat)
  }, [])

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const ValidationSchema = object().shape({
    business_name: string().max(255, 'Text is Too Long!').required('Business Name is Required').typeError('Business name is required.'),
    name: string().max(255, 'Text is Too Long!').required('Name is Required').typeError('Name is required.'),
    email: string().max(255, 'Text is Too Long!').required('Email is Required'),
    contact_number: string().min(6, "Phone number is not valid").max(20, "Phone number is not valid").required('Phone is Required'),
    business_address: string().max(255, 'Text is Too Long!').required('Business Address is Required').typeError('Business Address is required.'),
  });

  return (
    <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle title="QuoteShark" subtitle="Notification Settings" md="12" className="ml-sm-auto mr-sm-auto" />
          </Row>
          <Row>
            <Col lg="9">
    <Formik
      id="profile-info"
      initialValues={{
        business_name: user.data.business_name ? user.data.business_name : "",
        name: user.data.name ? user.data.name : "",
        email: user.data.email,
        contact_number: user.data.contact_number ? user.data.contact_number: "",
        website: user.data.website ? user.data.website : "",
        business_address: user.data.business_address ? user.data.business_address : "",
        business_registration_number: user.data.business_registration_number,
        is_vat: isChecked,
        date_format_id: user.data.date_format_id ? user.data.date_format_id : 1,
        tax_label_id: user.data.tax_label_id ? user.data.tax_label_id : 1,
        vat_rate: user.data.vat_rate ? user.data.vat_rate : 0,
        currency_id: user.data.currency_id ? user.data.currency_id : 1,
        quote_expire_in_days: user.data.quote_expire_in_days ? user.data.quote_expire_in_days : 30,
        terms_is_deposit_required: user.data.terms_is_deposit_required  ? "true" : "false",
        terms_is_deposit_fixed: user.data.terms_is_deposit_fixed ? "true" : "false",
        terms_deposit_percentage: user.data.terms_deposit_percentage ? user.data.terms_deposit_percentage : 0,
        terms_deposit_amount: user.data.terms_deposit_amount ? user.data.terms_deposit_amount : 0,
        terms_other_conditions: user.data.terms_other_conditions ? user.data.terms_other_conditions : "",
        email_read: user.data.email_read,
        email_accepted: user.data.email_accepted,
        email_rejected: user.data.email_rejected,
        email_expired: user.data.email_expired,
        email_newsletter: user.data.email_newsletter
      }}
      validationSchema={ValidationSchema}
      onSubmit={async (values) => {
        console.log("values", values)
        setCompleteProfileInformation(true);
        var res = updateProfile(values, user.data.auth_token, isChecked);
        if(res){
          history.push("/dashboard")
        }
      }}
    >
    {({handleSubmit, handleChange, values, touched, errors, setFieldValue}) => (<Card className="mb-4">
      <CardHeader className="border-bottom" style={{paddingLeft: 17}}>
        <h6 className="m-0">{title}</h6>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <Form className={"form"}>
              <ConnectedFocusError />
                <p className="mt-2 mb-3 text-bold" style={{fontWeight: 600}}>Send me email notifications for: </p>

                <Row>
                  <Col lg={6} md={6} sm={6} xs={6} className="form-group">
                  <label>
                  <Field type="checkbox" name="email_read" tag={FormCheckbox} />
                    <span style={{marginLeft: 7}}>When my Quotes are Read</span>
                  </label>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6} sm={6} xs={6} className="form-group">
                   <label>
                  <Field type="checkbox" name="email_accepted" tag={FormCheckbox} />
                  <span style={{marginLeft: 7}}>When my Quotes are Accepted</span>
                  </label>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6} sm={6} xs={6} className="form-group">
                   <label>
                  <Field type="checkbox" name="email_rejected" tag={FormCheckbox} />
                  <span style={{marginLeft: 7}}>When my Quotes are Rejected</span>
                  </label>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6} sm={6} xs={6} className="form-group">
                   <label>
                  <Field type="checkbox" name="email_expired" tag={FormCheckbox} />
                  <span style={{marginLeft: 7}}>When my Quotes are Expired</span>
                  </label>
                  </Col>
                </Row>

                <p className="mt-3 mb-3 text-bold" style={{fontWeight: 600}}>Newsletter notifications</p>

                <Row>
                  <Col lg={12} md={12} sm={12} xs={12} className="form-group">
                   <label>
                  <Field type="checkbox" name="email_newsletter" tag={FormCheckbox} />
                  <span style={{marginLeft: 7}}>Get the latest news, updates and special offers delivered to your inbox.</span>
                  </label>
                  </Col>
                </Row>

                <Button theme="accent" className="" type="submit">Save Changes</Button>
                <span className="text-primary" style={{ cursor: "pointer", marginLeft: 12, fontWeight: 600}} onClick={() => {
                  history.push('/dashboard')
                }}>Cancel</span>
              </Form>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>)}
    </Formik>
  </Col>
</Row>
</Container>
  );
};

NotificationSetting.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

NotificationSetting.defaultProps = {
  title: "Email Notifications"
};

export default NotificationSetting;
