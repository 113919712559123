import React, { useEffect, useContext, useState } from "react";
// import './Login.css'
import { signInWithGoogle, signInWithFacebook } from "../../api/firebase";
import { UserContext } from "../../providers/UserProvider";
import { QuoteContext } from "../../providers/QuoteProvider";
import { Redirect, Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import logo from "../../images/logo.png";
import IMAGE_ASSETS from "../../assets/image-list";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";

import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function Login() {
  const { user, logIn } = useContext(UserContext);
  const { setNotifications, setLatestQuoteList } = useContext(QuoteContext);
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [values, setValues] = useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    setNotifications(null);
    setLatestQuoteList(null);
    // if (user) {
    //   return <Redirect to={'/dashboard'}/>
    // }
  }, [user]);
  if (user) {
    let _pathname = localStorage.getItem("admin-pathname");
    return <Redirect to={_pathname} />;
  }
  const onKeyPress = (ev) => {
    console.log(`Pressed keyCode ${ev.key}`);
    if (ev.key === "Enter") {
      // Do code here
      ev.preventDefault();
      console.log(email);
      console.log(password);
      let data = {
        email: email,
        password: password,
      };
      // logIn(data);
    }
  };

  return (
    <div className="container mt-lg-5" data-testid="mainLayout">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-5 col-md-7 col-sm-12 col-xs-12 p-0">
          <div className="w-100 p-5" style={{ backgroundColor: "#004E74" }}>
            <img className="mb-4 img-fluid " src={logo} alt="QuoteShark Logo" />
            {/* <pre>{user}</pre> */}
            <h3
              className="h3 mb-3 fw-normal text-center text-white"
              style={{ fontWeight: 700 }}
            >
              Sign in
            </h3>
            <p className="text-white text-center" style={{ fontWeight: 300 }}>
              No Credit Card Required
            </p>
            <div className="form-floating">
              <label className="text-white">Email</label>
              <input
                data-testid="inputEmail"
                type="email"
                autoFocus={true}
                value={email}
                onChange={(e) => {
                  setemail(e.target.value);
                }}
                onKeyPress={onKeyPress}
                className="form-control"
                placeholder="hello@example.com"
              />
            </div>
            <div className="form-floating mt-3">
              <label className="text-white">Password</label>
              <input
                data-testid="inputPassword"
                type={values.showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => {
                  setpassword(e.target.value);
                }}
                onKeyPress={onKeyPress}
                className="form-control"
                placeholder="********"
              />
              <InputAdornment position="end">
                <IconButton
                  style={{ marginTop: -34, marginLeft: "88%" }}
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            </div>

            <div className="checkbox mb-3  mt-2">
              <label className="text-white">
                <Link
                  to={`/forgot-password`}
                  className="text-white"
                  data-testid="linkForgotPassword"
                >
                  Forgot password?
                </Link>
                {/* <input type="checkbox" value="remember-me" /> Remember my preference */}
              </label>
              <label className="float-right text-white">
                {/* Forgot password? */}
              </label>
            </div>
            <button
              data-testid="buttonSignIn"
              className="btn btn-block call-to-action-main"
              style={{ padding: 12 }}
              onClick={() => {
                console.log(email);
                console.log(password);
                // setUser("jake")
                let data = {
                  email: email,
                  password: password,
                };
                logIn(data);
              }}
            >
              Sign In
            </button>
            <p className="text-white mt-3">
              Don't have an account?{" "}
              <Link
                data-testid="linkSignUp"
                to={`/sign-up`}
                className="text-white"
              >
                <b>
                  <u>Sign up</u>
                </b>
              </Link>
            </p>
            <div className="flex flex-row">
              <p className="text-center text-white m-0">or continue with</p>
            </div>

            <div className="w-100 justify-content-center align-items-center">
              <center>
                <img
                  className=" btn hover-shadow"
                  style={{ width: 100 }}
                  src={IMAGE_ASSETS.src.facebookIcon}
                  alt="Facebook Logo"
                  onClick={() => {
                    signInWithFacebook();
                  }}
                />
                <img
                  className=" btn hover-shadow"
                  style={{ width: 100 }}
                  src={IMAGE_ASSETS.src.googleIcon}
                  alt="Google Logo"
                  onClick={() => {
                    signInWithGoogle();
                  }}
                />
              </center>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
