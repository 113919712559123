import React, {useEffect, useContext} from "react";
import PropTypes from "prop-types";
import { Container, Col,
  Card,
  CardHeader,
  CardFooter,
  Button,
  ListGroupItem, Row} from "shards-react";
  import { QuoteContext } from '../../providers/QuoteProvider';
  import { UserContext } from '../../providers/UserProvider';
  import { Link, useHistory} from "react-router-dom";
  import EventBusyIcon from '@mui/icons-material/EventBusy';
  import Backdrop from '@mui/material/Backdrop';
  import Zoom from '@mui/material/Zoom';


import Modal from 'react-bootstrap/Modal'
import { orange } from '@mui/material/colors';

import NotificationCard from '../../components/dashboard/NotificationCard';
import LatestQuotes from '../../components/dashboard/LatestQuotes';
import PageTitle from "../../components/common/PageTitle";
import CompleteProfileWizard from "./complete-profile/CompleteProfileWizard";
// import SmallStats from "../../components/common/SmallStats";
// import UsersOverview from "../../components/blog/UsersOverview";
// import UsersByDevice from "../../components/blog/UsersByDevice";
// import NewDraft from "../../components/blog/NewDraft";
// import Discussions from "../../components/blog/Discussions";
// import TopReferrals from "../../components/common/TopReferrals";

const Dashboard = ({ smallStats }) => {
  const {user, completeProfileInformation, setCompleteProfileInformation, changePassword, getUserProfile} = useContext(UserContext)
  const {latestQuoteList, getLatestQuotes, getQuotesDetails, getNotifications, notifications, localeCurrencyFormat} = useContext(QuoteContext)
  const [open, setOpen] = React.useState(false);
  const [openModalChoice, setOpenModalChoice] = React.useState(false);
  const history = useHistory();


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {

    if(user && user.message == "Registration successful."){
      setOpenModalChoice(true);
      // handleOpen()
    }
    if(user.data && user.data.auth_token){
      getLatestQuotes(user.data.auth_token);
      getNotifications(user.data.auth_token);
      if(user.data.name !== null && user.data.business_name !== null && user.data.contact_number !== null && user.data.business_address !== null){
        setCompleteProfileInformation(true)
      }else{
        setOpenModalChoice(true);
      }
    }
  },[user])

  useEffect(() => {
    getUserProfile(user.data.auth_token);
  }, [])


  return (
    <Container fluid className="main-content-container px-4" data-testid="mainLayout">
      <Row noGutters className="page-header py-4">
        <PageTitle title="QuoteShark" subtitle="Dashboard" className="text-sm-left mb-3" />
      </Row>

      {user && <div>
        {notifications && <NotificationCard user={user} notifications={notifications} completeProfileInformation={completeProfileInformation} getQuotesDetails={getQuotesDetails} />}
        {latestQuoteList && latestQuoteList.length > 0 ? <LatestQuotes latestQuoteList={latestQuoteList} user={user} getQuotesDetails={getQuotesDetails} localeCurrencyFormat={localeCurrencyFormat} /> : null}
      </div>}

      {/* <Modal
        open={openModalChoice}
        onClose={()=> setOpenModalChoice(false)}
        disableBackdropClick={true}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >

      </Modal> */}
       <Modal
       show={openModalChoice}
       backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
       dialogClassName="modal-50w"
       style={{zIndex: 99999}}
    >
       <Card >
          <CardHeader className="border-bottom">
            <p className="text-center" style={{fontWeight: 700, fontSize: 22, marginBottom: 0}}>
                Welcome to QuoteShark.
            </p>
          </CardHeader>
          <div className="container" style={{
              padding: "15px 45px",
           }}>
            <p className="text-center" style={{fontSize: 20}}>
              Please complete your Business Profile <br /> to start sending professional quotes.
            </p>
            <Row style={{justifyContent: "center"}}>
            <div  style={{justifyContent: "center"
            }}>
              <Button theme="success" style={{width: 190, marginLeft: 10,  marginBottom: 10, height: 45, fontSize: 13}} type="button" onClick={() => {
                setOpenModalChoice(false)
                handleOpen()
              }}><i className="fa fa-check" aria-hidden="true"></i> Complete Your Profile</Button>
               {/* <Button theme="danger" style={{width: 150, marginLeft: 10, marginBottom: 10, height: 45, fontSize: 15}} type="button" onClick={() => {
                setOpenModalChoice(false)
              }}><i className="fa fa-arrow-right" aria-hidden="true"></i> Skip</Button> */}
            </div>
            </Row>
          </div>
        </Card>
    </Modal>

    <Modal
       show={open}
       backdrop="static"
       aria-labelledby="center"
       centered
       fullscreen={true}
       size="lg"
       style={{zIndex: 99999}}
    >
      <CompleteProfileWizard handleClose={handleClose} />
    </Modal>

    </Container>
  );
}



Dashboard.propTypes = {
  /**
   * The small stats dataset.
   */
  smallStats: PropTypes.array
};

Dashboard.defaultProps = {
};

export default Dashboard;
