import React from "react";
import { backend_url } from "../config/endpoints";
import axios from 'axios';
import { Notyf } from 'notyf';

const notyf = new Notyf({
  duration: 4000, // Set your global Notyf configuration here
  position: {
    x: 'center',
    y: 'top',
  },
});

export const createCustomer = async (data) => {
    var config = {
      method: 'post',
      url: backend_url + '/customers',
      headers: {
        'Authorization': data.auth_token,
        'Content-Type': 'application/json'
      },
      data: {
          "data": {
              "customer_name": data.name,
              "email": data.email,
              "customer_address": data.address,
          }
      }
    };

   return await axios(config)
    .then(function (res) {
      if(res.data.status){
        notyf.success(res.data.message)
        console.log(res.data)
       return res.data.data
      }else{
        return res.data.message
      }
    })
    .catch(function (error) {
        return error
    });
};

export const updateCustomer = async (data) => {
    var config = {
      method: 'put',
      url: backend_url + `/customers/${data.customer_id}`,
      headers: {
        'Authorization': data.auth_token,
        'Content-Type': 'application/json'
      },
      data: {
          "data": {
              "customer_name": data.name,
              "email": data.email,
              "customer_address": data.address,
          }
      }
    };

   return await axios(config)
    .then(function (res) {
      console.log(res.data)
      if(res.data.status){
        notyf.success(res.data.message)
       return res.data.data
      }else{
        return res.data.message
      }
    })
    .catch(function (error) {
        return error
    });
};


export const createQuotation = async (customer, items, terms, summary) => {

  var data = {}

  var _items = [];

  items.lineItems.forEach((item, index) => {
    if(item.item_id == 0){
      _items[index] = {
        "item_description" : item.description,
        "item_unit_cost" : item.unit_cost,
      }
    }else{
      _items[index] = {
        "item_description" : item.description,
        "item_unit_cost" : item.unit_cost,
        "item_id" : item.item_id,
      }
    }
  });

  let _ref_number = customer.referenceNumber.split("-")
  if(customer.customer_id != 0){
    data = {
      "data": {
        "reference_number": _ref_number[1],
        "terms_save": terms.terms_save,
        "expire_in_days": terms.expire_in_days,
        "terms_is_deposit_required": terms.terms_is_deposit_required,
        "terms_is_deposit_fixed": terms.terms_is_deposit_fixed,
        "terms_deposit_percentage": terms.terms_deposit_percentage,
        "terms_deposit_amount": terms.terms_deposit_amount,
        "terms_other_conditions": terms.terms_other_conditions,
        "customer_id": customer.customer_id,
        "customer_email": customer.email,
        "quotation_details_attributes": _items,
        "customer_contact_number": customer.contact_number,
      }
    }
  }else{
    data = {
      "data": {
        "reference_number": _ref_number[1],
        "terms_save": terms.terms_save,
        "expire_in_days": terms.expire_in_days,
        "terms_is_deposit_required": terms.terms_is_deposit_required,
        "terms_is_deposit_fixed": terms.terms_is_deposit_fixed,
        "terms_deposit_percentage": terms.terms_deposit_percentage,
        "terms_deposit_amount": terms.terms_deposit_amount,
        "terms_other_conditions": terms.terms_other_conditions,
        "customer_name": customer.name,
        "customer_email": customer.email,
        "customer_address": customer.address,
        "quotation_details_attributes": _items,
        "customer_contact_number": customer.contact_number,
      }
    }
  }

  var config = {
    method: 'post',
    url: backend_url + '/quotations',
    headers: {
      'Authorization': customer.auth_token,
      'Content-Type': 'application/json'
    },
    data: data
  };
 return await axios(config)
  .then(function (res) {
    console.log(res.data)
    if(res.data.status){
     return res.data
    }else{
      return res.data
    }
  })
  .catch(function (error) {
      return error
  });
};




export const saveDraft = async () => {

  var user, customer, items, terms = "";
  var data = {}

  var _items = [];

  customer = JSON.parse(localStorage.getItem("draftQuoteCustomer"));
  user = JSON.parse(localStorage.getItem("user"));
  console.log("quoteInfo.customerDetails", customer)

  data = {
    "data": {
      "reference_number": customer.referenceNumber,
      "expiration_date": user.data.expiration_date,
      "expire_in_days": user.data.quote_expire_in_days,
      "terms_is_deposit_required": user.data.terms_is_deposit_required,
      "terms_is_deposit_fixed": user.data.terms_is_deposit_fixed,
      "terms_deposit_percentage": user.data.terms_deposit_percentage,
      "terms_deposit_amount": user.data.terms_deposit_amount,
      "terms_other_conditions": user.data.terms_other_conditions,
      "customer_name": customer.name,
      "customer_email": customer.email,
      "customer_address": customer.address,
      "quotation_details_attributes": [],
      "status_id": 0,
    }
  }


  try {
    items = JSON.parse(localStorage.getItem("draftQuoteLineItems"));
    items.lineItems = items.lineItems.filter(function(data) {
      return data.description !== ""
    });

    items.lineItems.forEach((item, index) => {
      if(item.item_id == 0){
        _items[index] = {
          "item_description" : item.description,
          "item_unit_cost" : item.unit_cost,
        }
      }else{
        _items[index] = {
          "item_description" : item.description,
          "item_unit_cost" : item.unit_cost,
          "item_id" : item.item_id,
        }
      }
    });


    if(customer.customer_id != 0){
      data = {
        "data": {
          ...data.data,
          "quotation_details_attributes": _items,
        }
      }
    }else{
      data = {
        "data": {
          ...data.data,
          "quotation_details_attributes": _items,
          "status_id": 0,
        }
      }
    }


  } catch (error) {

  }
  try {
    terms = JSON.parse(localStorage.getItem("draftQuoteTerms")); if(customer.customer_id != 0){
      data = {
        "data": {
          ...data.data,
          "terms_save": terms.terms_save === "true" ? true : false,
          "expire_in_days": terms.expire_in_days,
          "terms_is_deposit_required": terms.terms_is_deposit_required,
          "terms_is_deposit_fixed": terms.terms_is_deposit_fixed,
          "terms_deposit_percentage": terms.terms_deposit_percentage,
          "terms_deposit_amount": terms.terms_deposit_amount,
          "terms_other_conditions": terms.terms_other_conditions,
          "status_id": 0,
        }
      }
    }else{
      data = {
        "data": {
          ...data.data,
          "terms_save": terms.terms_save === "true" ? true : false,
          "expire_in_days": terms.expire_in_days,
          "terms_is_deposit_required": terms.terms_is_deposit_required,
          "terms_is_deposit_fixed": terms.terms_is_deposit_fixed,
          "terms_deposit_percentage": terms.terms_deposit_percentage,
          "terms_deposit_amount": terms.terms_deposit_amount,
          "terms_other_conditions": terms.terms_other_conditions,
          "status_id": 0,
        }
      }
    }
  } catch (error) {

  }


  console.log("save Draft data", data)

  var config = {
    method: 'post',
    url: backend_url + '/quotations',
    headers: {
      'Authorization': customer.auth_token,
      'Content-Type': 'application/json'
    },
    data: data
  };
 return await axios(config)
  .then(function (res) {
    console.log(res.data)
    if(res.data.status){
      notyf.success("Quotation saved as draft.")
     return res.data
    }else{
      notyf.error(res.data.message)
      return res.data
    }
  })
  .catch(function (error) {
      return error
  });
};
