// Layout Types
import { DefaultLayout, PublicLayout, NoNavLayout } from "./layouts";

// Route Views
import Dashboard from "./views/admin/Dashboard";
import UserProfileLite from "./views/UserProfileLite";
import AddNewPost from "./views/AddNewPost";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
import BlogPosts from "./views/BlogPosts";

//Public Pages
import LandingPage from "./views/public/LandingPage";
import Features from "./views/public/Features";
import Product from "./views/public/Product";
import AboutUs from "./views/public/AboutUs";
import Login from "./views/public/Login";
import DataDeletionPage from "./views/public/DataDeletionPage";
import PrivacyPage from "./views/public/PrivacyPage";
import Signup from "./views/public/Signup";
import TermsConditionPage from "./views/public/TermsConditionPage";
import ForgotPassword from "./views/public/ForgotPassword";
import ResetPassword from "./views/public/ResetPassword";

//admin
import Profile from "./views/admin/setting/Profile";
import Messages from "./views/admin/admin-message/Messages";
import ChangePassword from "./views/admin/setting/ChangePassword";
import CreateQuote from "./views/admin/quote/CreateQuote";
import ListQuotes from "./views/admin/quote/ListQuotes";
import ShowQuote from "./views/admin/quote/ShowQuote";
import EditQuoteInline from "./views/admin/quote/EditQuoteInline";
import DuplicateQuoteInline from "./views/admin/quote/DuplicateQuoteInline";
import PublicViewQuote from "./views/public/PublicViewQuote";
import SubscribePage from "./views/public/SubscribePage";
import UnSubscribePage from "./views/public/UnSubscribePage";
import ListItems from "./views/admin/item/ListItems";
import CreateItems from "./views/admin/item/CreateItems";
import EditItems from "./views/admin/item/EditItems";
import ListCustomers from "./views/admin/customer/ListCustomers";
import ViewCustomer from "./views/admin/customer/ViewCustomer";
import CreateCustomers from "./views/admin/customer/CreateCustomers";
import EditCustomers from "./views/admin/customer/EditCustomers";
import PdfViewQuote from "./views/admin/quote/pdf/PdfViewQuote";
import NotificationSetting from "./components/user-profile-lite/NotificationSetting";

export default [
  // {
  //   path: "/",
  //   exact: true,
  //   layout: PublicLayout,
  //   component: () => <Redirect to="/dashboard" />
  // },
  {
    path: "/",
    exact: true,
    layout: PublicLayout,
    component: LandingPage,
  },
  {
    path: "/features",
    exact: true,
    layout: PublicLayout,
    component: Features,
  },
  {
    path: "/product",
    exact: true,
    layout: PublicLayout,
    component: Product,
  },
  {
    path: "/about-us",
    exact: true,
    layout: PublicLayout,
    component: AboutUs,
  },
  {
    path: "/login",
    layout: PublicLayout,
    component: Login,
  },
  {
    path: "/forgot-password",
    layout: PublicLayout,
    component: ForgotPassword,
  },
  {
    path: "/reset-password",
    layout: PublicLayout,
    component: ResetPassword,
  },
  {
    path: "/sign-up",
    layout: PublicLayout,
    component: Signup,
  },
  {
    path: "/data-deletion",
    layout: PublicLayout,
    component: DataDeletionPage,
  },
  {
    path: "/terms",
    layout: PublicLayout,
    component: TermsConditionPage,
  },
  {
    path: "/privacy",
    layout: PublicLayout,
    component: PrivacyPage,
  },
  {
    path: "/customers/quotation",
    layout: NoNavLayout,
    component: PublicViewQuote,
  },
  {
    path: "/subscribe",
    layout: NoNavLayout,
    component: SubscribePage,
  },
  {
    path: "/unsubscribe",
    layout: NoNavLayout,
    component: UnSubscribePage,
  },
  // {
  //   path: "/customers/quotation/:id/:token",
  //   exact: true,
  //   layout: PublicLayout,
  //   component: PublicViewQuote
  // },
  {
    path: "/dashboard",
    layout: DefaultLayout,
    component: Dashboard,
  },
  {
    path: "/messages",
    layout: DefaultLayout,
    component: Messages,
  },
  {
    path: "/user-profile-lite",
    layout: DefaultLayout,
    component: UserProfileLite,
  },
  {
    path: "/settings/profile",
    layout: DefaultLayout,
    component: Profile,
  },
  {
    path: "/settings/notifications",
    layout: DefaultLayout,
    component: NotificationSetting,
  },
  {
    path: "/change-password",
    layout: DefaultLayout,
    component: ChangePassword,
  },
  {
    path: "/quotes/create",
    layout: DefaultLayout,
    component: CreateQuote,
  },
  {
    path: "/quotes/edit",
    layout: DefaultLayout,
    component: EditQuoteInline,
  },
  {
    path: "/quotes/duplicate",
    layout: DefaultLayout,
    component: DuplicateQuoteInline,
  },
  {
    path: "/quotes/show",
    layout: DefaultLayout,
    component: ShowQuote,
  },
  {
    path: "/quotes/pdf",
    layout: DefaultLayout,
    component: PdfViewQuote,
  },
  {
    path: "/quotes/list",
    layout: DefaultLayout,
    component: ListQuotes,
  },
  {
    path: "/items/list",
    layout: DefaultLayout,
    component: ListItems,
  },
  {
    path: "/items/create",
    layout: DefaultLayout,
    component: CreateItems,
  },
  {
    path: "/items/edit",
    layout: DefaultLayout,
    component: EditItems,
  },
  {
    path: "/customers/list",
    layout: DefaultLayout,
    component: ListCustomers,
  },
  {
    path: "/customer/view",
    layout: DefaultLayout,
    component: ViewCustomer,
  },
  {
    path: "/customers/create",
    layout: DefaultLayout,
    component: CreateCustomers,
  },
  {
    path: "/customers/edit",
    layout: DefaultLayout,
    component: EditCustomers,
  },
  {
    path: "/add-new-post",
    layout: DefaultLayout,
    component: AddNewPost,
  },
  {
    path: "/errors",
    layout: DefaultLayout,
    component: Errors,
  },
  {
    path: "/components-overview",
    layout: DefaultLayout,
    component: ComponentsOverview,
  },
  {
    path: "/tables",
    layout: DefaultLayout,
    component: Tables,
  },
  {
    path: "/blog-posts",
    layout: DefaultLayout,
    component: BlogPosts,
  },
];
