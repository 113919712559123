import React, { useState, useLayoutEffect } from "react";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Button,
  FormCheckbox,
  FormInput,
} from "shards-react";
import logo from "../../images/logo.png";
import IMAGE_ASSETS from "../../assets/image-list";
import Footer from "./Footer";
import { Link, useHistory } from "react-router-dom";
import { Jumbotron } from "react-bootstrap";

const AboutUs = () => {
  const history = useHistory();

  return (
    <div className="bg-white">
      <section id="section1" style={{ backgroundColor: "#FFF" }}>
        <div
          className="pt-5 pb-5"
          style={{
            backgroundImage: `url("${IMAGE_ASSETS.src.featuresBg}")`,
            backgroundSize: "cover",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div>
                  <h2
                    style={{
                      fontWeight: 700,
                      color: "#FFF",
                      lineHeight: "57px",
                      textAlign: "center",
                    }}
                  >
                    We Help Businesses Unlock The Power of Quoting
                  </h2>
                  <h4
                    style={{
                      color: "#E5E5E5",
                      fontWeight: 300,
                      fontStyle: "normal",
                      textAlign: "center",
                    }}
                  >
                    <span>
                      Our mission today is to make it effortless for you to
                      manage your finances and stay organized. So now you can
                      focus on more important things—like delighting your
                      customers and getting to bed on time.
                    </span>
                  </h4>

                  <center>
                    <button
                      className="call-to-action-main"
                      onClick={() => {
                        history.push("/sign-up");
                      }}
                    >
                      Sign Up Now
                    </button>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="section2" style={{ backgroundColor: "#FFF" }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div>
                <h2
                  style={{
                    fontWeight: 700,
                    color: "#007DC4",
                    lineHeight: "57px",
                  }}
                >
                  About Us
                </h2>
                <h5
                  style={{
                    color: "#000",
                    fontWeight: 300,
                    fontStyle: "normal",
                  }}
                >
                  <p>
                    We build straightforward tools that empower small business
                    owners to take control of the way they work The number of
                    freelancers and small business owners is skyrocketing. In
                    the past 5 years, more than 4 million freelancers and small
                    companies joined the US economy. Almost 30% of all Americans
                    are now self-employed. These independent professionals are
                    the heart of the global economy.
                  </p>
                </h5>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div>
                <h2
                  style={{
                    fontWeight: 700,
                    color: "#007DC4",
                    lineHeight: "57px",
                  }}
                >
                  &nbsp;
                </h2>
                <h5
                  style={{
                    color: "#000",
                    fontWeight: 300,
                    fontStyle: "normal",
                  }}
                >
                  <p>
                    We build straightforward tools that empower small business
                    owners to take control of the way they work The number of
                    freelancers and small business owners is skyrocketing. In
                    the past 5 years, more than 4 million freelancers and small
                    companies joined the US economy. Almost 30% of all Americans
                    are now self-employed. These independent professionals are
                    the heart of the global economy.
                  </p>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default AboutUs;
