import React, {useContext ,useState, useEffect} from "react";
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from "prop-types";
import { Link, useHistory} from "react-router-dom";
import { Spinner} from "react-bootstrap";
import {
  Card,
  CardHeader,
  Row,
  Col,
  Container,
  Button
} from "shards-react";
import IMAGE_ASSETS from "../../../../assets/image-list";



const ViewCustomerComponent = ({customerQuotesList, customerInfo, loading, user, getQuotesDetails}) => {
  const history = useHistory();

  return (
     <Card small className="" >
          <CardHeader className="border-bottom">
            <h6 className="m-0">Customer Info</h6>
          </CardHeader>
           <div >
             <Col lg={12} className="" style={{paddingTop: 15, paddingBottom: 15}}>
                <span style={{fontWeight: 600}}><i className="fa fa-user" aria-hidden="true"></i> {customerInfo.customer_name}</span><br />
                {customerInfo.customer_contact_number && <span className=""><i className="fa fa-phone" aria-hidden="true"></i> {customerInfo.customer_contact_number}<br /></span>}
                <span><i className="fa fa-envelope" aria-hidden="true"></i> {customerInfo.customer_email}</span><br />
                <span><i className="fa fa-map-pin" aria-hidden="true"></i> {customerInfo.customer_address}</span><br />
              </Col>

              <Col lg={12} className="" style={{paddingTop: 15, paddingBottom: 15}}>
               {loading === true ? <span><Spinner animation="border" variant="primary" /> Loading quotes...</span> :
               <div className="table-responsive">
                  <table className="table table-hover " >
                      <thead>
                          <tr>
                          <th>#</th>
                          <th>Reference #</th>
                          <th>Status</th>
                          <th></th>
                          </tr>
                      </thead>
                      <tbody>
                      {customerQuotesList && customerQuotesList.map((quote, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td><Link to={"/quotes/show/" + quote.transaction_number } onClick={() => {
                                              getQuotesDetails(user.data.auth_token, quote.id);
                                              localStorage.setItem("showQuoteDetails", JSON.stringify(quote.id))
                                            }}>{quote.transaction_number}</Link></td>
                            <td>{quote.status}</td>
                          </tr>
                        )
                      })}
                      </tbody>
                    </table>
                </div>}
              </Col>

            </div>
            </Card>
  );
};

ViewCustomerComponent.propTypes = {
  customers: PropTypes.array
};

ViewCustomerComponent.defaultProps = {
  customers: []
};

export default ViewCustomerComponent;
