import React, {useContext ,useState, useEffect} from "react";
import { UserContext } from '../../../providers/UserProvider';
import { QuoteContext } from '../../../providers/QuoteProvider';
import PageTitle from "../../../components/common/PageTitle";
import makeStyles from '@mui/styles/makeStyles';
import { Input, AccordionDetails, AccordionSummary, Accordion, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from "prop-types";
import { Link, useHistory} from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Create';
import SearchIcon from '@mui/icons-material/Search';
import Icon from '@mui/material/Icon';
import { Spinner } from "react-bootstrap";
import LinearProgress from '@mui/material/LinearProgress';
import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Container,
  FormInput,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button
} from "shards-react";
import NotyfContext from '../../../providers/NotificationContext';
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    color: '#7F7F7F',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
  },
}));


const ListItems = () => {
  const {user, changePassword} = useContext(UserContext)
  const {localeCurrencyFormat, quoteList, getQuotesWithFilter, getCustomers, customers, loading, setItems, getItems, items, deleteItem} = useContext(QuoteContext)
  const [customer, setCustomer] = useState(null)
  const [search, setSearch] = useState(null)
  const dateToday = new Date();
  const history = useHistory();
  const [filter, setFilter] = useState({
    status_id: 0,
    customer: "",
    expiry_from: "",
    expiry_to: "",
  })
  const [filterStatus, setFilterStatus] = useState("all")
  const [showAdvanceSearch, setShowAdvanceSearch] = useState(false)
  const [sendDisabled, setSendDisabled] = useState(false)
  const [searchString, setSearchString] = useState(null)
  const [allItems, setAllItems] = useState([])

  const notyf = useContext(NotyfContext);
  const classes = useStyles();

  const searchValue = (value) => {
    console.log("search value: " + value)
    if(value != ""){
      value = value.toLowerCase()
      var newData = [];
      if(allItems.length == 0){
        setAllItems(items);
        newData = items.filter(function(data) {
          return data.description.toLowerCase().search(value) !== -1 || data.unit_cost.search(value) !== -1
        });
      }else{
        newData = allItems.filter(function(data) {
          return data.description.toLowerCase().search(value) !== -1 || data.unit_cost.search(value) !== -1
        });
      }
      console.log(newData)
      if(newData.length > 0){
        setItems(newData)
      }
    }else{
      setItems(allItems)
    }
  }

  const currencyFormatter = new Intl.NumberFormat(localeCurrencyFormat[user.data.currency_name ? user.data.currency_name : "en-US"], {
    style: 'currency',
    currency: user.data.currency_name ? user.data.currency_name : "USD",
  });

  useEffect(() => {
    if(user.data && user.data.auth_token){
      getItems(user.data.auth_token);
    }
  },[])

  return (
    <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title="QuoteShark" subtitle="Item List" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>

    <Row>
      <Col lg={3} md={5} sm={5} xs={5}>
        <div className="form-group">
        <Button theme="accent" block className="p-3"  style={{ fontSize: 10}}  tag={(props)=> <Link  {...props} />} to="/items/create" type="button" > <i className="fa fa-plus" aria-hidden="true"></i> New Item</Button>
        </div>
      </Col>
      <Col lg={4} md={7} sm={7} xs={7}>
          <Row>
              <input  style={{height: 45, width: "70%"}} placeholder="Search Item"  className="form-control"  onChange={(e) => {setSearchString(e.target.value)}}
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  searchValue(searchString)
                }
              }}
              />
          <IconButton aria-label="SearcIcon" style={{fontSize: 19, height: 40, width: "12%", marginTop: 2, marginLeft: 2}} onClick={() => {
              searchValue(searchString)
            }}>
              <SearchIcon color="primary" style={{ fontSize: 36 }} />
          </IconButton>

          </Row>
      </Col>
    </Row>


    <Row>
      <Col>
            <Card small className="mb-4"  >
                <Row>
                    <Col className="col-12 d-none d-xl-block d-lg-block d-md-none">
                    <div className="table-responsive">
                        <table className="table table-hover ">
                            <thead>
                                <tr>
                                <th>#</th>
                                <th colSpan="3">Description</th>
                                <th>Unit Cost</th>
                                <th></th>
                                </tr>
                            </thead>
                            <tbody>
                              {loading == true ? (<tr ><td colSpan={6} align="center"> <LinearProgress /></td></tr>) : null}
                                {items && items.map((data, index) => {
                                    return (
                                        <tr key={data.transaction_number}>
                                        <td>{index + 1}</td>
                                            <td colSpan="3">{data.description}</td>
                                            <td>{currencyFormatter.format(Number(data.unit_cost || 0))}</td>
                                            <td>
                                                <IconButton aria-label="edit" style={{marginTop: -10}} onClick={() => {
                                                  localStorage.setItem("editItem", JSON.stringify(data))
                                                  history.push(`/items/edit/${data.id}`)
                                                }}>
                                                  <CreateIcon color="primary" style={{ fontSize: 17 }} />
                                                </IconButton>
                                                <IconButton aria-label="delete" style={{marginTop: -10}} onClick={() => {
                                                  deleteItem(user.data.auth_token, data.id)
                                                    // setTableItems(newData);
                                                    // setFieldValue('lineItems', newData);
                                                }}>
                                                  <DeleteIcon color="error" style={{ fontSize: 17 }} />
                                                </IconButton>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                      </div>
                    </Col>
                    <Col className="col-12 d-xs-block d-sm-block d-md-block d-lg-none d-xl-none" style={{marginTop: 22}}>
                    <ListGroup flush>
                     {loading == true ? ( <LinearProgress />) : null}
                     {items && items.length <= 0 ? <p style={{fontWeight: 600, textAlign: "center", paddingTop: 10}}>No Item</p> : null}

                     {items && items.map((data, index) => {
                          return (
                            <div style={{paddingLeft: 10, paddingRight: 10}}>
                            <hr style={{color: "#7C7D7E"}} />
                              <Row key={data.transaction_number}>
                              <Col className="col-7">
                                      <span style={{fontWeight: 600}}>{data.description}</span><br/>
                                      <span style={{color: "#007000", }}>{currencyFormatter.format(Number(data.unit_cost || 0))}</span><br/>
                                  </Col>

                                  <Col className="col-5" style={{paddingTop: 10}}>
                                  <IconButton aria-label="edit" style={{marginTop: -10}} onClick={() => {
                                          localStorage.setItem("editItem", JSON.stringify(data))
                                          history.push(`/items/edit/${data.id}`)
                                        }}>
                                          <CreateIcon color="primary" style={{ fontSize: 17 }} />
                                        </IconButton>
                                        <IconButton aria-label="delete" style={{marginTop: -10}} onClick={() => {
                                          deleteItem(user.data.auth_token, data.id)
                                            // setTableItems(newData);
                                            // setFieldValue('lineItems', newData);
                                        }}>
                                          <DeleteIcon color="error" style={{ fontSize: 17 }} />
                                        </IconButton>
                                  </Col>
                              </Row>
                            </div>
                          )
                      })}
                    </ListGroup>
                    </Col>
                </Row>
            </Card>
      </Col>
      {/* <Col lg="3">
        <RightBar />
      </Col> */}
    </Row>
  </Container>
  );
};

ListItems.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

ListItems.defaultProps = {
  title: "Account Details"
};

export default ListItems;
