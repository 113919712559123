export default function () {
  return [
    {
      title: "Dashboard",
      to: "/dashboard",
      htmlBefore: '<i class="material-icons">home</i>',
      htmlAfter: "",
      dropdown: false,
    },
    {
      title: "Messages",
      htmlBefore: '<i class="material-icons">message</i>',
      to: "/messages",
    },
    {
      title: "Quotes",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "/quotes/list",
      dropdown: true,
      subLink: [
        {
          title: "Quote List",
          to: "/quotes/list",
        },
        {
          title: "Add New",
          to: "/quotes/create",
        },
      ],
    },
    {
      title: "Items",
      htmlBefore: '<i class="material-icons">view_list</i>',
      to: "/items/list",
      dropdown: true,
      subLink: [
        {
          title: "Items List",
          to: "/items/list",
        },
        {
          title: "Add New",
          to: "/items/create",
        },
      ],
    },
    {
      title: "Customers",
      htmlBefore: '<i class="fa fa-users" aria-hidden="true"></i>',
      to: "/customers/list",
      dropdown: true,
      subLink: [
        {
          title: "Customer List",
          to: "/customers/list",
        },
        {
          title: "Add New",
          to: "/customers/create",
        },
      ],
    },
    {
      title: "Settings",
      htmlBefore: '<i class="fa fa-cog" aria-hidden="true"></i>',
      to: "/settings/profile",
      dropdown: true,
      subLink: [
        {
          title: "Profile Information",
          to: "/settings/profile",
        },
        {
          title: "Notifications",
          to: "/settings/notification",
        },
      ],
    },
    // {
    //   title: "Messages",
    //   htmlBefore: '<i class="material-icons">chat</i>',
    //   to: "/messages",
    // },
    // {
    //   title: "blog-posts",
    //   htmlBefore: '<i class="material-icons">vertical_split</i>',
    //   to: "/blog-posts",
    // },
    // {
    //   title: "add-new-post",
    //   htmlBefore: '<i class="material-icons">note_add</i>',
    //   to: "/add-new-post",
    // },
    // {
    //   title: "Forms & Components",
    //   htmlBefore: '<i class="material-icons">view_module</i>',
    //   to: "/components-overview",
    // },
    // {
    //   title: "Tables",
    //   htmlBefore: '<i class="material-icons">table_chart</i>',
    //   to: "/tables",
    // },
    // {
    //   title: "User Profile",
    //   htmlBefore: '<i class="material-icons">person</i>',
    //   to: "/user-profile-lite",
    // },
    // {
    //   title: "Errors",
    //   htmlBefore: '<i class="material-icons">error</i>',
    //   to: "/errors",
    // }
  ];
}
