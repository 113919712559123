import React, { useContext, useState, useEffect } from "react";
import { QuoteContext } from "../../providers/QuoteProvider";
import NotyfContext from "../../providers/NotificationContext";
import { Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import PublicSavePdf from "../admin/quote/pdf/PublicSavePdf";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { PDFDownloadLink, pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import IMAGE_ASSETS from "../../assets/image-list";
import LinearProgress from "@mui/material/LinearProgress";
import ViewQuotePublicMessage from "../../components/messaging/ViewQuotePublicMessage";
import { Card, CardHeader, Row, Col, Container, Button } from "shards-react";
import moment from "moment";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const PublicViewQuote = () => {
  const {
    getPublicQuote,
    publicQuoteDetail,
    acceptQuote,
    rejectQuote,
    localeCurrencyFormat,
    loading,
    getConversationListCustomer,
  } = useContext(QuoteContext);
  const [showRejectQuote, setShowRejectQuote] = useState(false);
  const [rejectQuoteReason, setRejectQuoteReason] = useState("");
  const currentDate = moment().startOf("day");
  const query = useQuery();
  const MySwal = withReactContent(Swal);
  const notyf = useContext(NotyfContext);

  const areYouSure = (action) => {
    MySwal.fire({
      title: "Confirmation",
      text: `Do you want to ${action} this quotation?`,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        notyf.success("saving response...");
        if (action === "accept") {
          acceptQuote(query.get("quote_id"), query.get("qtoken"));
        } else {
          rejectQuote(
            query.get("quote_id"),
            query.get("qtoken"),
            rejectQuoteReason
          );
        }
      }
    });
  };

  const getIcon = (status) => {
    if (status == "Sent") {
      return <img className="mr-2" src={IMAGE_ASSETS.status.sent} alt="sent" />;
    } else if (status == "Read") {
      return <img className="mr-2" src={IMAGE_ASSETS.status.read} alt="read" />;
    } else if (status == "Accepted") {
      return (
        <img
          className="mr-2"
          src={IMAGE_ASSETS.status.accepted}
          alt="Accepted"
        />
      );
    } else if (status == "Draft") {
      return (
        <img className="mr-2" src={IMAGE_ASSETS.status.draft} alt="draft" />
      );
    }
    return (
      <img className="mr-2" src={IMAGE_ASSETS.status.rejected} alt="rejected" />
    );
  };

  const getColorStatus = (status) => {
    if (status === "Sent") {
      return "#007DC4";
    } else if (status === "Draft") {
      return "#007DC4";
    } else if (status === "Read") {
      return "#ff9800";
    } else if (status === "Accepted") {
      return "#5BC772";
    } else if (status === "Rejected") {
      return "#ED4134";
    }
  };

  const currencyFormatter = new Intl.NumberFormat(
    localeCurrencyFormat[
      publicQuoteDetail && publicQuoteDetail.user_profile_details.currency_name
        ? publicQuoteDetail.user_profile_details.currency_name
        : "en-US"
    ],
    {
      style: "currency",
      currency:
        publicQuoteDetail &&
        publicQuoteDetail.user_profile_details.currency_name
          ? publicQuoteDetail.user_profile_details.currency_name
          : "USD",
    }
  );

  const getTerms = () => {
    if (publicQuoteDetail.terms_is_deposit_required) {
      if (publicQuoteDetail.terms_is_deposit_fixed == true) {
        return (
          <span>
            A deposit of{" "}
            <b>
              {currencyFormatter.format(
                Number(publicQuoteDetail.terms_deposit_amount)
              )}
            </b>{" "}
            is payable on acceptance of this quote.
          </span>
        );
      } else {
        return (
          <span>
            A deposit of{" "}
            <b>
              {currencyFormatter.format(
                Number(
                  parseInt(publicQuoteDetail.total) *
                    (parseInt(publicQuoteDetail.terms_deposit_percentage) / 100)
                )
              )}
            </b>{" "}
            ({publicQuoteDetail.terms_deposit_percentage}%) is payable on
            acceptance of this quote.
          </span>
        );
      }
    }
  };

  useEffect(() => {
    getPublicQuote(query.get("quote_id"), query.get("qtoken"));
    getConversationListCustomer(query.get("quote_id"), query.get("qtoken"));
  }, []);

  return (
    <div>
      {loading == true ? (
        <LinearProgress />
      ) : (
        <Container fluid className="main-content-container px-4">
          <Row className="justify-content-center align-items-center">
            <Col lg={8}>
              <Card small className="mb-4 mt-5">
                {publicQuoteDetail && (
                  <Row
                    style={{
                      border: "1px solid gray",
                      padding: 5,
                      backgroundColor: "#fff",
                    }}
                  >
                    <Col
                      lg={12}
                      style={{
                        borderBottom: "1px solid gray",
                        padding: 5,
                        backgroundColor: "#fff",
                      }}
                    >
                      <Row>
                        <Col
                          lg={6}
                          md={12}
                          sm={12}
                          xs={6}
                          className="col-6"
                          style={{ paddingTop: 12 }}
                        >
                          <span
                            className=""
                            style={{
                              fontWeight: 600,
                              marginLeft: 10,
                              color: "black",
                            }}
                          >
                            Status
                          </span>
                          <span
                            className=""
                            style={{
                              marginLeft: 12,
                              color: getColorStatus(publicQuoteDetail.status),
                            }}
                          >
                            {getIcon(publicQuoteDetail.status)}
                            {publicQuoteDetail.status}
                          </span>
                        </Col>

                        <Col
                          md={6}
                          sm={12}
                          xs={12}
                          className="col-6"
                          style={{ paddingTop: 7, paddingRight: 21 }}
                        >
                          <div
                            className="float-right"
                            style={{ marginBottom: 5 }}
                          >
                            {/* <Button pill className="ml-2" style={{width: 100, height:32}} type="button"
                            tag={PDFDownloadLink}
                            document={<PublicSavePdf quoteListDetails={publicQuoteDetail} user={publicQuoteDetail.user_profile_details} currencyFormatter={currencyFormatter} />}
                            fileName={publicQuoteDetail.transaction_number}
                          >Print PDF</Button> */}

                            <Tooltip title="Download PDF">
                              <IconButton
                                size="small"
                                pill
                                className="ml-2"
                                style={{}}
                                type="button"
                                onClick={async () => {
                                  const doc = (
                                    <PublicSavePdf
                                      quoteListDetails={publicQuoteDetail}
                                      user={
                                        publicQuoteDetail.user_profile_details
                                      }
                                      currencyFormatter={currencyFormatter}
                                    />
                                  );
                                  const asPdf = pdf([]); // {} is important, throws without an argument
                                  asPdf.updateContainer(doc);
                                  const blob = await asPdf.toBlob();
                                  saveAs(
                                    blob,
                                    publicQuoteDetail.transaction_number
                                  );
                                }}
                              >
                                <img
                                  className="mr-2"
                                  src={IMAGE_ASSETS.iconBtn.pdf}
                                />
                              </IconButton>
                            </Tooltip>

                            {publicQuoteDetail.status_id < 3 ? (
                              <>
                                <Button
                                  pill
                                  theme="success"
                                  className="ml-2"
                                  style={{ width: 100, height: 32 }}
                                  type="button"
                                  onClick={() => {
                                    areYouSure("accept");
                                  }}
                                >
                                  Accept
                                </Button>
                                <Button
                                  pill
                                  theme="danger"
                                  className="ml-2"
                                  style={{ width: 100, height: 32 }}
                                  type="button"
                                  onClick={() => {
                                    areYouSure("reject");
                                  }}
                                >
                                  Reject
                                </Button>
                              </>
                            ) : null}
                          </div>
                        </Col>
                      </Row>
                    </Col>

                    <Col lg={12} className="pt-4">
                      <span className="" style={{ fontWeight: 600 }}>
                        <i className="fa fa-building" aria-hidden="true"></i>{" "}
                        {publicQuoteDetail.user_profile_details.business_name}
                        <br />
                      </span>
                      <span className="">
                        <i className="fa fa-user" aria-hidden="true"></i>{" "}
                        {publicQuoteDetail.user_profile_details.name}
                      </span>
                      <br />
                      <span className="">
                        <i className="fa fa-envelope" aria-hidden="true"></i>{" "}
                        {publicQuoteDetail.user_profile_details.email}
                      </span>
                      <br />
                      <span className="">
                        <i className="fa fa-map-pin" aria-hidden="true"></i>{" "}
                        {
                          publicQuoteDetail.user_profile_details
                            .business_address
                        }
                        <br />
                      </span>
                    </Col>

                    <Col
                      lg={12}
                      className="bg-primary"
                      style={{ marginTop: 20, height: 30 }}
                    >
                      <span>&nbsp;</span>
                      <span
                        style={{
                          fontSize: 40,
                          fontWeight: 700,
                          backgroundColor: "#FFF",
                          color: "#404144",
                          position: "absolute",
                          right: 138,
                          top: -15,
                          paddingLeft: 9,
                          paddingRight: 9,
                        }}
                      >
                        QUOTE
                      </span>
                    </Col>

                    <Col lg={8} className="pt-4">
                      <span style={{ fontWeight: 600, fontSize: 16 }}>
                        Quote To:
                      </span>
                      <br />
                      <span style={{ fontWeight: 600 }}>
                        <i className="fa fa-user" aria-hidden="true"></i>{" "}
                        {publicQuoteDetail.customer_name}
                      </span>
                      <br />
                      <span>
                        <i className="fa fa-envelope" aria-hidden="true"></i>{" "}
                        {publicQuoteDetail.customer_email}
                      </span>
                      <br />
                      <span>
                        <i className="fa fa-map-pin" aria-hidden="true"></i>{" "}
                        {publicQuoteDetail.customer_address}
                      </span>
                      <br />
                    </Col>
                    <Col lg={4} className="pt-lg-4">
                      <br />
                      <span style={{ fontWeight: "bold" }}>Ref #:</span>
                      <span> {publicQuoteDetail.transaction_number}</span>
                      <br />
                      <span style={{ fontWeight: "bold" }}>Quote Date:</span>
                      <span>
                        {" "}
                        {moment(publicQuoteDetail.created_at).format(
                          publicQuoteDetail.user_profile_details
                            .date_format_name
                            ? publicQuoteDetail.user_profile_details
                                .date_format_name
                            : "DD/MM/YYYY"
                        )}
                      </span>
                      <br />
                      <span style={{ fontWeight: "bold" }}>Expiry Date:</span>
                      <span>
                        {" "}
                        {moment(publicQuoteDetail.expiration_date).format(
                          publicQuoteDetail.user_profile_details
                            .date_format_name
                            ? publicQuoteDetail.user_profile_details
                                .date_format_name
                            : "DD/MM/YYYY"
                        )}
                      </span>
                      <br />
                    </Col>

                    <Col lg={12} className="pt-4">
                      <table
                        className="custom-table w-100"
                        style={{
                          border: "1px solid #404144",
                          marginBottom: 20,
                        }}
                      >
                        <thead>
                          <tr
                            style={{
                              backgroundColor: "#404144",
                              color: "white",
                            }}
                          >
                            <th colSpan="1">#</th>
                            <th colSpan="5">Description</th>
                            <th colSpan="1">
                              <p className="text-right  m-0">Unit Cost</p>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {publicQuoteDetail &&
                            publicQuoteDetail.items.map((lineItem, index) => {
                              return (
                                <tr key={"item" + lineItem.id}>
                                  <td colSpan="1">{index + 1}</td>
                                  <td colSpan="5">
                                    {lineItem.item_description}
                                  </td>
                                  <td colSpan="1">
                                    <p className="text-right  m-0">
                                      {currencyFormatter.format(
                                        Number(lineItem.item_unit_cost)
                                      )}
                                    </p>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </Col>
                    <Col lg={8}></Col>
                    <Col lg={4}>
                      {publicQuoteDetail.user_profile_details.is_vat && (
                        <div>
                          <Row>
                            <Col>
                              <span
                                className="text-center text-semibold"
                                style={{}}
                              >
                                Subtotal
                              </span>
                            </Col>
                            <Col>
                              <span
                                className="float-right"
                                style={{ marginRight: 12 }}
                              >
                                {currencyFormatter.format(
                                  Number(publicQuoteDetail.subtotal)
                                )}
                              </span>
                            </Col>
                          </Row>
                          <hr style={{ marginTop: 8, marginBottom: 10 }} />
                          <Row>
                            <Col>
                              <span
                                className="text-center text-semibold"
                                style={{}}
                              >
                                {publicQuoteDetail.user_profile_details
                                  .tax_label_name
                                  ? publicQuoteDetail.user_profile_details
                                      .tax_label_name
                                  : "VAT"}
                                (
                                {publicQuoteDetail.user_profile_details.is_vat
                                  ? parseInt(
                                      publicQuoteDetail.user_profile_details
                                        .vat_rate
                                    )
                                  : 0}
                                %)
                              </span>
                            </Col>
                            <Col>
                              <span
                                className="float-right"
                                style={{ marginRight: 12 }}
                              >
                                {currencyFormatter.format(
                                  Number(publicQuoteDetail.vat_amount)
                                )}
                              </span>
                            </Col>
                          </Row>
                        </div>
                      )}
                      <Row
                        className="bg-primary"
                        style={{
                          paddingTop: 10,
                          paddingBottom: 10,
                          marginLeft: 1,
                          marginRight: 1,
                          marginTop: 10,
                        }}
                      >
                        <Col>
                          <span
                            className="text-center text-semibold"
                            style={{ fontWeight: 600, color: "white" }}
                          >
                            Total
                          </span>
                        </Col>
                        <Col>
                          <span
                            className="float-right"
                            style={{ fontWeight: 600, color: "white" }}
                          >
                            {currencyFormatter.format(
                              Number(publicQuoteDetail.total)
                            )}
                          </span>
                        </Col>
                      </Row>
                    </Col>

                    {publicQuoteDetail.terms_is_deposit_required && (
                      <Col lg={12} className="pt-4">
                        <span className="" style={{ fontWeight: 600 }}>
                          <u>TERMS AND CONDITIONS</u>
                        </span>
                        <br />
                        {getTerms()}
                      </Col>
                    )}

                    <Col className="mb-5">
                      {publicQuoteDetail.terms_other_conditions ? (
                        <Row>
                          <Col lg={12} className="pt-4">
                            <span className="text-semibold ">
                              Other conditions
                            </span>
                            <br />
                            <span className="">
                              {publicQuoteDetail.terms_other_conditions}
                            </span>
                            <br />
                          </Col>
                        </Row>
                      ) : null}
                    </Col>
                  </Row>
                )}
              </Card>
            </Col>
            <Col lg={4}>{publicQuoteDetail && <ViewQuotePublicMessage />}</Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default PublicViewQuote;
