import React, {useState, useEffect, useContext} from 'react'
import { Field, useFormikContext } from 'formik'
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  FormCheckbox,
  FormInput,
  FormFeedback,
  Button
} from "shards-react";
import TextField from '@mui/material/TextField';
import {InputGroup, FormControl,  Form as ReactForm} from 'react-bootstrap';
import { UserContext } from '../../../providers/UserProvider';


function EditInlineProfileDetails(props) {
  const { errors, touched, values, setFieldValue, handleChange } = useFormikContext()
  const {user,updateProfile,  changePassword} = useContext(UserContext)
  const [isChecked, setIsChecked] = useState(false)


  const setIsVat = (val) => {
    setIsChecked(val)
    return val;
  }

  useEffect(() => {
    if(user.data && user.data.auth_token){
      // setFieldValue('email', user.data.email)
    }
  }, [])

  useEffect(() => {
  }, [])

  return (
            <Col lg="6" md="12">
                   <InputGroup hasValidation style={{marginBottom: 5}}>
                      <InputGroup.Text><i className="fa fa-building" aria-hidden="true"></i></InputGroup.Text>
                      <Field
                        autoFocus={true}
                        className="form-control"
                        id="business_name"
                        name="business_name"
                        value={values.business_name}
                        invalid={errors.business_name}
                        onChange={handleChange}
                        placeholder="Business Name"
                        type="text"
                        as={FormInput}
                      />
                       {errors.business_name? <FormFeedback>{errors.business_name}</FormFeedback> : null}
                    </InputGroup>

                   <InputGroup hasValidation style={{marginBottom: 5}}>
                      <InputGroup.Text><i className="fa fa-user" aria-hidden="true"></i></InputGroup.Text>
                      <Field
                        autoFocus={true}
                        className="form-control"
                        id="business_profile_name"
                        name="business_profile_name"
                        value={values.business_profile_name}
                        invalid={errors.business_profile_name}
                        onChange={handleChange}
                        placeholder="Name"
                        type="text"
                        as={FormInput}
                      />
                       {errors.business_profile_name? <FormFeedback>{errors.business_profile_name}</FormFeedback> : null}
                   </InputGroup>

                    <InputGroup hasValidation style={{marginBottom: 5}}>
                      <InputGroup.Text><i className="fa fa-envelope" aria-hidden="true"></i></InputGroup.Text>
                      <Field
                        className="form-control"
                        id="business_email"
                        name="business_email"
                        value={values.business_email}
                        invalid={errors.business_email}
                        onChange={handleChange}
                        placeholder="Business Email"
                        type="text"
                        disabled={true}
                        as={FormInput}
                      />
                       {errors.business_email? <FormFeedback>{errors.business_email}</FormFeedback> : null}
                    </InputGroup>

                    <InputGroup hasValidation style={{marginBottom: 5}}>
                      <InputGroup.Text><i className="fa fa-phone" aria-hidden="true"></i></InputGroup.Text>
                      <Field
                        className="form-control"
                        id="contact_number"
                        name="contact_number"
                        value={values.contact_number}
                        invalid={errors.contact_number}
                        onChange={handleChange}
                        placeholder="Contact Number"
                        type="text"
                        as={FormInput}
                      />
                       {errors.address? <FormFeedback>{errors.address}</FormFeedback> : null}
                    </InputGroup>

                    <InputGroup hasValidation style={{}}>
                      <InputGroup.Text><i className="fa fa-map-pin" aria-hidden="true"></i>&nbsp;</InputGroup.Text>
                      <Field
                        className="form-control"
                        id="business_address"
                        name="business_address"
                        value={values.business_address}
                        invalid={errors.business_address}
                        onChange={handleChange}
                        placeholder="Business Address"
                        type="text"
                        as={FormInput}
                      />
                       {errors.business_address? <FormFeedback>{errors.business_address}</FormFeedback> : null}
                    </InputGroup>

                    <div style={{marginTop: 15}}>
                        <Button theme="success" className="" style={{width: 90, height:32, marginRight: 6}} type="button"  onClick={(e) => {
                          user.business_name = values.business_name;
                          user.email = values.business_email;
                          user.name = values.business_profile_name;
                          user.business_address = values.business_address;
                          user.contact_number = values.contact_number;
                          updateProfile(user, user.data.auth_token, true);
                          props.setShowEditBusinessProfile(false)
                        }} >Update</Button>
                        <span className="text-primary" style={{ cursor: "pointer",fontWeight: 600}} onClick={() => {
                              setFieldValue('business_name', user.data.business_name)
                              setFieldValue('business_profile_name', user.data.name)
                              setFieldValue('business_email', user.data.email)
                              setFieldValue('business_address', user.data.business_address)
                              setFieldValue('contact_number', user.data.contact_number)

                              props.setShowEditBusinessProfile(false)
                        }}>Cancel</span>
                        </div>
     </Col>
  )
}

export default EditInlineProfileDetails;
