import { object, string } from "yup";

import CustomerDetailsComponent from "./CustomerDetailsComponent";
import LineItemsComponent from "./LineItemsComponent";
import SummaryComponent from "./SummaryComponent";
import TermsAndConditionComponent from "./TermsAndConditionComponent";
import {
  createCustomer,
  updateCustomer,
  createQuotation,
} from "../../../providers/QuoteApiResource";
// import { Notyf } from 'notyf';

// const notyf = new Notyf({
//   duration: 4000, // Set your global Notyf configuration here
//   position: {
//     x: 'center',
//     y: 'top',
//   },
// });

export default [
  {
    id: "customerDetails",
    component: CustomerDetailsComponent,
    initialValues: {
      referenceNumber: "",
      auth_token: "",
      customer_id: 0,
      name: "",
      email: "",
      address: "",
      contact_number: "",
    },
    validationSchema: object().shape({
      name: string()
        .typeError("Customer name is required.")
        .required("Customer name is required."),
      email: string().email().required("Customer email is required."),
    }),
    onAction: async (sectionValues, formValues) => {
      console.log("from on action");
      console.log("sectionValues", sectionValues);
      console.log("formValues.customerDetails", formValues.customerDetails);

      if (sectionValues.customer_id == 0) {
        const response = await createCustomer(sectionValues);
        console.log("response ", response);
        console.log("Clicked Next");
        console.log(sectionValues);
      } else {
        // const response = await updateCustomer(sectionValues);
        // console.log("response ", response)
        // console.log("Clicked Next")
        // console.log(sectionValues)
      }
    },
  },
  {
    id: "itemStep",
    component: LineItemsComponent,
    initialValues: {
      itemName: "",
      itemUnitCost: "",
      subtotal: 0.0,
      vat: 0.0,
      total: 0.0,
      lineItems: [
        { id: 1, description: "", unit_cost: 0 },
        { id: 2, description: "", unit_cost: 0 },
        { id: 3, description: "", unit_cost: 0 },
      ],
    },
    validationSchema: object().shape({
      // lineItems: array().length(1, "At least one item is required"),
      //   itemUnitCost: string().required(),
    }),
    onAction: (sectionValues, formValues) => {
      let error = false;
      let errorMsg = "";
      var newData = [];
      console.log("accessss");
      newData = sectionValues.lineItems.filter(function (data) {
        return data.description !== "";
      });
      if (error) {
        throw new Error(errorMsg);
      }
      if (newData.length === 0) {
        throw new Error("At least one item is required");
      }

      sectionValues.lineItems = newData;

      console.log(sectionValues);
      // if(sectionValues && formValues.lineItems){
      //   if(sectionValues.customer_id == 0){
      //     const response = await createCustomer(sectionValues);
      //     console.log("response ", response)
      //     console.log("Clicked Next")
      //     console.log(sectionValues)
      //   }else{
      //     const response = await updateCustomer(sectionValues);
      //     console.log("response ", response)
      //     console.log("Clicked Next")
      //     console.log(sectionValues)
      //   }
      // }
    },
  },
  {
    id: "termsAndCondition",
    actionLabel: "Next",
    component: TermsAndConditionComponent,
    initialValues: {
      savePreference: true,
      terms_save: true,
      expire_in_days: 30,
      terms_is_deposit_required: "false",
      terms_is_deposit_fixed: "false",
      terms_deposit_percentage: 0,
      terms_deposit_amount: 0,
      terms_other_conditions: "",
    },
    validationSchema: object().shape({
      // expire_in_days: number().min(1, "Invalid Quote to Expire Days").required("Quote to Expire Days is required."),
      // terms_deposit_percentage: number().max(100, "Invalid Deposit Percentage"),
      // accepted: boolean().required("The terms and conditions must be accepted.").oneOf([true], 'The terms and conditions must be accepted.'),
      //   itemUnitCost: string().required(),
    }),
    onAction: async (sectionValues, formValues) => {
      console.log(sectionValues);
      if (sectionValues.terms_deposit_amount > formValues.itemStep.total) {
        throw new Error(
          "Deposit amount must not be greater than the total amount."
        );
      } else if (
        sectionValues.terms_deposit_amount <= 0 &&
        sectionValues.terms_is_deposit_fixed === "true" &&
        sectionValues.terms_is_deposit_required === "true"
      ) {
        throw new Error("Invalid Fixed Deposit Amount.");
      } else if (sectionValues.expire_in_days < 1) {
        throw new Error("Invalid Quote to Expire Days.");
      } else if (!sectionValues.expire_in_days) {
        throw new Error("Quote to Expire Days is required.");
      } else if (
        sectionValues.terms_is_deposit_required === "true" &&
        sectionValues.terms_is_deposit_fixed === "false" &&
        (sectionValues.terms_deposit_percentage > 100 ||
          sectionValues.terms_deposit_percentage < 1)
      ) {
        throw new Error("Invalid Deposit Percentage.");
      }
    },
  },
  {
    id: "summary",
    actionLabel: "Next",
    component: SummaryComponent,
  },
];
