import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardHeader,
  Button,
  ListGroup,
  ListGroupItem,
  Progress
} from "shards-react";

const RightBar = () => (
  <>
  
    <div className="p-3">
      <Button block className="p-3 rounded"  style={{ fontSize: 10}}><span style={{ fontSize: 30}}>26</span> <span className="text-muted">Today</span> <br/> Sunday</Button>
    </div>
  </>
);


export default RightBar;
