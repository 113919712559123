import React, {useContext ,useState, useEffect} from "react";
import { UserContext } from '../../../providers/UserProvider';
import { QuoteContext } from '../../../providers/QuoteProvider';
import PropTypes from "prop-types";
import { Spinner } from 'react-bootstrap';
import { useHistory, Redirect} from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Button
} from "shards-react";
import FormikWizard from 'formik-wizard'
import steps from './steps';
import StepperWidget from '../../../components/common/Stepper';
import { createQuotation } from '../../../providers/QuoteApiResource';
import NotyfContext from '../../../providers/NotificationContext';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

function FormWrapper({
  children,
  isLastStep,
  status,
  goToPreviousStep,
  canGoBack,
  actionLabel,
  currentStep,
  steps,
}) {
const [activeStep, setActiveStep] = useState(0)

useEffect(() => {
  setActiveStep(steps.indexOf(currentStep))
}, [currentStep])

  return (
    <div>
    <StepperWidget steps={['Profile Details','Currency & Tax','Terms & Conditions']} activeStep={activeStep}/>
    <div  style={{marginLeft: 30,}}>
      {children}
      {status && (
        <div>
          <small style={{ color: '#167AF6' }}>
            Sending...
           </small> <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
          <hr />
        </div>
      )}
    </div>

      <div className="mt-3" style={{marginLeft: 30, marginBottom: 15}}>
        <Button theme="accent" className="mr-2" style={{width: 100, height:40}} type="button" hidden={!canGoBack} onClick={(e) => {
          goToPreviousStep(e)
          }} >Previous</Button>
        <Button theme={isLastStep ? "success" : "primary"} disabled={status ? true : false} className="" style={{width:  100, height:40}} type="submit"  >{isLastStep ? 'Save Profile' : <span>Next <i className="fa fa-arrow-right" aria-hidden="true"></i></span>}</Button>
      </div>
    </div>
  )
}

const CompleteProfileWizard = (props) => {
  const {user, updateProfile} = useContext(UserContext)
  const [customer, setCustomer] = useState(null)
  const [sendDisabled, setSendDisabled] = useState(false)
  const history = useHistory();
  const MySwal = withReactContent(Swal)

  const notyf = useContext(NotyfContext);

  const handleSubmit = React.useCallback((values) => {
    console.log('full values:', values)
    notyf.success('Saving Profile...')

    let _user = {
      business_name: values.profileDetails.business_name,
      name: values.profileDetails.name,
      email: values.profileDetails.email,
      contact_number: values.profileDetails.contact_number,
      website: values.profileDetails.website,
      business_address: values.profileDetails.business_address,
      business_registration_number: values.profileDetails.business_registration_number,
      date_format_id: values.currencyTaxDetails.date_format_id,
      tax_label_id: values.currencyTaxDetails.tax_label_id,
      is_vat: values.currencyTaxDetails.is_checked,
      vat_rate: values.currencyTaxDetails.vat_rate,
      currency_id: values.currencyTaxDetails.currency_id,
      quote_expire_in_days: values.termsAndConditionDetails.quote_expire_in_days,
      terms_is_deposit_required: values.termsAndConditionDetails.terms_is_deposit_required  ? "true" : "false",
      terms_is_deposit_fixed: values.termsAndConditionDetails.terms_is_deposit_fixed ? "true" : "false",
      terms_deposit_percentage: values.termsAndConditionDetails.terms_deposit_percentage,
      terms_deposit_amount: values.termsAndConditionDetails.terms_deposit_amount,
      terms_other_conditions: values.termsAndConditionDetails.terms_other_conditions,
    }

    // setSendDisabled(true)
    updateProfile(_user, user.data.auth_token, values.currencyTaxDetails.is_checked).then(response => {
      props.handleClose();
    });
    // console.log("from response ", response)




  }, [])

  return (
    <Row className="justify-content-center align-items-center" style={{}}>
              <Col className="col-12">
                <Card className="" >
                  <CardHeader className="border-bottom">
                    <h6 className="m-0">Profile Information</h6>
                  </CardHeader>
                  <FormikWizard steps={steps}  onSubmit={handleSubmit} render={FormWrapper} />
                </Card>
              </Col>
     </Row>
  );
};

export default CompleteProfileWizard;
