import React, { useState, useContext, useEffect } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import { red } from "@mui/material/colors";

import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Typography from "@mui/material/Typography";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { QuoteContext } from "../../../providers/QuoteProvider";
import AdminMessageQuoteList from "../../../components/messaging/AdminMessageQuoteList";
import AdminMessageList from "../../../components/messaging/AdminMessageList";
import AdminMessageConversationWidget from "../../../components/messaging/AdminMessageConversationWidget";
import LinearProgress from "@mui/material/LinearProgress";
import { useLocation } from "react-router-dom";
import {
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  FormInput,
  Button,
  Row,
  Col,
  Container,
} from "shards-react";

const Messages = () => {
  const { loading, selectedConversation } = useContext(QuoteContext);
  return (
    <Row>
      <Col className="col-12">
        {loading == true ? <LinearProgress /> : null}
      </Col>
      <Col lg={5}>
        <AdminMessageQuoteList />
        <AdminMessageList />
      </Col>
      <Col lg={7}>
        {selectedConversation && <AdminMessageConversationWidget />}
      </Col>
    </Row>
  );
};

export default Messages;
