import React, {useState, useEffect, useContext} from 'react'
import { Field, useFormikContext } from 'formik'
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  FormCheckbox,
  Button
} from "shards-react";
import TextField from '@mui/material/TextField';
import { UserContext } from '../../../providers/UserProvider';


function CurrencyTaxDetailComponent() {
  const { errors, touched, values, setFieldValue, handleChange } = useFormikContext()
  const {user, changePassword} = useContext(UserContext)
  const [isChecked, setIsChecked] = useState(false)


  const setIsVat = (val) => {
    setIsChecked(val)
    return val;
  }

  useEffect(() => {
    if(user.data && user.data.auth_token){
      // setFieldValue('email', user.data.email)
    }
  }, [])

  useEffect(() => {
  }, [])

  return (
    <div className="w-100">
          <Row>
            <Col lg="12" md="12">
            <Row>
                      <Col lg={5}  className="form-group">
                        <Row >
                            <Col lg={12}>
                              <Row style={{marginLeft: 1}}>
                              <span className="mr-1">Default Date Format </span>
                                <select defaultValue={values.date_format_id ? values.date_format_id : 1} className="form-select form-select-lg" aria-label=".form-select-lg example" style={{padding: 6, marginTop: -5}}
                                onChange={(e) => {setFieldValue("date_format_id", e.target.value)}}>
                                      <option style={{fontSize: 13}} value={1}>DD/MM/YYYY</option>
                                      <option style={{fontSize: 13}} value={2}>MM/DD/YYYY</option>
                                </select>
                              </Row>
                            </Col>
                        </Row>
                      </Col>
                   </Row>
                <Row>
                  <Col lg={5}  className="form-group">
                    <Row >
                        <Col lg={12}>
                          <Row style={{marginLeft: 1}}>
                            <span className="mr-1">Select </span>
                            <select className="form-select form-select-lg" aria-label=".form-select-lg example" style={{padding: 6, marginTop: -5}}  onChange={(e) => {setFieldValue("currency_id", e.target.value)}} defaultValue={5}>
                                  <option style={{fontSize: 13}} value={1}>AUD</option>
                                  <option style={{fontSize: 13}} value={2}>CAD</option>
                                  <option style={{fontSize: 13}} value={3}>GBP</option>
                                  <option style={{fontSize: 13}} value={4}>NZD</option>
                                  <option style={{fontSize: 13}} value={5}>USD</option>
                            </select>
                            <span style={{marginLeft: 4}}>as Currency</span>
                          </Row>
                        </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={6} sm={6} xs={6} className="form-group">
                    <FormCheckbox
                      id="is_vat"
                      name="is_vat"
                      value={isChecked}
                      checked={isChecked}
                      onChange={(e) => {
                        setFieldValue("is_checked", !isChecked)
                        setIsChecked(!isChecked)
                        handleChange(e)
                      }}
                    >Add Sales Tax
                    </FormCheckbox>
                  </Col>
                </Row>
                <Row>
                  <Col lg={5}  className="form-group">
                    <Row >
                        <Col lg={12}>
                          <Row style={{marginLeft: 1}}>
                            <span className="mr-1">Sales Tax Label </span>
                            <select className="form-select form-select-lg" aria-label=".form-select-lg example" style={{padding: 6, marginTop: -5}} defaultValue={1} onChange={(e) => {
                              setFieldValue("tax_label_id", e.target.value)
                            }}>
                                  <option style={{fontSize: 13}} value={1}>GST</option>
                                  <option style={{fontSize: 13}} value={2}>VAT</option>
                            </select>
                          </Row>
                        </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col lg={5}  className="form-group">
                    <Row >
                        <Col lg={12}>
                          <Row style={{marginLeft: 1}}>
                            <span className="mr-1">Sales Tax</span>
                            <Field
                              type="number"
                              id="vat_rate"
                              name="vat_rate"
                              value={values.vat_rate}
                              onChange={(e) => {setFieldValue("vat_rate", e.target.value)}}
                              disabled={!isChecked}
                              style={{width: 50, height: 30, marginTop: -5}}
                            />
                            <span style={{marginLeft: 4}}>&#x25;</span>
                          </Row>
                        </Col>
                    </Row>
                  </Col>
                </Row>
            </Col>
          </Row>
    </div>
  )
}

export default CurrencyTaxDetailComponent;
