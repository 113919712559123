import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, useLocation } from "react-router-dom";

import routes from "./routes";
import UserProvider from "./providers/UserProvider";
import QuoteProvider from "./providers/QuoteProvider";
import ScrollToTop from "./ScrollToTop";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets-shards/styles/scss/shards-dashboards.scss";
import "notyf/notyf.min.css";

const theme = createTheme({
  primary: {
    main: "#007DC4",
  },
  palette: {
    primary: {
      main: "#007DC4",
    },
    secondary: {
      main: "#007DC4",
    },
  },
  typography: {
    fontSize: 13,
    color: "#495057",
    fontWeight: "normal",
  },
  overrides: {
    MuiTextField: {
      root: {
        fontSize: 13,
        color: "#495057",
        fontWeight: "normal",
      },
    },
    MuiInput: {
      root: {
        fontSize: 15,
        color: "#495057",
        fontWeight: "normal",
      },
    },
    TheInput: {
      fontSize: 13,
      color: "#495057",
      fontWeight: "normal",
    },
    Input: {
      fontSize: 13,
      color: "#495057",
      fontWeight: "normal",
    },
    MuiInputLabel: {
      shrink: {
        fontSize: 13,
      },
      root: {
        fontSize: 13,
      },
    },
  },
});

export default () => (
  <UserProvider>
    <QuoteProvider>
      <ThemeProvider theme={theme}>
        <Router basename={process.env.REACT_APP_BASENAME || ""}>
          <ScrollToTop />
          <div>
            {routes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  onChange={() => {
                    console.log("Route Change ", route.path);
                  }}
                  component={(props) => {
                    return (
                      <route.layout {...props}>
                        <route.component {...props} theme={theme} />
                      </route.layout>
                    );
                  }}
                />
              );
            })}
          </div>
        </Router>
      </ThemeProvider>
    </QuoteProvider>
  </UserProvider>
);
