import React, {useContext ,useState, useEffect} from "react";
import { UserContext } from '../../providers/UserProvider';
import { QuoteContext } from '../../providers/QuoteProvider';
import PropTypes from "prop-types";
import { Spinner } from 'react-bootstrap';
import { useHistory, Redirect} from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Button
} from "shards-react";
import FormikWizard from 'formik-wizard'
import steps from './form-steps/steps';
import StepperWidget from '../common/Stepper';
import { createQuotation, saveDraft } from '../../providers/QuoteApiResource';
import NotyfContext from '../../providers/NotificationContext';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

function FormWrapper({
  children,
  isLastStep,
  status,
  goToPreviousStep,
  canGoBack,
  actionLabel,
  currentStep,
  steps,
  sendDisabled,
}) {
const [activeStep, setActiveStep] = useState(0)
const MySwal = withReactContent(Swal)
const notyf = useContext(NotyfContext);
  const history = useHistory();

useEffect(() => {
  setActiveStep(steps.indexOf(currentStep))
}, [currentStep])

const showSaveDraftButton = () => {
  if(activeStep == 1 || activeStep == 2){
    return (<span className="text-primary" style={{ cursor: "pointer", fontWeight: 600, marginLeft: 5}} onClick={() => {
        notyf.success('Saving quotation as draft...')
        saveDraft().then(response => {
          if(response.status){
              history.push("/quotes/list");
            }
            // if(response.status){
            //   notyf.success(response.message)
            //   // history.push("/quotes/list");
            // }else{
            //   notyf.error(response.message)
            // }
            // console.log("from response ", response)
        });

    }}>Save as Draft</span>);
  }
}

  return (
    <div>
    <StepperWidget steps={['Customer Details','Items','Terms & Conditions','Preview']} activeStep={activeStep}/>
    <div  style={{marginLeft: 6,}}>
      {children}
      {status && (
        <div>
          {/* <small style={{ color: '#167AF6' }}>
            Sending...
           </small> <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    /> */}
          <small className="text-danger">
          {status.message}
           </small>
          <hr />
        </div>
      )}
    </div>

      <div className="mt-3" >
        <Button theme="accent" className="ml-2" style={{width: 90, height:40}} type="button" disabled={!canGoBack || sendDisabled} onClick={(e) => {
          goToPreviousStep(e)
          }} >Previous</Button>
        <Button theme={isLastStep ? "success" : "primary"} disabled={isLastStep && status ? true : false} className="ml-2" style={{width:  sendDisabled ? 130 : 90, height:40}} type="submit"  >{isLastStep ? 'Send' : 'Next'}{sendDisabled == true ? "ing": null} {sendDisabled == true ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
          ) : null}</Button>
        {showSaveDraftButton()}
      </div>
    </div>
  )
}

const CreateQuoteForm = ({ title}) => {
  const {user, getUserProfile} = useContext(UserContext)
  const [customer, setCustomer] = useState(null)
  const [sendDisabled, setSendDisabled] = useState(false)
  const history = useHistory();
  const MySwal = withReactContent(Swal)

  const notyf = useContext(NotyfContext);

  const handleSubmit = React.useCallback((values) => {
    console.log('full values:', values)
    MySwal.fire({
      title: "Confirmation",
      text: `Do you want to send quotation to ${values.customerDetails.email} now?`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Send Quote'
    }).then((result) => {
      if (result.isConfirmed) {
        if(sendDisabled === false){
          setSendDisabled(true);
          notyf.success('Sending quotation...')
          // setSendDisabled(true)
          createQuotation(values.customerDetails, values.itemStep, values.termsAndCondition).then(response => {
            if(response.status){
              if(values.termsAndCondition.terms_save){
                getUserProfile(values.customerDetails.auth_token)
              }

              notyf.success(response.message)
              history.push("/quotes/list");
            }else{
              notyf.error(response.message)
            }
            setSendDisabled(false)
            console.log("from response ", response)
          });
         }
      }
    })




  }, [])

  return (
    <Card small className="mb-4">
      <CardHeader className="border-bottom">
        <h6 className="m-0">{title}</h6>
      </CardHeader>

      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
            <FormikWizard steps={steps} sendDisabled={sendDisabled} onSubmit={handleSubmit} render={FormWrapper} />
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
};

CreateQuoteForm.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

CreateQuoteForm.defaultProps = {
  title: "Account Details"
};

export default CreateQuoteForm;
