import React from "react";
import { Nav, Collapse, Button  } from "shards-react";

import SidebarNavItem from "./SidebarNavItem";
import { Store } from "../../../flux";
import { Link } from 'react-router-dom';

class SidebarNavItems extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      navItems: Store.getSidebarItems()
    };

    // this.toggle = this.toggle.bind(this);
    // this.state = { collapse: true };
    this.onChange = this.onChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    Store.addChangeListener(this.onChange);
  }

  UNSAFE_componentWillUnmount() {
    Store.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState({
      ...this.state,
      navItems: Store.getSidebarItems()
    });
  }
  // toggle() {
  //   this.setState({ collapse: !this.state.collapse });
  // }


  render() {
    const { navItems: items } = this.state;
    return (
      <div className="nav-wrapper">
        <Nav className="nav--no-borders flex-column">
          {items.map((item, idx) => (
            <SidebarNavItem key={idx} item={item} />
          ))}
          <div className="p-3">
            <Button block className="p-3 rounded-pill" tag={(props)=> <Link  {...props} />} to="/quotes/create"  style={{}}> <i className="fa fa-plus" aria-hidden="true"></i> New Quote</Button>
            <p className="text-center mt-3">
              <span className="page-subtitle text-muted" style={{ fontSize: 8}}>Quote Shark Admin Dashboard <br /></span>
              <span className="text-muted" style={{ fontSize: 8, marginTop: -5}}>&#169; 2021 All Rights Reserved</span>
            </p>
          </div>
        </Nav>
        {/* <div>
          <Button onClick={this.toggle}>Toggle</Button>
          <Collapse open={this.state.collapse}>
            <div className="p-3 mt-3 border rounded">
              <h5>😁 I am already visible!</h5>
              <span>
                In sagittis nibh non arcu viverra, nec imperdiet quam suscipit.
                Sed porta eleifend scelerisque. Vestibulum dapibus quis arcu a
                facilisis.
              </span>
            </div>
          </Collapse>
        </div> */}
      </div>
    )
  }
}

export default SidebarNavItems;
