import React from "react";
import { Link, Redirect} from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from "shards-react";
import { UserContext } from '../../../../providers/UserProvider';

export default class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false
    };

    this.toggleUserActions = this.toggleUserActions.bind(this);
  }

  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }

  render() {
    return (
      <UserContext.Consumer>
      {(userContext) => {
        const { user, setUser, logOut, setCompleteProfileInformation } = userContext;
        console.log("user", user)

        const pathname = window.location.pathname
        console.log("pathname ", pathname)
        let _pathname = localStorage.getItem("admin-pathname");

        if (_pathname != pathname) {
          localStorage.setItem("admin-pathname", pathname);
        }

        if(user && user.data){
          return (
            <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
              <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
              {user.data && user.data.image === null ? ( <img
                  className="user-avatar rounded-circle mr-2"
                  src={require("../../../../assets/avatardefault.png")}
                  alt="User Avatar"
                />): ( <img
                  className="user-avatar rounded-circle mr-2"
                  src={user.data.image}
                  alt="User Avatar"
                />)}
              {" "}
                <span className="d-none d-md-inline-block">{user.data && user.data.name ? user.data.name : "Hello there!"}</span>
              </DropdownToggle>
              <Collapse tag={DropdownMenu} right small open={this.state.visible}>
                <DropdownItem  tag={(props)=> <Link  {...props} />} to="/settings/profile">
                <i className="fa fa-user" aria-hidden="true"></i> Profile
                </DropdownItem>
                <DropdownItem  tag={(props)=> <Link  {...props} />} to="/settings/notifications">
                <i className="fa fa-bell" aria-hidden="true"></i> Notifications
                </DropdownItem>
                <DropdownItem  tag={(props)=> <Link  {...props} />} to="/change-password">
                <i className="fa fa-cog" aria-hidden="true"></i> Change Password
                </DropdownItem>
                {/* <DropdownItem  tag={(props)=> <Link  {...props} />} to="file-manager-list">
                  <i className="material-icons">&#xE2C7;</i> Files
                </DropdownItem>
                <DropdownItem  tag={(props)=> <Link  {...props} />} to="transaction-history">
                  <i className="material-icons">&#xE896;</i> Transactions
                </DropdownItem> */}
                <DropdownItem divider />
                <DropdownItem  tag={(props)=> <Link  {...props} />} to="/" className="text-danger" onClick={() => {
                  logOut(user);
                  setCompleteProfileInformation(false)
                  setUser(null)
                }}>
                  <i className="material-icons text-danger">&#xE879;</i> Logout
                </DropdownItem>
              </Collapse>
            </NavItem>);
        }else{
          return (
            <Redirect to="/login"/>
          );
        }
      }}
      </UserContext.Consumer>
    );
  }
}
