import React, { useState, createContext, useContext } from "react";
import { backend_url } from "../config/endpoints";
import axios from "axios";
import NotyfContext from "./NotificationContext";
import moment from "moment";

export const QuoteContext = createContext({ user: null });

const QuoteProvider = (props) => {
  const [user, setuser] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [allQuoteList, setAllQuoteList] = useState([]);
  const [quoteList, setQuoteList] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [quoteListDetails, setQuoteListDetails] = useState(null);
  const [publicQuoteDetail, setPublicQuoteDetail] = useState(null);
  const [latestQuoteList, setLatestQuoteList] = useState([]);
  const [customerQuotesList, setCustomerQuotesList] = useState([]);
  const [nextTransactionNumber, setNextTransactionNumber] = useState(null);
  const [items, setItems] = useState(null);
  const [selectedConversation, setSelectedConversation] = useState(false);
  const [messageList, setMessageList] = useState([]);
  const [messageConversationList, setMessageConversationList] = useState([]);
  const [dateFormats, setDateFormats] = useState([]);
  const [taxLabels, setTaxLabels] = useState([]);
  const [currencyFormat, setCurrencyFormat] = useState([]);
  const [authToken, setAuthToken] = useState(null);
  const [filterMessage, setFilterMessage] = useState({
    status_id: 12,
    customer: "",
    expiry_from: "",
    expiry_to: "",
    archived: 0,
  });
  const notyf = useContext(NotyfContext);
  let cancelToken;

  const sendMessage = async (quote_id, qtoken, message) => {
    // setLoading(true);

    console.log("quote_id ", quote_id);
    console.log("qtoken ", qtoken);

    var data = {
      data: {
        message: message,
      },
    };

    var config = {
      method: "post",
      url:
        backend_url +
        `/quotation_customer_messages?quotation_id=${quote_id}&qtoken=${qtoken}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(config)
      .then(function (res) {
        console.log(JSON.stringify(res.data));
        if (res.data.status) {
          // notyf.success(res.data.message);
          setMessageConversationList(res.data.data);
        } else {
          // notyf.error(res.data.message);
        }
        setLoading(false);
        return res.data.status;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const sendMessageBusiness = async (quote_id, qtoken, message, auth_token) => {
    // setLoading(true);

    console.log("quote_id ", quote_id);
    console.log("qtoken ", qtoken);

    var data = {
      data: {
        message: message,
      },
    };

    var config = {
      method: "post",
      url:
        backend_url +
        `/quotation_messages?quotation_id=${quote_id}&qtoken=${qtoken}`,
      headers: {
        Authorization: auth_token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    return await axios(config)
      .then(function (res) {
        console.log(JSON.stringify(res.data));
        if (res.data.status) {
          // notyf.success(res.data.message);
          setMessageConversationList(res.data.data);
          getMessageList(auth_token);
        } else {
          // notyf.error(res.data.message);
        }
        setLoading(false);
        return res.data.status;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const setArchiveConversation = async (quote_id, qtoken, auth_token) => {
    // setLoading(true);

    console.log("quote_id ", quote_id);
    console.log("qtoken ", qtoken);

    var config = {
      method: "post",
      url:
        backend_url +
        `/quotation_messages/archive?quotation_id=${quote_id}&qtoken=${qtoken}`,
      headers: {
        Authorization: auth_token,
        "Content-Type": "application/json",
      },
    };

    return await axios(config)
      .then(function (res) {
        console.log(JSON.stringify(res.data));
        if (res.data.status) {
          notyf.success(res.data.message);
          setMessageConversationList(res.data.data);
          getMessageList(auth_token);
        } else {
          notyf.error(res.data.message);
        }
        setLoading(false);
        return res.data.status;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getMessageList = async (auth_token) => {
    setLoading(true);
    var config = {
      method: "get",
      url: backend_url + `/quotation_messages`,
      headers: {
        Authorization: auth_token,
        "Content-Type": "application/json",
      },
    };

    return await axios(config)
      .then(function (res) {
        setLoading(false);
        console.log("res.data", res.data.data);
        if (res.data.status) {
          setMessageList(res.data.data);
        }
        return res.data.status;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getMessageListWithoutLoading = async (auth_token) => {
    // setLoading(true);
    //Check if there are any previous pending requests
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }

    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source();

    var config = {
      method: "get",
      url: backend_url + `/quotation_messages`,
      headers: {
        Authorization: auth_token,
        "Content-Type": "application/json",
      },
      cancelToken: cancelToken.token,
    };

    return await axios(config)
      .then(function (res) {
        // setLoading(false);
        console.log("res.data", res.data.data);
        if (res.data.status) {
          if (res.data.data.length > messageList && filterMessage == 12) {
            setMessageList(res.data.data);
          }
        }
        return res.data.status;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getMessageListStatus = async (auth_token, status) => {
    setMessageList([]);
    setLoading(true);
    var config = {
      method: "get",
      url: backend_url + `/quotation_messages?status=${status}`,
      headers: {
        Authorization: auth_token,
        "Content-Type": "application/json",
      },
    };

    return await axios(config)
      .then(function (res) {
        setLoading(false);
        console.log("res.data", res.data.data);
        if (res.data.status) {
          setMessageList(res.data.data);
        }
        return res.data.status;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getConversationListCustomer = async (quotation_id, qtoken) => {
    setLoading(true);
    var config = {
      method: "get",
      url:
        backend_url +
        `/quotation_customer_messages/view?quotation_id=${quotation_id}&qtoken=${qtoken}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    return await axios(config)
      .then(function (res) {
        setLoading(false);
        console.log("res.data", res.data.data);
        if (res.data.status) {
          setMessageConversationList(res.data.data);
        }
        return res.data.status;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getConversationListCustomerWithoutLoading = async (
    quotation_id,
    qtoken
  ) => {
    var config = {
      method: "get",
      url:
        backend_url +
        `/quotation_customer_messages/view?quotation_id=${quotation_id}&qtoken=${qtoken}`,
      headers: {
        "Content-Type": "application/json",
      },
    };

    return await axios(config)
      .then(function (res) {
        console.log("res.data", res.data.data);
        if (res.data.status) {
          if (res.data.data.length > messageConversationList) {
            setMessageConversationList(res.data.data);
          }
        }
        return res.data.status;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getMessageConversationList = async (
    quotation_id,
    qtoken,
    auth_token
  ) => {
    setMessageConversationList([]);
    setLoading(true);

    var config = {
      method: "get",
      url:
        backend_url +
        `/quotation_messages/view?quotation_id=${quotation_id}&qtoken=${qtoken}`,
      headers: {
        Authorization: auth_token,
        "Content-Type": "application/json",
      },
    };

    return await axios(config)
      .then(function (res) {
        setLoading(false);
        console.log("res.data", res.data.data);
        if (res.data.status) {
          setMessageConversationList(res.data.data);
        }
        return res.data.status;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getMessageConversationListWithoutLoading = async (
    quotation_id,
    qtoken,
    auth_token
  ) => {
    //Check if there are any previous pending requests
    if (typeof cancelToken != typeof undefined) {
      cancelToken.cancel("Operation canceled due to new request.");
    }

    //Save the cancel token for the current request
    cancelToken = axios.CancelToken.source();

    if (quotation_id == selectedConversation.quotation_id) {
      var config = {
        method: "get",
        url:
          backend_url +
          `/quotation_messages/view?quotation_id=${quotation_id}&qtoken=${qtoken}`,
        headers: {
          Authorization: auth_token,
          "Content-Type": "application/json",
        },
        cancelToken: cancelToken.token,
      };

      return await axios(config)
        .then(function (res) {
          console.log("res.data", res.data.data);
          if (res.data.status) {
            if (res.data.data.length > messageConversationList) {
              setMessageConversationList(res.data.data);
            }
          }
          return res.data.status;
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const sendQuotation = async (customer, items, terms, summary) => {
    var data = {};

    var _items = [];

    items.lineItems = items.lineItems.filter(function (data) {
      return data.description !== "";
    });

    items.lineItems.forEach((item, index) => {
      if (item.item_id == 0) {
        _items[index] = {
          item_description: item.description,
          item_unit_cost: item.unit_cost,
        };
      } else {
        _items[index] = {
          item_description: item.description,
          item_unit_cost: item.unit_cost,
          item_id: item.item_id,
        };
      }
    });

    if (customer.customer_id != 0) {
      data = {
        data: {
          reference_number: customer.referenceNumber,
          terms_save: terms.terms_save,
          expire_in_days: terms.expire_in_days,
          terms_is_deposit_required: terms.terms_is_deposit_required,
          terms_is_deposit_fixed: terms.terms_is_deposit_fixed,
          terms_deposit_percentage: terms.terms_deposit_percentage,
          terms_deposit_amount: terms.terms_deposit_amount,
          terms_other_conditions: terms.terms_other_conditions,
          customer_id: customer.customer_id,
          quotation_details_attributes: _items,
          customer_contact_number: "",
        },
      };
    } else {
      data = {
        data: {
          reference_number: customer.referenceNumber,
          terms_save: terms.terms_save,
          expire_in_days: terms.expire_in_days,
          terms_is_deposit_required: terms.terms_is_deposit_required,
          terms_is_deposit_fixed: terms.terms_is_deposit_fixed,
          terms_deposit_percentage: terms.terms_deposit_percentage,
          terms_deposit_amount: terms.terms_deposit_amount,
          terms_other_conditions: terms.terms_other_conditions,
          customer_name: customer.name,
          customer_email: customer.email,
          customer_address: customer.address,
          quotation_details_attributes: _items,
          customer_contact_number: "",
        },
      };
    }

    var config = {
      method: "post",
      url: backend_url + "/quotations",
      headers: {
        Authorization: customer.auth_token,
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(config)
      .then(function (res) {
        console.log(res.data);
        if (res.data.status) {
          return res.data;
        } else {
          return res.data;
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  const editQuotation = async (customer, items, terms, id) => {
    var data = {};

    var _items = [];

    items.lineItems = items.lineItems.filter(function (data) {
      return data.description !== "";
    });

    items.lineItems.forEach((item, index) => {
      if (item.item_id == 0) {
        _items[index] = {
          item_description: item.description,
          item_unit_cost: item.unit_cost,
        };
      } else {
        _items[index] = {
          item_description: item.description,
          item_unit_cost: item.unit_cost,
          item_id: item.item_id,
        };
      }
    });

    if (customer.customer_id != 0) {
      data = {
        data: {
          reference_number: customer.referenceNumber,
          terms_save: terms.terms_save,
          expire_in_days: terms.expire_in_days,
          expiration_date: terms.expiration_date,
          terms_is_deposit_required: terms.terms_is_deposit_required,
          terms_is_deposit_fixed: terms.terms_is_deposit_fixed,
          terms_deposit_percentage: terms.terms_deposit_percentage,
          terms_deposit_amount: terms.terms_deposit_amount,
          terms_other_conditions: terms.terms_other_conditions,
          customer_id: customer.customer_id,
          quotation_details_attributes: _items,
          customer_contact_number: customer.customer_number,
        },
      };
    } else {
      data = {
        data: {
          reference_number: customer.referenceNumber,
          terms_save: terms.terms_save,
          expire_in_days: terms.expire_in_days,
          expiration_date: terms.expiration_date,
          terms_is_deposit_required: terms.terms_is_deposit_required,
          terms_is_deposit_fixed: terms.terms_is_deposit_fixed,
          terms_deposit_percentage: terms.terms_deposit_percentage,
          terms_deposit_amount: terms.terms_deposit_amount,
          terms_other_conditions: terms.terms_other_conditions,
          customer_name: customer.name,
          customer_email: customer.email,
          customer_address: customer.address,
          quotation_details_attributes: _items,
          customer_contact_number: customer.customer_number,
        },
      };
    }

    var config = {
      method: "put",
      url: backend_url + "/quotations/" + id,
      headers: {
        Authorization: customer.auth_token,
        "Content-Type": "application/json",
      },
      data: data,
    };
    return await axios(config)
      .then(function (res) {
        console.log(res.data);
        if (res.data.status) {
          return res.data;
        } else {
          return res.data;
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  const updateDraftQuoteStatus = async (
    auth_token,
    quote_id,
    status_id,
    reason = ""
  ) => {
    var config = {
      method: "post",
      url: backend_url + "/quotations/" + quote_id + "/update_status",
      headers: {
        Authorization: auth_token,
        "Content-Type": "application/json",
      },
      data: {
        data: {
          status_id: parseInt(status_id),
        },
      },
    };

    return await axios(config)
      .then(function (res) {
        getQuotes(auth_token);
        setLoading(false);
        return res.data.status;
      })
      .catch(function (error) {});
  };

  const updateQuoteStatus = async (
    auth_token,
    quote_id,
    status_id,
    reason = ""
  ) => {
    // var _items = [];

    // var _items = [];

    // quoteDetails.items = quoteDetails.items.filter(function(data) {
    //   return data.description !== ""
    // });

    // quoteDetails.items.forEach((item, index) => {
    //   if(!item.id){
    //     _items[index] = {
    //       "item_description" : item.item_description,
    //       "item_unit_cost" : item.item_unit_cost,
    //     }
    //   }else{
    //     _items[index] = {
    //       // "item_description" : item.item_description,
    //       "item_unit_cost" : item.item_unit_cost,
    //       "item_id" : item.id,
    //     }
    //   }
    // });

    // var start = moment(quoteDetails.quotation_date, "YYYY-MM-DD");
    // var end = moment(quoteDetails.expiration_date, "YYYY-MM-DD");
    // //Difference in number of days
    // var days = moment.duration(start.diff(end)).asDays();

    // var data = {
    //   "data": {

    //     "customer_name": quoteDetails.customer_name,
    //     "customer_email": quoteDetails.customer_email,
    //     "customer_address": quoteDetails.customer_address,

    //     "terms_save": true,
    //     "expire_in_days": days,
    //     "terms_is_deposit_required": quoteDetails.terms_is_deposit_required,
    //     "terms_is_deposit_fixed": quoteDetails.terms_is_deposit_fixed,
    //     "terms_deposit_percentage": quoteDetails.terms_deposit_percentage,
    //     "terms_deposit_amount": quoteDetails.terms_deposit_amount,
    //     "terms_other_conditions": quoteDetails.terms_other_conditions,

    //     "quotation_details_attributes": _items,

    //     "status_id": parseInt(status_id),
    //   }
    // }

    // console.log("quoteDetails update quote data ", data)

    notyf.success("Updating status...");

    var config = {
      method: "post",
      url: backend_url + "/quotations/" + quote_id + "/update_status",
      headers: {
        Authorization: auth_token,
        "Content-Type": "application/json",
      },
      data: {
        data: {
          status_id: parseInt(status_id),
        },
      },
    };

    return await axios(config)
      .then(function (res) {
        console.log(JSON.stringify(res.data));
        if (res.data.status) {
          getQuotes(auth_token);
          notyf.success(res.data.message);
        } else {
          // notyf.error(res.data.message)
        }
        // notyf.success("Status updated successfully.")
        setLoading(false);
        return res.data.status;
      })
      .catch(function (error) {
        // notyf.success("Status updated successfully.")
        // notyf.error("Something went wrong")
        // console.log(error);
      });
  };

  const resendQuote = async (auth_token, id) => {
    setLoading(true);

    var config = {
      method: "post",
      url: backend_url + "/quotations/" + id + "/email",
      headers: {
        Authorization: auth_token,
        "Content-Type": "application/json",
      },
    };

    return await axios(config)
      .then(function (res) {
        if (res.data.status) {
          notyf.success(res.data.message);
        } else {
          // notyf.error(res.data.message)
        }
        setLoading(false);
        return res.data.status;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const archiveQuote = async (auth_token, quote_id) => {
    setLoading(true);

    var config = {
      method: "post",
      url: backend_url + "/quotations/" + quote_id + "/archive",
      headers: {
        Authorization: auth_token,
        "Content-Type": "application/json",
      },
    };

    return await axios(config)
      .then(function (res) {
        console.log(JSON.stringify(res.data));
        if (res.data.status) {
          notyf.success(res.data.message);
          getQuotes(auth_token);
        } else {
          // notyf.error(res.data.message)
        }
        setLoading(false);
        return res.data.status;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const restoreQuote = async (auth_token, quote_id) => {
    setLoading(true);

    var config = {
      method: "post",
      url: backend_url + "/quotations/" + quote_id + "/restore",
      headers: {
        Authorization: auth_token,
        "Content-Type": "application/json",
      },
    };

    return await axios(config)
      .then(function (res) {
        console.log(JSON.stringify(res.data));
        if (res.data.status) {
          notyf.success(res.data.message);
          var filter = {
            status_id: 0,
            customer: "",
            expiry_from: "",
            expiry_to: "",
            archived: 1,
          };
          getQuotesWithFilter(auth_token, filter);
        } else {
          // notyf.error(res.data.message)
        }
        setLoading(false);
        return res.data.status;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getNextTransactionNumber = async (auth_token) => {
    setLoading(true);

    var config = {
      method: "get",
      url: backend_url + "/next_quotation_number",
      headers: {
        Authorization: auth_token,
      },
    };

    return await axios(config)
      .then(function (res) {
        console.log("next_quotation_number ", JSON.stringify(res.data));
        if (res.data) {
          // notyf.success(res.data.message)
          setNextTransactionNumber(res.data.transaction_number);
        }
        setLoading(false);
        return res.data;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getNotifications = async (auth_token) => {
    setLoading(true);

    var config = {
      method: "get",
      url: backend_url + "/notifications",
      headers: {
        Authorization: auth_token,
      },
    };

    return await axios(config)
      .then(function (res) {
        console.log(JSON.stringify(res.data));
        if (res.data.status) {
          // notyf.success(res.data.message)
          setNotifications(res.data.data);
        }
        setLoading(false);
        return res.data.status;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getCustomers = (auth_token) => {
    var config = {
      method: "get",
      url: backend_url + "/customers",
      headers: {
        Authorization: auth_token,
      },
    };

    axios(config)
      .then(function (res) {
        console.log(JSON.stringify(res.data));
        if (res.data.status) {
          setCustomers(res.data.data);
        } else {
          notyf.error(res.data.message);
        }
      })
      .catch(function (error) {
        // notyf.error("Something went wrong")
        console.log(error);
      });
  };

  const getQuotes = (auth_token) => {
    setLoading(true);
    var config = {
      method: "get",
      url: backend_url + "/quotations",
      headers: {
        Authorization: auth_token,
      },
    };

    axios(config)
      .then(function (res) {
        if (res.data.status) {
          setQuoteList(res.data.data);
          setAllQuoteList(res.data.data);
        } else {
          // notyf.error(res.data.message)
        }

        setLoading(false);
      })
      .catch(function (error) {
        // notyf.error("Something went wrong")
        console.log(error);
        setLoading(false);
      });
  };

  const getLatestQuotes = (auth_token) => {
    setLoading(true);
    var config = {
      method: "get",
      url: backend_url + "/quotations/latest",
      headers: {
        Authorization: auth_token,
      },
    };

    axios(config)
      .then(function (res) {
        if (res) {
          setLatestQuoteList(res.data.data);
        }

        setLoading(false);
      })
      .catch(function (error) {
        // notyf.error("Something went wrong")
        console.log(error);
        setLoading(false);
      });
  };

  const getQuotesDetails = async (auth_token, id, status_id = 12) => {
    var config = {
      method: "get",
      url: backend_url + "/quotations/" + id,
      headers: {
        Authorization: auth_token,
      },
    };

    setLoading(true);
    axios(config)
      .then(function (res) {
        if (res.data.status) {
          localStorage.setItem("quoteDetails", JSON.stringify(res.data.data));
          setQuoteListDetails(res.data.data);
          if (status_id != 12) {
            updateQuoteStatus(
              res.data.data,
              auth_token,
              res.data.data.id,
              status_id
            );
          }
          setLoading(false);
        } else {
          // notyf.error(res.data.message)
        }
      })
      .catch(function (error) {
        // notyf.error("Something went wrong")
        console.log(error);
      });
  };

  const getQuotesWithFilter = (auth_token, filter) => {
    setLoading(true);
    var _url = "";
    if (filter.status_id != "12") {
      _url =
        backend_url +
        `/quotations/?status_id=${filter.status_id}&expiry_from=${filter.expiry_from}&expiry_to=${filter.expiry_to}&customer=${filter.customer}&archived=${filter.archived}`;
      if (filter.customer == "all") {
        _url =
          backend_url +
          `/quotations/?status_id=${filter.status_id}&expiry_from=${filter.expiry_from}&expiry_to=${filter.expiry_to}&archived=${filter.archived}`;
      }
    } else if (filter.status_id == "12" && filter.customer != "") {
      _url = backend_url + `/quotations/?customer=${filter.customer}`;
      if (filter.customer == "all") {
        _url =
          backend_url +
          `/quotations/?expiry_from=${filter.expiry_from}&expiry_to=${filter.expiry_to}&archived=${filter.archived}`;
      }
    } else {
      _url = backend_url + `/quotations/?archived=${filter.archived}`;
    }
    var config = {
      method: "get",
      url: _url,
      headers: {
        Authorization: auth_token,
      },
    };

    axios(config)
      .then(function (res) {
        console.log(JSON.stringify(res.data));
        if (res.data.status) {
          setQuoteList(res.data.data);
        } else {
          // notyf.error(res.data.message)
        }
        setLoading(false);
      })
      .catch(function (error) {
        // notyf.error("Something went wrong")
        console.log(error);
        setLoading(false);
      });
  };

  const getQuotesOfCustomer = (auth_token, customer) => {
    setLoading(true);
    var _url = "";
    _url = backend_url + `/quotations/?customer=${customer}`;
    var config = {
      method: "get",
      url: _url,
      headers: {
        Authorization: auth_token,
      },
    };

    return axios(config)
      .then(function (res) {
        setLoading(false);
        setCustomerQuotesList(res.data.data);
        return res.data;
      })
      .catch(function (error) {
        // notyf.error("Something went wrong")
        console.log(error);
        setLoading(false);
      });
  };

  const getItems = (auth_token) => {
    var config = {
      method: "get",
      url: backend_url + "/items",
      headers: {
        Authorization: auth_token,
      },
    };

    axios(config)
      .then(function (res) {
        console.log(JSON.stringify(res.data));
        if (res.data.status) {
          setItems(res.data.data);
        } else {
          // notyf.error(res.data.message)
        }
      })
      .catch(function (error) {
        // notyf.error("Something went wrong")
        console.log(error);
      });
  };

  const deleteItem = (auth_token, id) => {
    var config = {
      method: "delete",
      url: backend_url + "/items/" + id,
      headers: {
        Authorization: auth_token,
      },
    };

    axios(config)
      .then(function (res) {
        console.log(JSON.stringify(res.data));
        if (res.data.status) {
          getItems(auth_token);
          notyf.success(res.data.message);
        } else {
          notyf.error(res.data.message);
        }
      })
      .catch(function (error) {
        // notyf.error("Something went wrong")
        console.log(error);
      });
  };

  const createCustomer = async (auth_token, data) => {
    var config = {
      method: "post",
      url: backend_url + "/customers",
      headers: {
        Authorization: auth_token,
        "Content-Type": "application/json",
      },
      data: {
        data: {
          customer_name: data.customer_name,
          email: data.email,
          customer_address: data.customer_address,
          contact_number: data.contact_number,
        },
      },
    };

    return await axios(config)
      .then(function (res) {
        if (res.data.status) {
          notyf.success(res.data.message);
          return res.data.status;
        } else {
          notyf.error(res.data.message);
          return res.data.status;
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  const updateCustomer = async (auth_token, data, id) => {
    var config = {
      method: "put",
      url: backend_url + "/customers/" + id,
      headers: {
        Authorization: auth_token,
        "Content-Type": "application/json",
      },
      data: {
        data: {
          customer_name: data.customer_name,
          email: data.email,
          customer_address: data.customer_address,
          contact_number: data.contact_number,
        },
      },
    };

    return await axios(config)
      .then(function (res) {
        if (res.data.status) {
          notyf.success(res.data.message);
          return res.data.status;
        } else {
          notyf.error(res.data.message);
          return res.data.status;
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  const createItem = async (auth_token, data) => {
    var config = {
      method: "post",
      url: backend_url + "/items",
      headers: {
        Authorization: auth_token,
        "Content-Type": "application/json",
      },
      data: {
        data: {
          description: data.description,
          unit_cost: data.unit_cost,
        },
      },
    };

    return await axios(config)
      .then(function (res) {
        if (res.data.status) {
          notyf.success(res.data.message);
          return res.data.status;
        } else {
          notyf.error(res.data.message);
          return res.data.status;
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  const updateItem = async (auth_token, data, id) => {
    var config = {
      method: "put",
      url: backend_url + "/items/" + id,
      headers: {
        Authorization: auth_token,
        "Content-Type": "application/json",
      },
      data: {
        data: {
          description: data.description,
          unit_cost: data.unit_cost,
        },
      },
    };

    return await axios(config)
      .then(function (res) {
        if (res.data.status) {
          notyf.success(res.data.message);
          return res.data.status;
        } else {
          notyf.error(res.data.message);
          return res.data.status;
        }
      })
      .catch(function (error) {
        return error;
      });
  };

  const getPublicQuote = (id, qtoken) => {
    setLoading(true);
    var config = {
      method: "get",
      url: backend_url + `/quotations/${id}/view?qtoken=${qtoken}`,
    };

    axios(config)
      .then(function (res) {
        setLoading(false);
        console.log(JSON.stringify(res.data));
        if (res.data.status) {
          setPublicQuoteDetail(res.data.data);
        } else {
          // notyf.error(res.data.message)
        }
      })
      .catch(function (error) {
        setLoading(false);
        // notyf.error("Something went wrong")
        console.log(error);
      });
  };

  const acceptQuote = (id, qtoken) => {
    var config = {
      method: "post",
      url: backend_url + `/quotations/${id}/accept?qtoken=${qtoken}`,
    };

    axios(config)
      .then(function (res) {
        console.log(JSON.stringify(res.data));
        if (res.data.status) {
          notyf.success(res.data.message);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          notyf.error(res.data.message);
        }
      })
      .catch(function (error) {
        // notyf.error("Something went wrong")
        console.log(error);
      });
  };

  const rejectQuote = (id, qtoken, reason) => {
    var config = {
      method: "post",
      url: backend_url + `/quotations/${id}/reject?qtoken=${qtoken}`,
      data: {
        data: {
          reason: reason,
        },
      },
    };

    axios(config)
      .then(function (res) {
        console.log(JSON.stringify(res.data));
        if (res.data.status) {
          notyf.success(res.data.message);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          notyf.error(res.data.message);
        }
      })
      .catch(function (error) {
        // notyf.error("Something went wrong")
        console.log(error);
      });
  };

  return (
    <QuoteContext.Provider
      value={{
        nextTransactionNumber: nextTransactionNumber,
        getNextTransactionNumber: getNextTransactionNumber,
        customerQuotesList: customerQuotesList,
        updateDraftQuoteStatus: updateDraftQuoteStatus,
        editQuotation: editQuotation,
        resendQuote: resendQuote,
        sendQuotation: sendQuotation,
        setQuoteListDetails: setQuoteListDetails,
        allQuoteList: allQuoteList,
        localeCurrencyFormat: localeCurrencyFormat,
        localeCurrencySign: localeCurrencySign,
        setItems: setItems,
        getCustomers: getCustomers,
        setCustomers: setCustomers,
        customers: customers,
        getItems: getItems,
        items: items,
        updateItem: updateItem,
        createItem: createItem,
        getQuotes: getQuotes,
        quoteList: quoteList,
        updateQuoteStatus: updateQuoteStatus,
        archiveQuote: archiveQuote,
        restoreQuote: restoreQuote,
        setQuoteList: setQuoteList,
        getQuotesDetails: getQuotesDetails,
        quoteListDetails: quoteListDetails,
        getQuotesWithFilter: getQuotesWithFilter,
        getLatestQuotes: getLatestQuotes,
        latestQuoteList: latestQuoteList,
        loading: loading,
        setLoading: setLoading,
        getPublicQuote: getPublicQuote,
        publicQuoteDetail: publicQuoteDetail,
        deleteItem: deleteItem,
        getQuotesOfCustomer: getQuotesOfCustomer,
        createCustomer: createCustomer,
        updateCustomer: updateCustomer,
        acceptQuote: acceptQuote,
        rejectQuote: rejectQuote,
        getNotifications: getNotifications,
        notifications: notifications,
        setLatestQuoteList: setLatestQuoteList,
        setNotifications: setNotifications,
        sendMessage: sendMessage,
        getMessageList: getMessageList,
        messageList: messageList,
        selectedConversation: selectedConversation,
        setSelectedConversation: setSelectedConversation,
        getMessageConversationList: getMessageConversationList,
        messageConversationList: messageConversationList,
        sendMessageBusiness: sendMessageBusiness,
        getConversationListCustomer: getConversationListCustomer,
        getMessageListStatus: getMessageListStatus,
        setArchiveConversation: setArchiveConversation,
        getMessageListWithoutLoading: getMessageListWithoutLoading,
        getConversationListCustomerWithoutLoading: getConversationListCustomerWithoutLoading,
        getMessageConversationListWithoutLoading: getMessageConversationListWithoutLoading,
        filterMessage: filterMessage,
        setFilterMessage: setFilterMessage,
      }}
    >
      {props.children}
    </QuoteContext.Provider>
  );
};

export default QuoteProvider;

const localeCurrencyFormat = [
  { AUD: "en-AU" },
  { CAD: "en-CA" },
  { GBP: "en-GB" },
  { NZD: "en-NZ" },
  { USD: "en-US" },
];

const localeCurrencySign = (name) => {
  if (name == "AUD") {
    return <span>A$</span>;
  } else if (name == "CAD") {
    return <span>CA$</span>;
  } else if (name == "GBP") {
    return <span>&#163;</span>;
  } else if (name == "NZD") {
    return <span>NZ$</span>;
  } else {
    return <span>$</span>;
  }
};
