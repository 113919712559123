import React, {useContext} from "react";
import { Container, Row, Col} from "shards-react";

import PageTitle from "../../../components/common/PageTitle";
import RightBar from "../../../components/layout/RightBar";
import CreateQuoteForm from "../../../components/quotes-components/CreateQuoteForm";
import { UserContext } from '../../../providers/UserProvider';
import IMAGE_ASSETS from '../../../assets/image-list';

const CreateQuote = () => {
  const {user} = useContext(UserContext)

  if(user){

    return (
          <Container fluid className="main-content-container px-4">
          <Row noGutters className="page-header py-4">
            <PageTitle title="QuoteShark" subtitle="Create Quote" md="12" className="ml-sm-auto mr-sm-auto" />
          </Row>
          <Row>
            <Col>
              <CreateQuoteForm title="Create Quote" user={user} />
            </Col>
            {/* <Col lg="3">
              <RightBar />
            </Col> */}
          </Row>
        </Container>
      );
  }else{
    return (
      <Container>
      <p></p>
      </Container>
    );
  }
};

export default CreateQuote;
