import React, { useState, useContext, useEffect, useLayoutEffect } from "react";
import Avatar from "@mui/material/Avatar";
import { red } from "@mui/material/colors";

import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ImageIcon from "@mui/icons-material/Image";
import WorkIcon from "@mui/icons-material/Work";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import { UserContext } from "../../providers/UserProvider";
import { QuoteContext } from "../../providers/QuoteProvider";
import { firestoreDb } from "../../api/firebase";
import RefreshIcon from "@mui/icons-material/Refresh";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useLocation } from "react-router-dom";
import ChatIcon from "@mui/icons-material/Chat";

import {
  Container,
  Col,
  Card,
  CardHeader,
  CardFooter,
  Button,
  ListGroupItem,
  Row,
} from "shards-react";
import Modal from "react-bootstrap/Modal";
import { roundToNearestMinutesWithOptions } from "date-fns/fp";

const AdminMessageList = () => {
  const { user } = useContext(UserContext);
  const {
    messageList,
    getMessageList,
    setSelectedConversation,
    selectedConversation,
    getMessageConversationList,
    getMessageListStatus,
    getMessageListWithoutLoading,
    getMessageConversationListWithoutLoading,
    filterMessage,
    setFilterMessage,
  } = useContext(QuoteContext);
  const [openModal, setOpenModal] = useState(false);
  const [businessFirstLetter, setBusinessFirstLetter] = useState("");

  const [firebaseMessageList, setFirebaseMessageList] = useState([]);
  const [windowSize, setWindowSize] = useState(1300);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [filterStatus, setFilterStatus] = useState("all");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  const fetchFirebaseMessageList = async () => {
    const response = firestoreDb.collection("messages").doc(user.data.email);
    const data = await response.get();
    data.docs.forEach((item) => {
      firebaseMessageList([...firebaseMessageList, item.data()]);
    });
    console.log("Firebase MessageList", data);
  };

  useLayoutEffect(() => {
    function updateSize() {
      setWindowSize(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  // useEffect(() => {
  //   fetchFirebaseMessageList();
  // }, []);

  useEffect(() => {
    if (user.data.business_name) {
      setBusinessFirstLetter(user.data.business_name.charAt(0));
    }
    if (user) {
      getMessageList(user.data.auth_token);
    }
  }, []);

  // useEffect(() => {
  //   setInterval(() => {
  //     getMessageListWithoutLoading(user.data.auth_token);
  //   }, 10000);
  // }, []);

  // useEffect(() => {
  //   if (selectedConversation) {
  //     setInterval(() => {
  //       getMessageConversationListWithoutLoading(
  //         selectedConversation.quotation_id,
  //         selectedConversation.qToken,
  //         user.data.auth_token
  //       );
  //     }, 10000);
  //   }
  // }, [selectedConversation]);

  useEffect(() => {
    console.log("messageList", messageList);
  }, [messageList]);

  return (
    <>
      <Card className="m-4" style={{ padding: 15, marginTop: 10 }}>
        <CardHeader style={{ padding: 0 }}>
          <Row>
            <Col className="col-10">
              <select
                className="form-control"
                style={{
                  height: 45,
                  display: windowSize > 350 ? "none" : "block",
                }}
                onChange={(e) => {
                  setSelectedIndex(null);
                  setFilterMessage({
                    ...filterMessage,
                    status_id: e.target.value,
                  });
                  if (e.target.value == "12") {
                    getMessageListStatus(user.data.auth_token, "all");
                  } else if (e.target.value == "1") {
                    getMessageListStatus(user.data.auth_token, "unread");
                  } else {
                    getMessageListStatus(user.data.auth_token, "archived");
                  }
                }}
              >
                <option value="12">All</option>
                <option value="0">Unread</option>
                <option value="1">Archive</option>
              </select>

              <div
                className="p-1 mb-1"
                style={{
                  borderRadius: 50,
                  backgroundColor: "#E1E1E1",
                  maxWidth: 250,
                  display: windowSize < 350 ? "none" : "block",
                }}
              >
                <Button
                  style={{
                    height: 40,
                    width: 60,
                    fontSize: 10,
                    borderWidth: 0,
                    borderRadius: 50,
                    backgroundColor:
                      filterMessage.status_id == 12 ? "#fff" : "#E1E1E1",
                  }}
                  type="button"
                  onClick={() => {
                    setSelectedIndex(null);
                    setFilterMessage({ ...filterMessage, status_id: 12 });
                    getMessageListStatus(user.data.auth_token, "all");
                  }}
                >
                  <span
                    style={{
                      color:
                        filterMessage.status_id == 12 ? "black" : "#7F7F7F",
                    }}
                  >
                    All
                  </span>
                </Button>

                <Button
                  style={{
                    marginLeft: 3,
                    height: 40,
                    width: 70,
                    fontSize: 10,
                    borderWidth: 0,
                    borderRadius: 50,
                    backgroundColor:
                      filterMessage.status_id == 0 ? "#fff" : "#E1E1E1",
                  }}
                  type="button"
                  onClick={() => {
                    setSelectedIndex(null);
                    setFilterMessage({ ...filterMessage, status_id: 0 });
                    getMessageListStatus(user.data.auth_token, "unread");
                  }}
                >
                  <span
                    style={{
                      color: filterMessage.status_id == 0 ? "black" : "#7F7F7F",
                    }}
                  >
                    Unread
                  </span>
                </Button>

                <Button
                  style={{
                    marginLeft: 3,
                    height: 40,
                    width: 75,
                    fontSize: 10,
                    borderWidth: 0,
                    borderRadius: 50,
                    backgroundColor:
                      filterMessage.status_id == 1 ? "#fff" : "#E1E1E1",
                  }}
                  type="button"
                  onClick={() => {
                    setSelectedIndex(null);
                    setFilterMessage({ ...filterMessage, status_id: 1 });
                    getMessageListStatus(user.data.auth_token, "archived");
                  }}
                >
                  <span
                    style={{
                      color: filterMessage.status_id == 1 ? "black" : "#7F7F7F",
                    }}
                  >
                    Archived
                  </span>
                </Button>
              </div>
            </Col>
            <Col className="col-2">
              <IconButton onClick={handleClick} style={{ float: "right" }}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                // PaperProps={{
                //   style: {
                //     maxHeight: ITEM_HEIGHT * 4.5,
                //     width: '20ch',
                //   },
                // }}
              >
                <MenuItem
                  onClick={() => {
                    getMessageList(user.data.auth_token);
                    handleClose();
                  }}
                >
                  Refresh
                </MenuItem>
              </Menu>
            </Col>
          </Row>
        </CardHeader>
        <Divider />

        {messageList && (
          <List sx={{ width: "100%", maxHeight: 300, overflowY: "scroll" }}>
            {messageList &&
              messageList.map((message, index) => {
                var _bg;
                {
                  /* var _color = "#5A6169"; */
                }

                if (selectedIndex === index) {
                  _bg = "#F0F2FD";
                  {
                    /* _color = "#FFF"; */
                  }
                } else if (message.is_read_by_business_owner === true) {
                  _bg = "#FFF";
                } else {
                  _bg = "lightgrey";
                }

                return (
                  <>
                    <ListItemButton
                      key={"message" + index}
                      // selected={selectedIndex === index}
                      style={{
                        backgroundColor: _bg,
                      }}
                      onClick={(event) => {
                        handleListItemClick(event, index);
                        var selected = {
                          customer_name: message.customer_name,
                          quotation_id: message.quotation_id,
                          qToken: message.qtoken,
                          transaction_number: message.reference_number,
                        };
                        setSelectedConversation(selected);
                        getMessageConversationList(
                          message.quotation_id,
                          message.qtoken,
                          user.data.auth_token
                        );
                        getMessageList(user.data.auth_token);
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar sx={{ bgcolor: red[500] }}>
                          {message.customer_name.charAt(0)}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        // sx={{ color: _color }}
                        primary={`${message.customer_name} (${message.reference_number})`}
                        secondary={message.message}
                      />
                    </ListItemButton>
                  </>
                );
              })}
          </List>
        )}
      </Card>
    </>
  );
};

export default AdminMessageList;
