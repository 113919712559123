import React, { useState, useLayoutEffect } from "react";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Button,
  FormCheckbox,
  FormInput,
} from "shards-react";
import logo from "../../images/logo.png";
import IMAGE_ASSETS from "../../assets/image-list";
import Footer from "./Footer";
import { Link, useHistory } from "react-router-dom";
import { Jumbotron } from "react-bootstrap";

const LandingPage = () => {
  const history = useHistory();
  const [windowSize, setWindowSize] = useState();
  const [userDevice, setUserDevice] = useState();
  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth >= 1200) {
        setUserDevice("l");
      } else if (window.innerWidth < 1200 && window.innerWidth >= 760) {
        setUserDevice("m");
      } else {
        setUserDevice("s");
      }
      setWindowSize(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <div className="bg-white">
      <Row style={{ backgroundColor: "#F5F6F8", margin: 0 }}>
        <section
          className="container"
          style={{ paddingBottom: 50, backgroundColor: "#F5F6F8" }}
        >
          <Row className="mt-lg-5" style={{ marginLeft: 0 }}>
            <Col
              lg={6}
              className="order-lg-2 justify-content-center align-items-center"
              style={{ marginBottom: 30 }}
            >
              <img
                className=""
                src={IMAGE_ASSETS.src.banner}
                style={{ width: "100%" }}
              ></img>
            </Col>
            <Col lg={6} style={{}}>
              <div className="d-none d-xl-block">
                <p
                  style={{
                    fontSize: 50,
                    fontStyle: "normal",
                    fontWeight: 700,
                    color: "#007DC4",
                    lineHeight: "57px",
                  }}
                >
                  Send Professional Quotes for Free
                </p>
                <p
                  style={{
                    fontSize: 24,
                    color: "#000",
                    fontWeight: 300,
                    fontStyle: "normal",
                  }}
                >
                  <span>
                    {" "}
                    The world's simplest way to quote customers, from your phone
                    or laptop. Save time, stay organized and look professional!
                  </span>
                </p>
              </div>
              <div className="d-block d-xl-none">
                <p
                  style={{
                    fontSize: 38,
                    fontStyle: "normal",
                    fontWeight: 700,
                    color: "#007DC4",
                    lineHeight: "45px",
                  }}
                >
                  Send Professional Quotes for Free
                </p>
                <p
                  style={{
                    fontSize: 18,
                    color: "#000",
                    fontWeight: 300,
                    fontStyle: "normal",
                  }}
                >
                  <span>
                    {" "}
                    The world's simplest way to quote customers, from your phone
                    or laptop. Save time, stay organized and look professional!
                  </span>
                </p>
              </div>
              <div>
                <button
                  className="call-to-action-main"
                  onClick={() => {
                    history.push("/sign-up");
                  }}
                >
                  Sign Up Now
                </button>
              </div>
            </Col>
          </Row>
        </section>
      </Row>

      <section
        id="how-it-works"
        className="pt-4 pb-3"
        style={{ backgroundColor: "#6FEFC1" }}
      >
        <br />
        <div className="container">
          <h3 className="" style={{ color: "#000", fontWeight: 600 }}>
            Start using QuoteShark for Free
          </h3>
          <p
            style={{
              fontSize: 18,
              fontStyle: "normal",
              fontWeight: 300,
              color: "#000",
              lineHeight: "30px",
              maxWidth: 500,
            }}
          >
            The Easiest Quote Software You’ll Ever Use. Get Started for Free
            Today Send your first invoice right now (it's free!)
          </p>

          <div className="row">
            <div className="col-12 col-lg-4">
              <img
                className=""
                src={IMAGE_ASSETS.src.saveTime}
                style={{ width: 125, height: 123 }}
              ></img>
              <h4
                className=""
                style={{ color: "#000", fontWeight: 600, marginTop: 15 }}
              >
                SAVE TIME
              </h4>
              <p
                style={{
                  fontSize: 18,
                  fontStyle: "normal",
                  fontWeight: 300,
                  color: "#000",
                }}
              >
                Quickly create professional quotes and estimates wherever you
                are with a customer, between jobs, or at home. Convert estimates
                to quotes with one click.
              </p>
            </div>
            <div className="col-12 col-lg-4">
              <img
                className=""
                src={IMAGE_ASSETS.src.getPaid}
                style={{ width: 122, height: 122 }}
              ></img>
              <h4
                className=""
                style={{ color: "#000", fontWeight: 600, marginTop: 15 }}
              >
                GET PAID
              </h4>
              <p
                style={{
                  fontSize: 18,
                  fontStyle: "normal",
                  fontWeight: 300,
                  color: "#000",
                }}
              >
                Email, text or print your quotes. Get notified when quotes are
                read and stay on top of late payments. Accept credit card
                payments as well as cash, check and others.
              </p>
            </div>
            <div className="col-12 col-lg-4">
              <img
                className=""
                src={IMAGE_ASSETS.src.lookProfessional}
                style={{ width: 105, height: 126 }}
              ></img>
              <h4
                className=""
                style={{ color: "#000", fontWeight: 600, marginTop: 15 }}
              >
                LOOK PROFESSIONAL
              </h4>
              <p
                style={{
                  fontSize: 18,
                  fontStyle: "normal",
                  fontWeight: 300,
                  color: "#000",
                }}
              >
                Simply choose a quotes template and customize it with your logo.
                Add a signature, photos, notes and more. Always look
                professional and stay organized.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        id="quote-shark-advantage"
        className="pt-4 pb-3"
        style={{ backgroundColor: "#FFF" }}
      >
        <br />
        <div className="container">
          <h3
            className=""
            style={{ color: "#007DC4", fontWeight: 600, textAlign: "center" }}
          >
            The QuoteShark Advantage
          </h3>
          <p
            className="lead"
            style={{
              fontStyle: "normal",
              fontWeight: 300,
              color: "#000",
              // lineHeight: "30px",
              textAlign: "center",
            }}
          >
            How it works, First You have to sign up <br /> and it’s Free, Second
            Create Professional Quotes and Lastly,
            <br /> Send Quotes by email
          </p>

          <div className="row">
            <div className="col-3 col-md-2 col-lg-2">
              <p
                style={{
                  fontSize: userDevice == "l" ? 22 : 11,
                  fontStyle: "normal",
                  fontWeight: 300,
                  color: "#007DC4",
                  textAlign: "center",
                }}
              >
                Sign Up <br />
                for Free
              </p>
            </div>{" "}
            <div className="col">
              <img
                className=""
                src={IMAGE_ASSETS.src.process}
                style={{ width: "100%" }}
              ></img>
              <p
                style={{
                  fontSize: userDevice == "l" ? 22 : 11,
                  fontStyle: "normal",
                  fontWeight: 300,
                  color: "#007DC4",
                  textAlign: "center",
                }}
              >
                Send Quotes <br />
                by Email
              </p>
            </div>{" "}
            <div className="col-3 col-md-2 col-lg-2">
              <p
                style={{
                  fontSize: userDevice == "l" ? 22 : 11,
                  fontStyle: "normal",
                  fontWeight: 300,
                  color: "#007DC4",
                  textAlign: "center",
                }}
              >
                Create <br /> Professional <br /> Quotes
              </p>
            </div>
          </div>
        </div>
      </section>

      <section
        id="slant"
        className="pt-4 pb-3"
        style={{ backgroundColor: "#FFF" }}
      >
        {" "}
        <br />
        <div
          className="horizontal-background"
          style={{
            backgroundImage: `url("${IMAGE_ASSETS.src.horizontal1}")`,
          }}
        >
          <div
            className="container"
            style={{ paddingBottom: "13%", paddingTop: "8%" }}
          >
            <div className="row">
              <div className="col-12 col-md-6 col-lg-6">
                <img
                  className=""
                  src={IMAGE_ASSETS.src.quickAccess}
                  style={{ width: "100%" }}
                ></img>
              </div>
              <div
                className="col-12 col-md-6 col-lg-6"
                style={{ paddingTop: "10%" }}
              >
                <h3
                  className="lead"
                  style={{
                    color: "#fff",
                    fontWeight: 600,
                  }}
                >
                  Access Securely From Any Device, Anywhere
                </h3>
                <p
                  className="h6"
                  style={{
                    fontStyle: "normal",
                    fontWeight: 300,
                    color: "#fff",
                    lineHeight: "30px",
                  }}
                >
                  Do your quoting wherever suits you on your phone, tablet, or
                  computer. Your account is always connected and your quotes,
                  customers and settings are saved securely to our system. Never
                  lose business because of lost data. You’re free to switch
                  phones or devices any time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="explore"
        className="pt-4 pb-3"
        style={{ backgroundColor: "#fff" }}
      >
        <br />
        <div className="container">
          <h3 className="" style={{ color: "#007DC4", fontWeight: 600 }}>
            Explore Features QuoteShark App <br /> Create Estimates and Quotes
            Instantly
          </h3>
          <p
            style={{
              fontSize: 18,
              fontStyle: "normal",
              fontWeight: 300,
              color: "#000",
              lineHeight: "30px",
            }}
          >
            Fast, professional quotes on the go or at home
          </p>

          <div className="row">
            <div className="col-6 col-lg-3">
              <h6
                className=""
                style={{ color: "#000", fontWeight: 600, marginTop: 15 }}
              >
                Mobile Quoting
              </h6>
              <img
                className="img-fluid mb-2"
                src={IMAGE_ASSETS.src.mobileQuote}
                // style={{ width: 125, height: 123 }}
              ></img>
              <p
                style={{
                  fontSize: 18,
                  fontStyle: "normal",
                  fontWeight: 300,
                  color: "#000",
                }}
              >
                Send the quote before you leave the customer.
              </p>
            </div>
            <div className="col-6 col-lg-3">
              <h6
                className=""
                style={{ color: "#000", fontWeight: 600, marginTop: 15 }}
              >
                Estimate to Quote
              </h6>
              <img
                className="img-fluid mb-2"
                src={IMAGE_ASSETS.src.estimateQuote}
                // style={{ width: 122, height: 122 }}
              ></img>
              <p
                style={{
                  fontSize: 18,
                  fontStyle: "normal",
                  fontWeight: 300,
                  color: "#000",
                }}
              >
                Turn estimates to quotes with a single click.
              </p>
            </div>
            <div className="col-6 col-lg-3">
              <h6
                className=""
                style={{ color: "#000", fontWeight: 600, marginTop: 15 }}
              >
                Email, PDF, Print
              </h6>
              <img
                className="img-fluid mb-2"
                src={IMAGE_ASSETS.src.emailPdfPrint}
                // style={{ width: 105, height: 126 }}
              ></img>
              <p
                style={{
                  fontSize: 18,
                  fontStyle: "normal",
                  fontWeight: 300,
                  color: "#000",
                }}
              >
                Deliver your quotes via email, pdf, or print.
              </p>
            </div>
            <div className="col-6 col-lg-3">
              <h6
                className=""
                style={{ color: "#000", fontWeight: 600, marginTop: 15 }}
              >
                Partial Payments
              </h6>
              <img
                className="img-fluid mb-2"
                src={IMAGE_ASSETS.src.partialPayments}
                // style={{ height: 268 }}
              ></img>
              <p
                style={{
                  fontSize: 18,
                  fontStyle: "normal",
                  fontWeight: 300,
                  color: "#000",
                }}
              >
                Accept partial payments and take deposits.
              </p>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default LandingPage;
