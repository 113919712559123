import React, { useState, useContext, useEffect, useRef } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Avatar from "@mui/material/Avatar";
import { red } from "@mui/material/colors";

import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { UserContext } from "../../providers/UserProvider";
import { QuoteContext } from "../../providers/QuoteProvider";
import { firestoreDb } from "../../api/firebase";
import firebase from "firebase/app";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useLocation } from "react-router-dom";
import {
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  FormInput,
  Button,
} from "shards-react";
import moment from "moment";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function AdminMessageConversationWidget() {
  const { user } = useContext(UserContext);
  const {
    sendMessageBusiness,
    selectedConversation,
    messageConversationList,
    setArchiveConversation,
  } = useContext(QuoteContext);
  const [message, setMessage] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const query = useQuery();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  var messagesEndRef = useRef(null);
  var convoEnd = useRef();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const scrollToBottom = () => {
    messagesEndRef.scrollIntoView();
    convoEnd.current.scrollIntoView();
  };

  const messagesRef = firestoreDb
    .collection("messages")
    .doc(`${user.data.email}-${selectedConversation.quotation_id}`)
    .collection("conversation");

  const sendMessage = async () => {
    // const { uid, photoURL } = auth.currentUser;

    await messagesRef.add({
      message: message,
      createdAt: new Date().toLocaleString(),
      quotation_id: selectedConversation.quotation_id,
      is_customer: false,
    });
  };

  const queryFirestore = messagesRef.orderBy("createdAt").limitToLast(25);

  const [messagesFromFireStore] = useCollectionData(queryFirestore, {
    idField: "id",
  });

  useEffect(() => {
    try {
      scrollToBottom();
    } catch (e) {}
  }, [messagesFromFireStore]);
  // useEffect(() => {
  //   if (messagesFromFireStore) {
  //     console.log("messagesFromFireStore", messagesFromFireStore);
  //   }
  // }, []);

  const renderBusinessMessage = (
    index,
    message,
    created_at,
    customer = true
  ) => {
    if (!customer) {
      return (
        <ListItem key={"message" + index}>
          <ListItemText
            primary={
              <p
                style={{
                  margin: 0,
                  textAlign: "right",
                  paddingLeft: "20%",
                }}
              >
                <span
                  style={{
                    backgroundColor: "#5BC772",
                    padding: 7,
                    borderRadius: "15px 15px 0px 15px",
                    color: "white",
                    textAlign: "left",
                    display: "inline-block",
                  }}
                >
                  {message}
                </span>
                <br />
                <span
                  style={{
                    color: "#8A8B8C",
                    fontSize: 10,
                  }}
                >
                  {moment(created_at).fromNow()}
                </span>
              </p>
            }
          />
        </ListItem>
      );
    }
    return (
      <ListItem
        key={"message" + index}
        style={{
          width: "85%",
        }}
      >
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
            {selectedConversation.customer_name.charAt(0)}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <p
              style={{
                marginBottom: -15,
                textAlign: "left",
              }}
            >
              <span
                style={{
                  backgroundColor: "#8A8B8C",
                  padding: 7,
                  borderRadius: "0px 15px 15px 15px",
                  color: "white",
                  textAlign: "left",
                  display: "inline-block",
                }}
              >
                {message}
              </span>
              <br />
              <span
                style={{
                  color: "#8A8B8C",
                  fontSize: 10,
                }}
              >
                {moment(created_at).fromNow()}
              </span>
            </p>
          }
        />
      </ListItem>
    );
  };

  return (
    <Card className="m-4" style={{}}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
            {selectedConversation.customer_name.charAt(0)}
          </Avatar>
        }
        action={
          <>
            <IconButton onClick={handleClick} aria-label="settings">
              <MoreVertIcon style={{ color: "white" }} />
            </IconButton>

            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              // PaperProps={{
              //   style: {
              //     maxHeight: ITEM_HEIGHT * 4.5,
              //     width: '20ch',
              //   },
              // }}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  setArchiveConversation(
                    selectedConversation.quotation_id,
                    selectedConversation.qToken,
                    user.data.auth_token
                  );
                }}
              >
                Archive
              </MenuItem>
            </Menu>
          </>
        }
        title={
          <p
            style={{
              color: "#FFFFFF",
              fontSize: 15,
              fontWeight: 600,
              marginBottom: 0,
            }}
          >
            {`${selectedConversation.customer_name}`}
          </p>
        }
        subheader={
          <p
            style={{
              color: "#FFFFFF",
              fontSize: 15,
              fontWeight: 600,
              marginBottom: 0,
            }}
          >
            {`(${selectedConversation.transaction_number})`}
          </p>
        }
        className="bg-primary"
      />
      <Divider />
      <CardContent style={{ padding: 0 }}>
        <List
          style={{
            overflowY: "scroll",
            height: 300,
          }}
          sx={{ width: "100%", bgcolor: "background.paper" }}
        >
          {messagesFromFireStore &&
            messagesFromFireStore.map((msg) => {
              if (msg.is_customer) {
                return renderBusinessMessage(
                  msg.id,
                  msg.message,
                  msg.created_at,
                  true
                );
              } else {
                return renderBusinessMessage(
                  msg.id,
                  msg.message,
                  msg.created_at,
                  false
                );
              }
            })}

          <ListItem
            ref={(el) => {
              messagesEndRef = el;
            }}
          ></ListItem>
          <ListItem ref={convoEnd}></ListItem>
        </List>
      </CardContent>
      <CardActions disableSpacing>
        <InputGroup seamless>
          <FormInput
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key == "Enter") {
                e.defaultMuiPrevented = true;
                e.preventDefault();
                setMessage("");
                sendMessage();
                sendMessageBusiness(
                  selectedConversation.quotation_id,
                  selectedConversation.qToken,
                  message,
                  user.data.auth_token
                );
              }
            }}
            value={message}
            placeholder="Type Message"
            style={{ paddingTop: 10, paddingBottom: 10, paddingRight: "20%" }}
          />
          <InputGroupAddon type="append">
            <InputGroupText>
              <Button
                outline={true}
                theme="primary"
                onClick={() => {
                  if (message != "") {
                    console.log(message);
                    setMessage("");
                    sendMessage();
                    sendMessageBusiness(
                      selectedConversation.quotation_id,
                      selectedConversation.qToken,
                      message,
                      user.data.auth_token
                    );
                  }
                }}
              >
                Send
              </Button>
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
      </CardActions>
    </Card>
  );
}

export default AdminMessageConversationWidget;
