import React, {useEffect, useContext, useState} from 'react'
import { useFormikWizard } from 'formik-wizard'
import { UserContext } from '../../../providers/UserProvider';
import { QuoteContext } from '../../../providers/QuoteProvider';
import {
  Row,
  Col,
  Card,
} from "shards-react";
import moment from "moment"

function SummaryComponent() {
  const {user} = useContext(UserContext)
  const {localeCurrencyFormat} = useContext(QuoteContext)
  const { values } = useFormikWizard()
  const dateToday = new Date();
  const currencyFormatter = new Intl.NumberFormat(localeCurrencyFormat[user.data.currency_name ? user.data.currency_name : "en-US"], {
    style: 'currency',
    currency: user.data.currency_name ? user.data.currency_name : "USD",
  });

  useEffect(() => {
    console.log(values)
  }, [])
  return (
    <Row className="justify-content-center align-items-center">
      <Col lg={11}>
      <Card small className="mb-4 mt-5">
            {user.data && <Row style={{border: '1px solid gray', padding: 5, backgroundColor: '#fff'}}>
              <Col lg={12} className="pt-4">
              <span className="" style={{fontWeight: 600}}><i className="fa fa-building" aria-hidden="true"></i> {user.data.business_name}<br /></span>
                    <span className=""><i className="fa fa-user" aria-hidden="true"></i> {user.data.name}</span><br />
                    {user.data.contact_number && <span className=""><i className="fa fa-phone" aria-hidden="true"></i> {user.data.contact_number}<br /></span>}
                    <span className=""><i className="fa fa-envelope" aria-hidden="true"></i> {user.data.email}</span><br />
                    {user.data.business_address && <span className=""><i className="fa fa-map-pin" aria-hidden="true"></i> {user.data.business_address}<br /></span>}
              </Col>

              <Col lg={12} className="bg-primary" style={{marginTop: 20, height: 30}}>
                  <span>&nbsp;</span>
                  <span style={{fontSize: 40, fontWeight: 700, backgroundColor: "#FFF", color: "#404144", position: "absolute", right: 130, top: -15, paddingLeft: 9, paddingRight: 9}}>QUOTE</span>
              </Col>


              <Col lg={8} className="pt-lg-4 pt-2">
                <span style={{fontWeight: 600, fontSize: 16}}>Quote To:</span><br />
                <span style={{fontWeight: 600}}><i className="fa fa-user" aria-hidden="true"></i> {values.customerDetails.name}</span><br />
                {values.customerDetails.contact_number && <span className=""><i className="fa fa-phone" aria-hidden="true"></i> {values.customerDetails.contact_number}<br /></span>}
                <span><i className="fa fa-envelope" aria-hidden="true"></i> {values.customerDetails.email}</span><br />
                {values.customerDetails.address && <span><i className="fa fa-map-pin" aria-hidden="true"></i> {values.customerDetails.address}<br /></span>}
              </Col>
              <Col lg={4} className="pt-4"><br />
              <span style={{fontWeight: 'bold'}}>Ref #:</span><span> {values.customerDetails.referenceNumber}</span><br />
              <span style={{fontWeight: 'bold'}}>Quote Date:</span><span> {moment(dateToday).format(user.data.date_format_name ? user.data.date_format_name : "DD/MM/YYYY")}</span><br />
              <span style={{fontWeight: 'bold'}}>Expiry Date:</span><span> {moment(dateToday).add('days',values.termsAndCondition.expire_in_days).format(user.data.date_format_name ? user.data.date_format_name : "DD/MM/YYYY")}</span><br />
              </Col>

              <Col lg={12} className="pt-3">
                  <table className="custom-table w-100" style={{border: "1px solid #404144"}}>
                    <thead>
                      <tr style={{backgroundColor: "#404144", color: 'white'}}>
                        <th colSpan="1">#</th>
                        <th colSpan="5">Description</th>
                        <th colSpan="1"><p className="text-right  m-0">Unit Cost</p></th>
                      </tr>
                    </thead>
                    <tbody>
                      {values.itemStep && values.itemStep.lineItems.map((lineItem, index) => {
                        return  (<tr key={"item"+lineItem.id}>
                              <td colSpan="1">{index + 1}</td>
                              <td colSpan="5">{lineItem.description}</td>
                              <td colSpan="1"><p className="text-right  m-0">{currencyFormatter.format(Number(lineItem.unit_cost))}</p></td>
                            </tr>)
                      })}
                    </tbody>
                  </table>
                </Col>
                <Col lg={8}>

                </Col>
                <Col lg={4}>
                  {user.data.is_vat && <div>
                    <Row>
                    <Col>
                    <span className="text-center text-semibold" style={{}}>Subtotal</span>
                    </Col>
                    <Col>
                    <span className="float-right" style={{marginRight: 12}}>{currencyFormatter.format(Number(values.itemStep.subtotal))}</span>
                    </Col>
                  </Row>
                  <hr style={{marginTop: 8, marginBottom: 10}} />
                  <Row>
                    <Col>
                    <span className="text-center text-semibold" style={{}}>{user.data.tax_label_name ? user.data.tax_label_name: "VAT"}({user.data.is_vat ? parseInt(user.data.vat_rate) : 0}%)</span>
                    </Col>
                    <Col>
                    <span className="float-right" style={{marginRight: 12}}>{currencyFormatter.format(Number(values.itemStep.vat))}</span>
                    </Col>
                  </Row>
                  </div>}
                  <Row className="bg-primary" style={{paddingTop: 10, paddingBottom: 10, marginLeft: 1, marginRight: 1, marginTop: 10}}>
                    <Col>
                    <span className="text-center text-semibold" style={{fontWeight: 600, color: 'white'}}>Total</span>
                    </Col>
                    <Col>
                    <span className="float-right" style={{fontWeight: 600, color: 'white'}}>{currencyFormatter.format(Number(values.itemStep.total))}</span>
                    </Col>
                  </Row>
                </Col>

                <Col lg={12} className="pt-4">
                      <span className="" style={{fontWeight: 600}}><u>TERMS AND CONDITIONS</u></span><br /><br />
                      <span className="">Quote to expire in: <b>{values.termsAndCondition.expire_in_days} Days</b> </span><br />
                      <span className="">Is a deposit required? <b>{values.termsAndCondition.terms_is_deposit_required === "true" ? "Required" : "Not Required"}</b></span><br />
                      {values.termsAndCondition.terms_is_deposit_required === "true" ? (
                        <div>
                          <span className="">Deposit Method: <b>{values.termsAndCondition.terms_is_deposit_fixed === "true" ? "Fixed Deposit" : "Percentage Deposit"}</b><br /></span>
                          {values.termsAndCondition.terms_is_deposit_fixed === "true" ? (<span className="">Deposit Amount: <b>{currencyFormatter.format(Number(values.termsAndCondition.terms_deposit_amount))}</b><br /></span>) : (<span className="">Deposit Percentage: <b>{values.termsAndCondition.terms_deposit_percentage}%</b><br /></span>)}
                        </div>
                      ) : null}
                </Col>


                <Col className="mb-5">
                  {values.termsAndCondition.terms_other_conditions ? (<Row>
                    <Col lg={12} className="pt-4">
                          <span className="text-semibold ">Other conditions</span><br />
                          <span className="">{values.termsAndCondition.terms_other_conditions}</span><br />
                    </Col>
                  </Row>) : null}
                </Col>
            </Row>}
            </Card>
      </Col>
    </Row>
  )
}

export default SummaryComponent
