import React, {useEffect, useContext} from "react";
import PropTypes from "prop-types";
import { Container, Col,
  Card,
  CardHeader,
  CardFooter,
  Button,
  ListGroupItem, Row} from "shards-react";
  import { Link, useHistory} from "react-router-dom";
  import InfoIcon from '@mui/icons-material/Info';
  import WarningIcon from '@mui/icons-material/Warning';
  import DraftsIcon from '@mui/icons-material/Drafts';
  import CancelIcon from '@mui/icons-material/Cancel';
  import CheckCircleIcon from '@mui/icons-material/CheckCircle';
  import EventBusyIcon from '@mui/icons-material/EventBusy';


import { orange } from '@mui/material/colors';


export default function LatestQuotes({latestQuoteList, getQuotesDetails, user, localeCurrencyFormat}) {
  const history = useHistory();

  const currencyFormatter = new Intl.NumberFormat(localeCurrencyFormat[user && user.data.currency_name ? user.data.currency_name : "en-US"], {
    style: 'currency',
    currency: user && user.data.currency_name ? user.data.currency_name : "USD",
  });


  return (
        <Row>
          <Col lg={7} md={7} sm={12} data-testid="cardLatestQuote">
            <Card className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Latest Quotes</h6>
              </CardHeader>
              <div className="table-responsive">
              <table className="table table-hover table-striped">
                {/* <thead>
                  <tr>
                    <th>Reference#</th>
                    <th>Status</th>
                    <th>Job Cost</th>
                  </tr>
                </thead> */}
                <tbody>
                  {latestQuoteList && latestQuoteList.map((quote, index) => {
                    return (
                      <tr key={index}>
                      <td><Link style={{marginLeft:18}} to={"/quotes/show/" + quote.transaction_number } onClick={() => {
                        getQuotesDetails(user.data.auth_token, quote.id);
                        localStorage.setItem("showQuoteDetails", JSON.stringify(quote.id))
                      }}> {quote.transaction_number}</Link></td>
                        <td>{quote.status}</td>
                        <td>{currencyFormatter.format(Number(quote.total || 0))}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
              </div>

            <CardFooter >
              <Button data-testid="buttonQuoteList" theme="accent" style={{width: 150, height: 45}} type="button" onClick={() => {
                    history.push("/quotes/list")
                  }}> View More Quotes</Button>
            </CardFooter>
            </Card>
          </Col>

          <Col lg={5} md={5} sm={12} className="d-block d-md-none d-lg-none d-xl-none"  >
             <Card className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Reach Out</h6>
              </CardHeader>
              <div style={{padding: "15px 31px"}}>
              <p className="m-0">
                  Need Help? Please Email <a className="">support@quoteshark.co</a>
                </p>
              </div>
            </Card>
          </Col>
        </Row>
  )
}


