import React, { useEffect, useContext} from 'react'
import { FastField, useFormikContext } from 'formik'
import {  Row, Col} from 'shards-react'
import TextField from '@mui/material/TextField';
import CustomerSmartSearch from './widgets/CustomerSmartSearch'
import Autocomplete from '@mui/material/Autocomplete';
import { QuoteContext } from '../../../providers/QuoteProvider';
import { UserContext } from '../../../providers/UserProvider';


function CustomerDetailsComponent() {
  const { errors, touched, values, setFieldValue } = useFormikContext()
  const {getCustomers, customers, getQuotes, quoteList, getNextTransactionNumber, nextTransactionNumber} = useContext(QuoteContext)
  const {user} = useContext(UserContext)

  useEffect(() => {
    getNextTransactionNumber(user.data.auth_token)
    getCustomers(user.data.auth_token)
    setFieldValue("auth_token", user.data.auth_token)
  }, [])

  useEffect(() => {
    console.log("from on action save draftQuote")
    localStorage.setItem("draftQuoteCustomer", JSON.stringify(values))
  }, [values])

  useEffect(() => {
   if(nextTransactionNumber){
      setFieldValue("referenceNumber", nextTransactionNumber)
   }
  }, [nextTransactionNumber])


  return (
    <div className="w-100">
          <Row>
            <Col lg="6" md="12">
                <Row>
                  <Col lg={6} md={6} sm={12} xs={12}>
                  <input type="hidden" value="something"/>
                    <div className="form-group">
                    <TextField  id="referenceNumber" label="Reference Number" disabled  className="form-control"  variant="outlined" value={values.referenceNumber} onChange={(e) => {setFieldValue("referenceNumber", e.target.value)}}/>
                    {/* <small style={{ color: 'red' }}>
                      {touched.referenceNumber && errors.referenceNumber}
                    </small> */}
                  </div>
                  </Col>
                </Row>
                <div className="form-group">
                  {/* <label htmlFor="name">Name* </label> */}
                  {/* <CustomerAutosuggets setFieldValue={setFieldValue} customers={customers} value={values.name ? values.name : ""} /> */}
                  <FastField id="name" name="auth_token" value={user.data.auth_token} readOnly={true} hidden={true}/>

                  <input type="hidden" value="something"/>
                  <CustomerSmartSearch   setFieldValue={setFieldValue} customers={customers} value={values.name ? values.name : ""} />

                  {/* <ErrorMessage name="name" component="div" className="invalid-feedback" /> */}
                  {/* <FastField name="name" id="name" className="form-control" /> */}
                  <small style={{ color: 'red' }}>
                    {touched.name && errors.name}
                  </small>
                </div>
                <div className="form-group">
                  {/* <label htmlFor="email">Email* </label> */}
                 <input type="hidden" value="something"/>
                  <TextField  autoComplete="email"  id="email" label={<span>Email<span className="text-danger"> *</span></span>}  className="form-control"  variant="outlined" value={values.email} onChange={(e) => {
                    setFieldValue("email", e.target.value)
                    setFieldValue("customer_id", 0)
                    }}/>
                  {/* <FastField name="email" id="email" className="form-control" /> */}

                  {/* <ErrorMessage name="email" component="div" className="invalid-feedback" /> */}
                  <small style={{ color: 'red' }}>
                    {touched.email && errors.email}
                  </small>
                </div>
                <div className="form-group">
                  {/* <label htmlFor="address">Address* </label> */}
                   <input type="hidden" value="something"/>
                  <TextField  autoComplete="contact_number"  id="contact_number" label="Phone"  className="form-control" variant="outlined" value={values.contact_number} onChange={(e) => {
                    setFieldValue("contact_number", e.target.value)
                    setFieldValue("customer_id", 0)
                    }} />
                  <small style={{ color: 'red' }}>
                    {touched.address && errors.address}
                  </small>
                </div>
                <div className="form-group">
                  {/* <label htmlFor="address">Address* </label> */}
                   <input type="hidden" value="something"/>
                  <TextField  autoComplete="address"  id="address" label="Address"  className="form-control" variant="outlined" value={values.address} onChange={(e) => {
                    setFieldValue("address", e.target.value)
                    setFieldValue("customer_id", 0)
                    }} />
                  <small style={{ color: 'red' }}>
                    {touched.address && errors.address}
                  </small>
                </div>
            </Col>
          </Row>
    </div>
  )
}

export default CustomerDetailsComponent
