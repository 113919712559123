import React, {useContext ,useState, useEffect} from "react";
import { UserContext } from '../../../providers/UserProvider';
import { QuoteContext } from '../../../providers/QuoteProvider';
import PageTitle from "../../../components/common/PageTitle";
import makeStyles from '@mui/styles/makeStyles';
import { Input, AccordionDetails, AccordionSummary, Accordion, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from "prop-types";
import { Link, useHistory} from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import CreateIcon from '@mui/icons-material/Create';
import Icon from '@mui/material/Icon';
import { Spinner } from "react-bootstrap";
import { DataGrid } from '@mui/x-data-grid';
import DataTable, { createTheme } from 'react-data-table-component';
import LinearProgress from '@mui/material/LinearProgress';
import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Container,
  FormCheckbox,
  Button
} from "shards-react";
import NotyfContext from '../../../providers/NotificationContext';
import moment from "moment";


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    color: '#7F7F7F',
    width: "100%",
  },
}));

const LinearIndeterminate = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LinearProgress />
    </div>
  );
};


const ListQuotes = () => {
  const {user, changePassword} = useContext(UserContext)
  const {quoteListDetails, getQuotes, allQuoteList, quoteList, getQuotesWithFilter, getCustomers, customers, loading, getQuotesDetails, setQuoteList, updateQuoteStatus, archiveQuote, restoreQuote, localeCurrencyFormat} = useContext(QuoteContext)
  const [customer, setCustomer] = useState(null)
  const [allQuotes, setAllQuotes] = useState([])
  const [searchString, setSearchString] = useState(null)
  const [isChecked, setIsChecked] = useState(false)
  const [statusCount, setStatusCount] = useState({
    all: 0,
    draft: 0,
    sent: 0,
    read: 0,
    rejected: 0,
    accepted: 0,
  })
  const dateToday = new Date();
  const currentDate = moment().startOf('day');
  const history = useHistory();
  const [filter, setFilter] = useState({
    status_id: 12,
    customer: "",
    expiry_from: "",
    expiry_to: "",
    archived: 0,
  })
  const [filterStatus, setFilterStatus] = useState("all")
  const [showAdvanceSearch, setShowAdvanceSearch] = useState(false)
  const [sendDisabled, setSendDisabled] = useState(false)

  const notyf = useContext(NotyfContext);
  const classes = useStyles();

  const currencyFormatter = new Intl.NumberFormat(localeCurrencyFormat[user.data.currency_name ? user.data.currency_name : "en-US"], {
    style: 'currency',
    currency: user.data.currency_name ? user.data.currency_name : "USD",
  });

  // const _updateStatus = async (quote) => {
  //   var _quoteListDetails = await getQuotesDetails(user.data.auth_token, quote.id);
  //   console.log("_quoteListDetails", _quoteListDetails)
  // }

  const searchValue = (value = "") => {
    var newData = [];
    value = value.toLowerCase()
    if(allQuotes.length == 0){
      setAllQuotes(quoteList);
      newData = quoteList.filter(function(data) {
        return data.transaction_number.toLowerCase().search(value) !== -1 || data.customer_name.toLowerCase().search(value) !== -1|| data.total.toLowerCase().search(value) !== -1
      });
    }else{
      newData = allQuotes.filter(function(data) {
        return data.transaction_number.toLowerCase().search(value) !== -1 || data.customer_name.toLowerCase().search(value) !== -1|| data.total.toLowerCase().search(value) !== -1
      });
    }
    console.log(newData)
    if(newData.length > 0){
      setQuoteList(newData)
    }
  }

  const getStatus = () => {
    if(filter.status_id === 1){
      setFilterStatus("Sent")
    }else if(filter.status_id === 2){
      setFilterStatus("Read")
    }else if(filter.status_id === 3){
      setFilterStatus("Rejected")
    }else if(filter.status_id === 4){
      setFilterStatus("Accepted")
    }else if(filter.status_id === 5){
      setFilterStatus("Archived")
    }
  }
  const recountStatus = () => {
    if(quoteList){
      if(filter.status_id == 1){
        setStatusCount({ ...statusCount, sent: 0});
        quoteList.forEach((quote) => {
          if(quote.status_id == 1){
            setStatusCount((prevState) => {
              return { ...prevState, sent: prevState.sent + 1}
            });
          }
        });
      }else if(filter.status_id == 2){
        setStatusCount({ ...statusCount, read: 0});
        quoteList.forEach((quote) => {
          if(quote.status_id == 2){
            setStatusCount((prevState) => {
              return { ...prevState, read: prevState.read + 1}
            });
          }
        });
      }else if(filter.status_id == 3){
        setStatusCount({ ...statusCount, rejected: 0});
        quoteList.forEach((quote) => {
          if(quote.status_id == 3){
            setStatusCount((prevState) => {
              return { ...prevState, rejected: prevState.rejected + 1}
            });
          }
        });
      }else if(filter.status_id == 4){
        setStatusCount({ ...statusCount, accepted: 0});
        quoteList.forEach((quote) => {
          if(quote.status_id == 4){
            setStatusCount((prevState) => {
              return { ...prevState, accepted: prevState.accepted + 1}
            });
          }
        });
      }else if(filter.status_id == 0){
        setStatusCount({ ...statusCount, draft: 0});
        quoteList.forEach((quote) => {
          if(quote.status_id == 0){
            setStatusCount((prevState) => {
              return { ...prevState, draft: prevState.draft + 1}
            });
          }
        });
      }else if(filter.status_id == 12){
        setStatusCount((prevState) => {
          return { ...prevState, all: quoteList.length}
        });
      }

      console.log("statusCount", statusCount)

    }
  }

  useEffect(() => {
    if(user.data && user.data.auth_token){
      if(allQuotes.length == 0 || quoteList.length == 0){
        getQuotes(user.data.auth_token);
      }
      if(!customers || customers.length == 0){
        getCustomers(user.data.auth_token);
      }
    }
  },[])

  useEffect(() => {
    if(quoteList){
     recountStatus();
    }
  },[quoteList])

  useEffect(() => {
    setStatusCount({
      all: 0,
      draft: 0,
      sent: 0,
      read: 0,
      rejected: 0,
      accepted: 0,
    })
    console.log("allQuoteList", allQuoteList)
    if(allQuoteList){
      setStatusCount((prevState) => {
        return { ...prevState, all: allQuoteList.length}
      })
      allQuoteList.forEach((quote, index) => {
        console.log("quote " + index, quote)
        if(quote.status_id == 1){
          setStatusCount((prevState) => {
            return { ...prevState, sent: prevState.sent + 1}
          })
        }else if(quote.status_id == 2){
          setStatusCount((prevState) => {
            return { ...prevState, read: prevState.read + 1}
          })
        }else if(quote.status_id == 3){
          setStatusCount((prevState) => {
            return { ...prevState, rejected: prevState.rejected + 1}
          })
        }else if(quote.status_id == 0){
          setStatusCount((prevState) => {
            return { ...prevState, draft: prevState.draft + 1}
          })
        }else{
          setStatusCount((prevState) => {
            return { ...prevState, accepted: prevState.accepted + 1}
          })
        }
      })
      console.log("statusCount", statusCount)
    }
  },[allQuoteList])

  useEffect(() => {
   if(user.data && user.data.auth_token){
     console.log(filter)
    //  getStatus()
    getQuotesWithFilter(user.data.auth_token, filter);
   }
  },[filter])

  useEffect(() => {
   if(isChecked){
    setFilter({ ...filter, archived: 1})

   }else{
    setFilter({ ...filter, archived: 0})
   }
  },[isChecked])


  const RenderReference = ({ row }) =>  {
    return  <Link to={"/quotes/show/" + row.transaction_number } onClick={() => {
      getQuotesDetails(user.data.auth_token, row.id);
      localStorage.setItem("showQuoteDetails", JSON.stringify(row.id))
    }}>{row.transaction_number}</Link>
  }

  const RenderName = ({ row }) =>  {
    return <span>{row.customer_name}</span>
  }

  const RenderJobCost = ({ row }) =>  {
    return <span>{ currencyFormatter.format(Number(row.total || 0))}</span>
  }

  const RenderDeadline = ({ row }) =>  {
    if(row.expiration_date){
      return <span>{moment(row.expiration_date).format(user.data.date_format_name ? user.data.date_format_name : "DD/MM/YYYY")} ({moment(row.expiration_date).fromNow()})</span>
    }else{
      return <span>{moment(moment(currentDate, "DD-MM-YYYY").add(user.data.quote_expire_in_days, 'days')).format(user.data.date_format_name ? user.data.date_format_name : "DD/MM/YYYY")} ({moment(moment(currentDate, "DD-MM-YYYY").add(user.data.quote_expire_in_days, 'days')).fromNow()})</span>
    }

  }

  const RenderCreated = ({ row }) =>  {
    return <span>{row.status_id == 0 ? "Saved" : "Quoted"} {row.status_id == 0 ? moment(row.created_at).fromNow() : moment(row.created_at).fromNow()}</span>
  }
  const RenderStatus = ({ quote }) =>  {
    return <select defaultValue={quote.status_id} className="form-select form-select-lg" aria-label=".form-select-lg example" style={{padding: 6, marginTop: -4}}  onChange={(e) => {
      updateQuoteStatus(user.data.auth_token, quote.id, e.target.value)
    }}>
        <option style={{fontSize: 13}} value={0} disabled>Draft</option>
        <option style={{fontSize: 13}} value={1} disabled>Sent</option>
        <option style={{fontSize: 13}} value={2} disabled>Read</option>
        <option style={{fontSize: 13}} value={3} disabled={quote.status_id == 0 ? true : false}>Rejected</option>
        <option style={{fontSize: 13}} value={4} disabled={quote.status_id == 0 ? true : false}>Accepted</option>
  </select>
  }
  const RenderArchiveButton = ({ quote }) =>  {
    return <div style={{width: 100}}>
       {quote.is_archived === false? (
          <IconButton aria-label="delete" style={{marginTop: -10}} onClick={() => {
              archiveQuote(user.data.auth_token, quote.id)
          }}>
            <DeleteIcon color="error" style={{ fontSize: 17 }} />
          </IconButton>) : (
          <IconButton aria-label="success" style={{marginTop: -10}} onClick={() => {
              restoreQuote(user.data.auth_token, quote.id)
          }}>
            <RefreshIcon color="success" style={{ fontSize: 17, color: "#5CC864" }} />
          </IconButton>)}
    </div>
  }

  const columns = [
    { name: 'Ref#', maxWidth: '50px', selector: row => row.transaction_number, cell: row => <RenderReference row={row} />, sortable: true,  },
    { name: 'Customer', maxWidth: '450px', selector: row => row.customer_name, cell: row => <RenderName row={row} />, sortable: true, },
    { name: 'Job Cost', maxWidth: '100px', selector: row => row.total, cell: row => <RenderJobCost row={row} />, sortable: true, },
    { name: 'Deadline', selector: row => row.expiration_date, cell: row => <RenderDeadline row={row} />, sortable: true, },
    { name: 'Created', selector: row => row.created_at, cell: row => <RenderCreated row={row} />, sortable: true, },
    { name: 'Status', maxWidth: '100px', selector: row => row.status_id, cell: row => <RenderStatus quote={row} />, sortable: true, },
    { name: '', maxWidth: '50px', cell: row => <RenderArchiveButton quote={row} />},
  ];

  return (
    <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title="QuoteShark" subtitle="Quote List" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>

    <Row  style={{marginLeft: 2, marginRight: 2, marginBottom: 2}}  className="d-none d-xl-block d-lg-block">
      <Col lg={12}>
        <Row>
        <div className="p-1 mb-1" style={{borderRadius : 50, backgroundColor: "#E1E1E1"}}>

          <Button style={{height: 40, width: 60, fontSize: 10 , borderWidth: 0, borderRadius: 50, backgroundColor: filter.status_id == 12 ? "#fff" : "#E1E1E1"}}  type="button" onClick={() => {
            setFilter({ ...filter, status_id: 12})
          }} > <span style={{color: filter.status_id == 12 ? "black" : "#7F7F7F"}}><span style={{color: filter.status_id == 12 ? "#167AF6" : "#7F7F7F"}}>{statusCount.all}</span> All</span> </Button>

           {statusCount.draft > 0 ? (<Button style={{marginLeft: 3, height: 40, width: 65, fontSize: 10 , borderWidth: 0, borderRadius: 50, backgroundColor: filter.status_id == 0 ? "#fff" : "#E1E1E1"}}  type="button" onClick={() => {
            setFilter({ ...filter, status_id: 0})
          }} > <span style={{color: filter.status_id == 0 ? "black" : "#7F7F7F"}}><span style={{color: filter.status_id == 0 ? "#167AF6" : "#7F7F7F"}}>{statusCount.draft}</span> Draft</span>  </Button>) : null}

          {statusCount.sent > 0 ? (<Button style={{marginLeft: 3, height: 40, width: 70, fontSize: 10 , borderWidth: 0, borderRadius: 50, backgroundColor: filter.status_id == 1 ? "#fff" : "#E1E1E1"}}  type="button" onClick={() => {
            setFilter({ ...filter, status_id: 1})
          }} > <span style={{color: filter.status_id == 1 ? "black" : "#7F7F7F"}}><span style={{color: filter.status_id == 1 ? "#167AF6" : "#7F7F7F"}}>{statusCount.sent}</span> Sent</span>  </Button>) : null}

          {statusCount.read > 0 ? (<Button style={{marginLeft: 3, height: 40, width: 70, fontSize: 10 , borderWidth: 0, borderRadius: 50, backgroundColor: filter.status_id == 2 ? "#fff" : "#E1E1E1"}}  type="button" onClick={() => {
            setFilter({ ...filter, status_id: 2})
          }} > <span style={{color: filter.status_id == 2 ? "black" : "#7F7F7F"}}><span style={{color: filter.status_id == 2 ? "#167AF6" : "#7F7F7F"}}>{statusCount.read}</span> Read</span>  </Button>) : null}

          {statusCount.rejected > 0 ? (<Button style={{marginLeft: 3, height: 40, width: 85, fontSize: 10 , borderWidth: 0, borderRadius: 50, backgroundColor: filter.status_id == 3 ? "#fff" : "#E1E1E1"}}  type="button" onClick={() => {
            setFilter({ ...filter, status_id: 3})
          }} > <span style={{color: filter.status_id == 3 ? "black" : "#7F7F7F", marginLeft: -3}}><span style={{color: filter.status_id == 3 ? "#167AF6" : "#7F7F7F"}}>{statusCount.rejected}</span> Rejected</span>  </Button>) : null}

          {statusCount.accepted > 0 ? (<Button style={{marginLeft: 3, height: 40, width: 85, fontSize: 10 , borderWidth: 0, borderRadius: 50, backgroundColor: filter.status_id == 4 ? "#fff" : "#E1E1E1"}}  type="button" onClick={() => {
            setFilter({ ...filter, status_id: 4})
          }} > <span style={{color: filter.status_id == 4 ? "black" : "#7F7F7F", marginLeft: -3}}><span style={{color: filter.status_id == 4 ? "#167AF6" : "#7F7F7F"}}>{statusCount.accepted}</span> Accepted</span>  </Button>) : null}

        </div>

        <div style={{paddingTop: 12, paddingLeft: 35}}>
          <FormCheckbox
                      id="show_archive"
                      name="show_archive"
                      value={isChecked}
                      checked={isChecked}
                      onChange={(e) => {
                        setIsChecked(!isChecked)
                      }}
                    >Include Archived
          </FormCheckbox>
        </div>

        <div style={{paddingTop: 12}}>
            <span className="text-primary" style={{ cursor: "pointer", marginLeft: 12, marginTop: 11}} onClick={() => {
              setShowAdvanceSearch(!showAdvanceSearch)
            }}><u>Advanced Search</u></span>
        </div>

        <div style={{width: 250}}>
          <Row className="" style={{marginLeft: 5}}>
            <input  style={{height: 45, width: "79%"}} placeholder="Search quote detail"  className="form-control"  value={searchString ? searchString : ""} onChange={(e) => {setSearchString(e.target.value)}}
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  searchValue(searchString)
                }
              }}/>
            <IconButton aria-label="SearcIcon" style={{fontSize: 19, height: 40, width: "19%", marginTop: 2, marginLeft: 2}} onClick={() => {
                  searchValue(searchString)
                }}>
                  <SearchIcon color="primary" style={{ fontSize: 36 }} />
              </IconButton>
            </Row>
        </div>

        </Row>
      </Col>
    </Row>

    <Row>
      <Col  className="d-xs-block d-sm-block d-md-block d-lg-none d-xl-none">
          <Row>
            <Col className="col-6" style={{paddingTop: 3}}>
              <span className="text-primary" style={{ cursor: "pointer", marginLeft: 45}} onClick={() => {
                setShowAdvanceSearch(!showAdvanceSearch)
              }}><u>Advanced Search</u></span>
            </Col>
            <Col className="col-6">
                  <FormCheckbox
                    id="show_archive"
                    name="show_archive"
                    value={isChecked}
                    checked={isChecked}
                    onChange={(e) => {
                      setIsChecked(!isChecked)
                    }}
                  >
                  Include Archive
                  </FormCheckbox>
            </Col>
            <Col lg={12}>
            <Row style={{marginLeft: 0, marginBottom: 10}}>
              <input  style={{height: 45, width: "85%"}} placeholder="Search quote detail"  className="form-control"  value={searchString ? searchString : ""} onChange={(e) => {setSearchString(e.target.value)}}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                searchValue(searchString)
              }
            }}
            />

              <IconButton aria-label="SearcIcon" style={{fontSize: 19, height: 40, width: "10%", marginTop: 2, marginLeft: 2}} onClick={() => {
                    searchValue(searchString)
                  }}>
                    <SearchIcon color="primary" style={{ fontSize: 36 }} />
                </IconButton>
            </Row>
            </Col>
          </Row>
      </Col>
    </Row>

    {showAdvanceSearch ? (<Row className="mt-2 mb-2">
      <Col>
       <Row>
          <Col lg={3} md={4} sm={6} xs={6}>
          <select className="form-control" style={{height: 45}}  onChange={(e) => {setFilter({ ...filter, status_id: e.target.value})}}>
            <option value="12">All</option>
            <option value="0">Drafts</option>
            <option value="1">Sent</option>
            <option value="2">Read</option>
            <option value="3">Rejected</option>
            <option value="4">Accepted</option>
          </select>
          </Col>
          <Col lg={3} md={6} sm={6} xs={6}>
          <select className="form-control" style={{height: 45}}  onChange={(e) => {setFilter({ ...filter, customer: e.target.value})}}>
          <option value="all">All</option>
           {customers && customers.map((customer, index) => ( <option value={customer.customer_name} key={index}>{customer.customer_name}</option>))}
          </select>
          </Col>
          <Col lg={3} md={6} sm={6} xs={6}>
            <TextField
              id="date"
              label="Expiry From"
              type="date"
              defaultValue={dateToday}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={e => setFilter({ ...filter, expiry_from: e.target.value})}
            />
          </Col>
          <Col lg={3} md={6} sm={6} xs={6}>
            <TextField
              id="date"
              label="Expiry To"
              type="date"
              defaultValue={dateToday}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={e => setFilter({ ...filter, expiry_to: e.target.value})}
            />
          </Col>
       </Row>
      </Col>
    </Row>) : null}

    <Row>
      <Col>
            <Card small className="mb-4">
                <Row>
                    <Col lg={12} className="col-12 d-none d-xl-block d-lg-block d-md-none" style={{marginTop: 22}}>
                    <div className="table-responsive" style={{height: 600, marginTop: -13}}>
                    {quoteList && <DataTable
                        progressPending={loading}
                        pagination
                        columns={columns}
                        data={quoteList}
                        fixedHeader={true}
                        fixedHeaderScrollHeight
                        persistTableHead={"100vh"}
                        paginationComponentOptions={{ noRowsPerPage: true }}
                        progressComponent={<LinearIndeterminate />}
                      />}
                      </div>
                    </Col>
                    <Col lg={12} className="col-12 d-xs-block d-sm-block d-md-block d-lg-none d-xl-none" style={{marginTop: 22}}>
                        <ListGroup flush>
                          {quoteList && quoteList.map((quote, index) => {
                            return (
                              <div style={{paddingLeft: 10, paddingRight: 10}}>
                              <Row>
                                <Col className="col-7">
                                    <Link to={"/quotes/show/" + quote.transaction_number } onClick={() => {
                                      getQuotesDetails(user.data.auth_token, quote.id);
                                      localStorage.setItem("showQuoteDetails", JSON.stringify(quote.id))
                                    }}>{quote.transaction_number}</Link><br/>
                                    <span style={{fontWeight: 600}}>{quote.customer_name}</span><br/>

                                    {quote.expiration_date ? <span style={{color: "#7F8081"}}>Deadline: {moment(quote.expiration_date).fromNow()}</span> : <span style={{color: "#7F8081"}}>{moment(moment(currentDate, "DD-MM-YYYY").add(user.data.quote_expire_in_days, 'days')).format(user.data.date_format_name ? user.data.date_format_name : "DD/MM/YYYY")} ({moment(moment(currentDate, "DD-MM-YYYY").add(user.data.quote_expire_in_days, 'days')).fromNow()})</span>}
                                    <br/>

                                    <span style={{color: "#7F8081"}}>Created: {quote.status_id == 0 ? moment(quote.created_at).fromNow() : moment(quote.created_at).fromNow()}</span>


                                </Col>
                                <Col className="col-5">
                                <p style={{color: "#007000", marginTop: 20, marginBottom: 0, textAlign: "right"}}>{ currencyFormatter.format(Number(quote.total || 0))}</p>
                                    <select defaultValue={quote.status_id} className="form-select form-select-lg" aria-label=".form-select-lg example" style={{padding: 6, marginTop: 12, width: "100%"}}  onChange={(e) => {
                                     if(e.target.value == 5){
                                        archiveQuote(user.data.auth_token, quote.id)
                                      }else if(e.target.value == 6){
                                        restoreQuote(user.data.auth_token, quote.id)
                                      }else{
                                        updateQuoteStatus(user.data.auth_token, quote.id, e.target.value)
                                      }

                                        }}>
                                            <option style={{fontSize: 13}} value={0} disabled>Draft</option>
                                            <option style={{fontSize: 13}} value={1} disabled>Sent</option>
                                            <option style={{fontSize: 13}} value={2} disabled>Read</option>
                                            <option style={{fontSize: 13}} value={3} disabled={quote.status_id == 0 ? true : false}>Rejected</option>
                                            <option style={{fontSize: 13}} value={4} disabled={quote.status_id == 0 ? true : false}>Accepted</option>
                                            {quote.is_archived == false ? <option style={{fontSize: 13}} value={5}>Archive</option>: null}
                                            {quote.is_archived == true ? <option style={{fontSize: 13}} value={6}>Restore</option> : null}
                                    </select>
                                </Col>
                              </Row>
                              <hr style={{color: "#7C7D7E"}} />
                              </div>
                            )
                          })}
                        </ListGroup>
                    </Col>
                </Row>
            </Card>
      </Col>
    </Row>
  </Container>
  );
};

ListQuotes.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

ListQuotes.defaultProps = {
  title: "Account Details"
};

export default ListQuotes;
