import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../../providers/UserProvider";
import { QuoteContext } from "../../../providers/QuoteProvider";
import PageTitle from "../../../components/common/PageTitle";
import PropTypes from "prop-types";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import makeStyles from "@mui/styles/makeStyles";
import IMAGE_ASSETS from "../../../assets/image-list";
import {
  Input,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  TextField,
} from "@mui/material";
import EditLineItemsComponent from "../../../components/quotes-components/form-steps/EditLineItemsComponent";
import EditTermsAndConditionDetails from "../../../components/quotes-components/form-steps/EditTermsAndConditionDetails";
import EditInlineProfileDetails from "../../../components/quotes-components/form-steps/EditInlineProfileDetails";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { ConnectedFocusError } from "focus-formik-error";
import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Container,
  FormInput,
  FormFeedback,
  Button,
} from "shards-react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { InputGroup, FormControl, Form as ReactForm } from "react-bootstrap";
import NotyfContext from "../../../providers/NotificationContext";
import moment from "moment";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    color: "#7F7F7F",
    width: 130,
    marginLeft: 7,
    marginTop: -5,
  },
}));

const EditQuoteInline = ({ title }) => {
  const { user } = useContext(UserContext);
  const {
    updateDraftQuoteStatus,
    resendQuote,
    updateQuoteStatus,
    quoteListDetails,
    getQuotesDetails,
    archiveQuote,
    localeCurrencyFormat,
    editQuotation,
    getCustomers,
    customers,
  } = useContext(QuoteContext);
  const [showEditStatus, setShowEditStatus] = useState(false);
  const [showEditBusinessProfile, setShowEditBusinessProfile] = useState(false);
  const history = useHistory();
  const currentDate = moment().startOf("day");
  const dateTomorrow = new Date();
  const MySwal = withReactContent(Swal);

  const notyf = useContext(NotyfContext);

  const classes = useStyles();
  const currencyFormatter = new Intl.NumberFormat(
    localeCurrencyFormat[
      user.data.currency_name ? user.data.currency_name : "en-US"
    ],
    {
      style: "currency",
      currency: user.data.currency_name ? user.data.currency_name : "USD",
    }
  );

  const getIcon = (status) => {
    if (status == "Sent") {
      return <img className="mr-2" src={IMAGE_ASSETS.status.sent} alt="sent" />;
    } else if (status == "Read") {
      return <img className="mr-2" src={IMAGE_ASSETS.status.read} alt="read" />;
    } else if (status == "Accepted") {
      return (
        <img
          className="mr-2"
          src={IMAGE_ASSETS.status.accepted}
          alt="Accepted"
        />
      );
    } else if (status == "Draft") {
      return (
        <img className="mr-2" src={IMAGE_ASSETS.status.draft} alt="draft" />
      );
    }
    return (
      <img className="mr-2" src={IMAGE_ASSETS.status.rejected} alt="rejected" />
    );
  };
  useEffect(() => {
    dateTomorrow.setDate(dateTomorrow.getDate() + 1);

    if (!quoteListDetails) {
      getQuotesDetails(
        user.data.auth_token,
        JSON.parse(localStorage.getItem("showQuoteDetails"))
      );
    }
  }, []);

  useEffect(() => {
    // getQuotesDetails(user.data.auth_token ,JSON.parse(localStorage.getItem('showQuoteDetails')))
  }, [updateQuoteStatus]);

  useEffect(() => {
    if (!customers) {
      getCustomers(user.data.auth_token);
    }
  }, [customers]);

  const getColorStatus = (status) => {
    if (status === "Sent") {
      return "#007DC4";
    } else if (status === "Draft") {
      return "#007DC4";
    } else if (status === "Read") {
      return "#ff9800";
    } else if (status === "Accepted") {
      return "#5BC772";
    } else if (status === "Rejected") {
      return "#ED4134";
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .typeError("Customer name is required.")
      .required("Customer name is required."),
    email: Yup.string().email().required("Customer email is required."),
    expiration_date: Yup.date()
      .min(dateTomorrow, "Invalid expiration date.")
      .required("Invalid expiration date."),
  });

  const transformLineItems = (_items) => {
    let _lineItems = [];

    _items.map((item, index) => {
      _lineItems[index] = {
        id: item.index_id,
        description: item.item_description,
        unit_cost: item.item_unit_cost,
      };
    });

    return _lineItems;
  };

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle
          title="QuoteShark"
          viewTitle="Quote List"
          subtitle={
            quoteListDetails ? quoteListDetails.transaction_number : null
          }
          md="12"
          className="ml-sm-auto mr-sm-auto"
        />
      </Row>
      {quoteListDetails && (
        <Formik
          initialValues={{
            referenceNumber: quoteListDetails.transaction_number,
            auth_token: user.data.auth_token,
            customer_id: 0,
            business_name: user.data.business_name,
            business_profile_name: user.data.name,
            business_email: user.data.email,
            contact_number: user.data.contact_number,
            business_address: user.data.business_address,

            name: quoteListDetails.customer_name
              ? quoteListDetails.customer_name
              : "",
            email: quoteListDetails.customer_email
              ? quoteListDetails.customer_email
              : "",
            address: quoteListDetails.customer_address
              ? quoteListDetails.customer_address
              : "",
            customer_number: quoteListDetails.customer_contact_number
              ? quoteListDetails.customer_contact_number
              : "",

            itemName: "",
            itemUnitCost: "",
            subtotal: quoteListDetails.subtotal,
            vat: quoteListDetails.vat,
            total: quoteListDetails.total,
            lineItems: transformLineItems(quoteListDetails.items),

            savePreference: true,
            terms_save: true,
            expiration_date: quoteListDetails.expiration_date
              ? quoteListDetails.expiration_date
              : moment(
                  moment(currentDate, "DD-MM-YYYY").add(
                    user.data.quote_expire_in_days,
                    "days"
                  )
                ).format("YYYY-MM-DD"),
            expire_in_days: moment
              .duration(
                moment(quoteListDetails.expiration_date, "YYYY-MM-DD").diff(
                  currentDate
                )
              )
              .asDays(),
            terms_is_deposit_required:
              quoteListDetails.terms_is_deposit_required === true
                ? "true"
                : "false",
            terms_is_deposit_fixed:
              quoteListDetails.terms_is_deposit_fixed === true
                ? "true"
                : "false",
            terms_deposit_percentage: quoteListDetails.terms_deposit_percentage,
            terms_deposit_amount: quoteListDetails.terms_deposit_amount,
            terms_other_conditions: quoteListDetails.terms_other_conditions,
          }}
          validationSchema={validationSchema}
          onSubmit={async (values, { resetForm }) => {
            let _data = {
              customerDetails: {
                referenceNumber: user.data.transaction_number,
                auth_token: user.data.auth_token,
                customer_id: values.customer_id,
                name: values.name,
                email: values.email,
                address: values.address,
                customer_number: values.customer_number,
              },
              itemStep: {
                lineItems: values.lineItems,
              },
              termsAndCondition: {
                savePreference: values.savePreference,
                terms_save: values.savePreference,
                expire_in_days: values.expire_in_days,
                expiration_date: values.expiration_date,
                terms_is_deposit_required:
                  values.terms_is_deposit_required === "true" ? true : false,
                terms_is_deposit_fixed:
                  values.terms_is_deposit_fixed === "true" ? true : false,
                terms_deposit_percentage: values.terms_deposit_percentage,
                terms_deposit_amount: values.terms_deposit_amount,
                terms_other_conditions: values.terms_other_conditions,
              },
            };
            notyf.success("Saving quotation...");
            editQuotation(
              _data.customerDetails,
              _data.itemStep,
              _data.termsAndCondition,
              quoteListDetails.id
            ).then((response) => {
              if (response.status) {
                notyf.success(response.message);
                var _text = quoteListDetails.status_id == 0 ? "send" : "resend";
                var _textC =
                  quoteListDetails.status_id == 0 ? "Send" : "Resend";
                MySwal.fire({
                  title: "Confirmation",
                  text: `Do you want to ${_text} quotation to ${_data.customerDetails.email} now?`,
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  cancelButtonText: "Not now",
                  confirmButtonText: `Yes, ${_textC} Quote`,
                }).then((result) => {
                  if (result.isConfirmed) {
                    notyf.success("Sending quotation...");
                    // setSendDisabled(true)
                    updateDraftQuoteStatus(
                      user.data.auth_token,
                      quoteListDetails.id,
                      1
                    );
                    resendQuote(user.data.auth_token, quoteListDetails.id).then(
                      (response) => {
                        history.push("/quotes/list");
                        console.log("from response ", response);
                      }
                    );
                  } else {
                    updateQuoteStatus(
                      user.data.auth_token,
                      quoteListDetails.id,
                      0
                    );
                    history.push("/quotes/list");
                  }
                });
              } else {
                notyf.error(response.message);
              }
              console.log("from response ", response);
            });
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
            setFieldValue,
          }) => (
            <Form className="form">
              <Row form className="justify-content-center align-items-center">
                <Col lg={9}>
                  <Card small className="mb-4 mt-5">
                    {user && (
                      <Row
                        style={{
                          border: "1px solid gray",
                          backgroundColor: "#fff",
                        }}
                      >
                        <Col
                          lg={12}
                          style={{
                            borderBottom: "1px solid gray",
                            padding: 5,
                            backgroundColor: "#fff",
                          }}
                        >
                          <Row>
                            <Col
                              hidden={!showEditStatus}
                              lg={6}
                              md={12}
                              sm={12}
                              xs={12}
                              className="col-6"
                            >
                              <span
                                className=""
                                style={{
                                  fontWeight: 600,
                                  marginLeft: 10,
                                  color: "black",
                                }}
                              >
                                Status
                              </span>
                              <select
                                defaultValue={quoteListDetails.status_id}
                                className="form-select form-select-lg"
                                style={{
                                  padding: 6,
                                  marginLeft: 20,
                                  marginTop: 5,
                                }}
                                onChange={(e) => {
                                  if (e.target.value !== 0) {
                                    updateQuoteStatus(
                                      user.data.auth_token,
                                      quoteListDetails.id,
                                      e.target.value
                                    );
                                    getQuotesDetails(
                                      user.data.auth_token,
                                      quoteListDetails.id
                                    );
                                    setShowEditStatus(false);
                                  }
                                }}
                              >
                                <option style={{ fontSize: 13 }} value={1}>
                                  Sent
                                </option>
                                <option style={{ fontSize: 13 }} value={2}>
                                  Read
                                </option>
                                <option style={{ fontSize: 13 }} value={3}>
                                  Rejected
                                </option>
                                <option style={{ fontSize: 13 }} value={4}>
                                  Accepted
                                </option>
                                <option style={{ fontSize: 13 }} value={0}>
                                  Draft
                                </option>
                              </select>
                              <span
                                className="text-primary"
                                style={{
                                  cursor: "pointer",
                                  marginLeft: 12,
                                  fontWeight: 600,
                                }}
                                onClick={() => {
                                  setShowEditStatus(false);
                                }}
                              >
                                Cancel
                              </span>
                            </Col>
                            <Col
                              hidden={showEditStatus}
                              lg={6}
                              md={12}
                              sm={12}
                              xs={12}
                              className="col-6"
                              style={{ paddingTop: 12 }}
                            >
                              <span
                                className=""
                                style={{
                                  fontWeight: 600,
                                  marginLeft: 10,
                                  color: "black",
                                }}
                              >
                                Status
                              </span>
                              <span
                                className=""
                                style={{
                                  marginLeft: 12,
                                  color: getColorStatus(
                                    quoteListDetails.status
                                  ),
                                }}
                              >
                                {getIcon(quoteListDetails.status)}
                                {quoteListDetails.status}
                              </span>
                              {/* <span className="text-primary" style={{ cursor: "pointer", marginLeft: 32, fontWeight: 600}} onClick={() => {
                              setShowEditStatus(true)
                            }}>Edit Status</span> */}
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          lg={12}
                          className="pt-4"
                          hidden={showEditBusinessProfile}
                        >
                          <span className="" style={{ fontWeight: 600 }}>
                            <i
                              className="fa fa-building"
                              aria-hidden="true"
                            ></i>{" "}
                            {values.business_name}
                            <br />
                          </span>
                          <span className="">
                            <i className="fa fa-user" aria-hidden="true"></i>{" "}
                            {values.business_profile_name}
                          </span>
                          <br />
                          {values.contact_number && (
                            <span className="">
                              <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                              {values.contact_number}
                              <br />
                            </span>
                          )}
                          <span className="">
                            <i
                              className="fa fa-envelope"
                              aria-hidden="true"
                            ></i>{" "}
                            {values.business_email}
                          </span>
                          <br />
                          {values.business_address && (
                            <span className="">
                              <i
                                className="fa fa-map-pin"
                                aria-hidden="true"
                              ></i>{" "}
                              {values.business_address}
                              <br />
                            </span>
                          )}

                          <div style={{ marginTop: 15 }}>
                            <span
                              className="text-primary"
                              style={{ cursor: "pointer", fontWeight: 600 }}
                              onClick={() => {
                                setShowEditBusinessProfile(true);
                              }}
                            >
                              Edit Business Profile
                            </span>
                          </div>
                        </Col>
                        <Col
                          lg={12}
                          className="pt-4"
                          hidden={!showEditBusinessProfile}
                        >
                          <EditInlineProfileDetails
                            setShowEditBusinessProfile={
                              setShowEditBusinessProfile
                            }
                          />
                        </Col>

                        <Col
                          lg={12}
                          className="bg-primary"
                          style={{ marginTop: 20, height: 30 }}
                        >
                          <span>&nbsp;</span>
                          <span
                            style={{
                              fontSize: 40,
                              fontWeight: 700,
                              backgroundColor: "#FFF",
                              color: "#404144",
                              position: "absolute",
                              right: 83,
                              top: -15,
                              paddingLeft: 9,
                              paddingRight: 9,
                            }}
                          >
                            QUOTE
                          </span>
                        </Col>

                        <Col lg={8} className="pt-4">
                          <span style={{ fontWeight: 600, fontSize: 16 }}>
                            Quote To:
                          </span>
                          <br />
                          <InputGroup hasValidation style={{ marginBottom: 5 }}>
                            <ConnectedFocusError />

                            <InputGroup.Text>
                              <i className="fa fa-user" aria-hidden="true"></i>
                            </InputGroup.Text>
                            <Autocomplete
                              size="small"
                              value={values.name}
                              onChange={(event, newValue) => {
                                console.log(newValue);
                                if (typeof newValue === "string") {
                                  setFieldValue("name", newValue);
                                } else if (newValue && newValue.customer_name) {
                                  // Create a new value from the user input
                                  setFieldValue("customer_id", newValue.id);
                                  setFieldValue("name", newValue.customer_name);
                                  setFieldValue("email", newValue.email);
                                  setFieldValue(
                                    "address",
                                    newValue.customer_address
                                  );
                                } else {
                                  setFieldValue("name", newValue);
                                }
                              }}
                              filterOptions={(options, params) => {
                                const filtered = filter(options, params);

                                // Suggest the creation of a new value
                                if (params.inputValue !== "") {
                                  filtered.push({
                                    inputValue: params.inputValue,
                                    customer_name: `${params.inputValue}`,
                                  });
                                }

                                return filtered;
                              }}
                              options={customers ? customers : []}
                              getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === "string") {
                                  return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                  return option.inputValue;
                                }
                                // Regular option
                                return option.customer_name;
                              }}
                              style={{ padding: 0, width: "80%" }}
                              freeSolo
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder="Customer Name"
                                  type="text"
                                  variant="outlined"
                                  onChange={(e) => {
                                    setFieldValue("name", e.target.value);
                                    setFieldValue("customer_id", 0);
                                  }}
                                />
                              )}
                            />
                            {/* {errors.name && touched.name ? <FormFeedback>{errors.name}</FormFeedback> : null} */}
                            <small style={{ color: "red" }}>
                              {touched.name && errors.name}
                            </small>
                          </InputGroup>

                          <InputGroup hasValidation style={{ marginBottom: 5 }}>
                            <InputGroup.Text>
                              <i
                                className="fa fa-envelope"
                                aria-hidden="true"
                              ></i>
                            </InputGroup.Text>
                            <Field
                              className=""
                              id="email"
                              name="email"
                              value={values.email}
                              invalid={errors.email}
                              onChange={handleChange}
                              placeholder="Customer Email"
                              type="text"
                              size="small"
                              variant="outlined"
                              style={{ width: "80%" }}
                              as={TextField}
                            />
                            <small style={{ color: "red" }}>
                              {touched.email && errors.email}
                            </small>
                          </InputGroup>

                          <InputGroup hasValidation style={{ marginBottom: 5 }}>
                            <InputGroup.Text>
                              <i className="fa fa-phone" aria-hidden="true"></i>
                            </InputGroup.Text>
                            <Field
                              className=""
                              id="customer_number"
                              name="customer_number"
                              value={values.customer_number}
                              invalid={errors.customer_number}
                              onChange={handleChange}
                              placeholder="Customer Number"
                              type="text"
                              size="small"
                              variant="outlined"
                              style={{ width: "80%" }}
                              as={TextField}
                            />
                          </InputGroup>
                          <InputGroup hasValidation style={{}}>
                            <InputGroup.Text>
                              <i
                                className="fa fa-map-pin"
                                aria-hidden="true"
                              ></i>
                              &nbsp;
                            </InputGroup.Text>
                            <Field
                              className=""
                              id="address"
                              name="address"
                              value={values.address}
                              invalid={errors.address}
                              onChange={handleChange}
                              placeholder="Customer Address"
                              type="text"
                              size="small"
                              variant="outlined"
                              style={{ width: "80%" }}
                              as={TextField}
                            />
                          </InputGroup>
                        </Col>

                        <Col lg={4} className="pt-lg-4 pt-2">
                          <br />
                          <span style={{ fontWeight: "bold" }}>Ref #:</span>
                          <span> {quoteListDetails.transaction_number}</span>
                          <br />
                          <span style={{ fontWeight: "bold" }}>
                            Quote Date:
                          </span>
                          <span>
                            {" "}
                            {moment(currentDate).format(
                              user.data.date_format_name
                                ? user.data.date_format_name
                                : "DD/MM/YYYY"
                            )}
                          </span>
                          <br />
                          <span style={{ fontWeight: "bold" }}>
                            Expiry Date:
                          </span>
                          <span>
                            {/* <ConnectedFocusError /> */}
                            {/* <Field
                        className="form-control"
                        id="expiration_date"
                        name="expiration_date"
                        value={values.expiration_date}
                      /> */}
                            <TextField
                              id="date"
                              // label="Expiry From"
                              name="expiration_date"
                              type="date"
                              defaultValue={
                                quoteListDetails.expiration_date
                                  ? quoteListDetails.expiration_date
                                  : moment(
                                      moment(currentDate, "DD-MM-YYYY").add(
                                        user.data.quote_expire_in_days,
                                        "days"
                                      )
                                    ).format("YYYY-MM-DD")
                              }
                              className={classes.textField}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              style={{ fontSize: 15 }}
                              onChange={(e) => {
                                setFieldValue(
                                  "expiration_date",
                                  e.target.value
                                );
                                setFieldValue(
                                  "expire_in_days",
                                  moment
                                    .duration(
                                      moment(e.target.value, "YYYY-MM-DD").diff(
                                        currentDate
                                      )
                                    )
                                    .asDays()
                                );
                              }}
                            />
                          </span>
                          <br />

                          <small style={{ color: "red" }}>
                            {touched.expiration_date && errors.expiration_date}
                          </small>
                        </Col>

                        <Col lg={12} className="pt-lg-5 pt-3">
                          <EditLineItemsComponent values={values} />
                        </Col>

                        <EditTermsAndConditionDetails />

                        <Col lg={7} className="" style={{ paddingBottom: 12 }}>
                          <Button
                            theme="success"
                            className=""
                            style={{ width: 100, height: 40, marginRight: 10 }}
                            type="submit"
                          >
                            Save
                          </Button>
                          {/* <Button theme="warning" className="ml-2" style={{width: 100, height:40, marginTop: 15}} type="button"  onClick={(e) => {
                            history.push('/quotes/show/'+quoteListDetails.transaction_number)
                          }} >Cancel</Button> */}

                          <span
                            className="text-primary"
                            style={{ cursor: "pointer", fontWeight: 600 }}
                            onClick={() => {
                              history.push(
                                "/quotes/show/" +
                                  quoteListDetails.transaction_number
                              );
                            }}
                          >
                            Cancel
                          </span>
                        </Col>
                      </Row>
                    )}
                  </Card>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      )}
    </Container>
  );
};

export default EditQuoteInline;
