import React, {useContext ,useState, useEffect} from "react";
import { UserContext } from '../../../providers/UserProvider';
import { QuoteContext } from '../../../providers/QuoteProvider';
import PageTitle from "../../../components/common/PageTitle";
import makeStyles from '@mui/styles/makeStyles';
import { Input, AccordionDetails, AccordionSummary, Accordion, TextField } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from "prop-types";
import { Link, useHistory} from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Container,
  FormInput,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button
} from "shards-react";
import NotyfContext from '../../../providers/NotificationContext';
import { ConnectedFocusError } from "focus-formik-error";
import { object, string, boolean, array, number } from 'yup'
import { Formik, Field, Form } from 'formik';

const CreateCustomers = () => {
  const {user} = useContext(UserContext)
  const {createCustomer} = useContext(QuoteContext)
  const history = useHistory();

  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const ValidationSchema = object().shape({
    customer_name: string().max(255, 'Text is Too Long!').required('Business Name is Required'),
    email: string().max(255, 'Text is Too Long!').email("Enter a valid email").required('Email is Required'),
    customer_address: string().max(255, 'Text is Too Long!').required('Business Address is Required'),
  });

  return (
    <Formik
    initialValues={{
      customer_name: "",
      email: "",
      customer_address: "",
      contact_number: "",
    }}
    validationSchema={ValidationSchema}
    onSubmit={ async (values) => {
      console.log("submit")
      var result = await createCustomer(user.data.auth_token ,values);
      console.log("result", result)
      if(result){
        history.push("/customers/list");
      }
    }}
  >
  {({handleSubmit, handleChange, values, touched, errors, setFieldValue}) => (
    <Container fluid className="main-content-container px-4">
    <Row noGutters className="page-header py-4">
      <PageTitle title="QuoteShark" title="QuoteShark" viewTitle="Customer List" subtitle="Create"  md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>

    <Form>
     <ConnectedFocusError />
    <Row >
    <Col>
      <Card small className="" >
          <CardHeader className="border-bottom">
            <h6 className="m-0">Create Customer</h6>
          </CardHeader>
          <Row>
            <Col lg={6} md={12}>
              <div  className="mb-4 p-4">
                <div className="form-group">

                      <Field
                        autoFocus={true}
                        variant="outlined"
                        type="text"
                        as={TextField}
                        id="customer_name"
                        name="customer_name"
                        label={<span>Customer Name<span className="text-danger"> *</span></span>}
                        className={`form-control input ${errors.customer_name ? "input-error" : ""}`}
                        onChange={(e) => {setFieldValue("customer_name", e.target.value)}}
                      />

                  <small style={{ color: 'red' }}>
                    {touched.customer_name && errors.customer_name}
                  </small>
                </div>
                <div className="form-group">
                      <Field type=""
                        variant="outlined"
                        type="text"
                        as={TextField}
                        id="email"
                        name="email"
                        label={<span>Email<span className="text-danger"> *</span></span>}
                        className={`form-control input ${errors.email ? "input-error" : ""}`}
                        onChange={(e) => {setFieldValue("email", e.target.value)}}
                      />
                  <small style={{ color: 'red' }}>
                    {touched.email && errors.email}
                  </small>
                </div>
                <div className="form-group">
                      <Field
                        variant="outlined"
                        type="text"
                        as={TextField}
                        id="customer_address"
                        name="customer_address"
                        label={<span>Address<span className="text-danger"> *</span></span>}
                        className={`form-control input ${errors.customer_address ? "input-error" : ""}`}
                        onChange={(e) => {setFieldValue("customer_address", e.target.value)}}
                      />
                  <small style={{ color: 'red' }}>
                    {touched.customer_address && errors.customer_address}
                  </small>
                </div>
                <div className="form-group">
                      <Field
                        variant="outlined"
                        type="text"
                        as={TextField}
                        id="contact_number"
                        name="contact_number"
                        label={<span>Contact Number</span>}
                        className={`form-control input ${errors.contact_number ? "input-error" : ""}`}
                        onChange={(e) => {setFieldValue("contact_number", e.target.value)}}
                      />
                  <small style={{ color: 'red' }}>
                    {touched.contact_number && errors.contact_number}
                  </small>
                </div>
                <div style={{marginBottom: -23}}>

              <p className="text-muted m-0" style={{fontSize: 12, paddingBottom: 3}}>Please complete all required fields (<span className="text-danger">*</span>)</p>

                    <Button theme="secondary" type="button" tag={Link} to="/customers/list">Cancel</Button>
                    <Button theme="success" className="ml-2" type="submit">Save</Button>
                </div>
                </div>
            </Col>
          </Row>
      </Card>
    </Col>
    </Row>
    </Form>
  </Container>)}
  </Formik>
  );
};

export default CreateCustomers;
