import React, { useEffect, useContext, useState } from "react";
// import './Login.css'
import { signInWithGoogle, signInWithFacebook } from "../../api/firebase";
import { UserContext } from "../../providers/UserProvider";
import { QuoteContext } from "../../providers/QuoteProvider";
import { Redirect, Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import logo from "../../images/logo.png";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import IMAGE_ASSETS from "../../assets/image-list";
export default function Signup() {
  const { user, signUp } = useContext(UserContext);
  const { setNotifications, setLatestQuoteList } = useContext(QuoteContext);
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [values, setValues] = useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onKeyPress = (ev) => {
    console.log(`Pressed keyCode ${ev.key}`);
    if (ev.key === "Enter") {
      // Do code here
      ev.preventDefault();
      console.log(email);
      console.log(password);
      let data = {
        data: {
          email: email,
          password: password,
        },
      };

      console.log(data);
      signUp(data);
    }
  };

  useEffect(() => {
    setNotifications(null);
    setLatestQuoteList(null);
  }, [user]);

  if (user) {
    return <Redirect to={"/dashboard"} />;
  }
  return (
    <div className="container mt-lg-5" data-testid="mainLayout">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-5 col-md-7 col-sm-12 col-xs-12 p-0">
          <div className="w-100 p-5" style={{ backgroundColor: "#004E74" }}>
            <img className="mb-4 img-fluid " src={logo} alt="QuoteShark Logo" />
            <h3
              className="h3 mb-3 fw-normal text-center text-white"
              style={{ fontWeight: 700 }}
            >
              Sign Up
            </h3>
            <p className="text-white text-center" style={{ fontWeight: 300 }}>
              No Credit Card Required
            </p>
            <div className="form-floating">
              <label className="text-white">Email</label>
              <input
                data-testid="inputEmail"
                type="email"
                value={email}
                onChange={(e) => {
                  setemail(e.target.value);
                }}
                onKeyPress={onKeyPress}
                className="form-control"
                placeholder="hello@example.com"
              />
            </div>
            <div className="form-floating mt-3">
              <label className="text-white">Password</label>
              <input
                data-testid="inputPassword"
                type={values.showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => {
                  setpassword(e.target.value);
                }}
                onKeyPress={onKeyPress}
                className="form-control"
                placeholder="********"
              />

              <InputAdornment position="end">
                <IconButton
                  style={{ marginTop: -34, marginLeft: "88%" }}
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            </div>

            <div className="mb-3  mt-4">
              <button
                data-testid="buttonSignUp"
                className="btn btn-block call-to-action-main"
                style={{ padding: 12 }}
                onClick={() => {
                  console.log(email);
                  console.log(password);
                  // setUser("jake")
                  let data = {
                    email: email,
                    password: password,
                  };
                  signUp(data);
                }}
              >
                Sign Up
              </button>
            </div>
            <p className="text-white mt-3">
              Already have an account?
              <Link
                data-testid="linkSignIn"
                to={`/login`}
                className="text-white"
              >
                <b>
                  {" "}
                  <u>Sign in</u>
                </b>
              </Link>
            </p>
            <div className="flex flex-row">
              <p className="text-center text-white m-0">or continue with</p>
            </div>

            <div className="w-100 justify-content-center align-items-center">
              <center>
                <img
                  className=" btn hover-shadow"
                  style={{ width: 100 }}
                  src={IMAGE_ASSETS.src.facebookIcon}
                  alt="Facebook Logo"
                  onClick={() => {
                    signInWithFacebook();
                  }}
                />
                <img
                  className=" btn hover-shadow"
                  style={{ width: 100 }}
                  src={IMAGE_ASSETS.src.googleIcon}
                  alt="Google Logo"
                  onClick={() => {
                    signInWithGoogle();
                  }}
                />
              </center>
            </div>
          </div>
        </div>
        {/* <div className="col-lg-1"></div> */}
        <div className="col-lg-5 col-md-7 d-none d-lg-block d-md-block ml-3">
          <div className="w-100" style={{}}>
            <div
              className="pt-5 pb-5"
              style={{
                backgroundImage: `url("${IMAGE_ASSETS.src.featuresBg}")`,
                backgroundSize: "cover",
              }}
            >
              <h3 className="h3 m-3 fw-normal text-center text-white">
                Check out{" "}
                <span style={{ fontWeight: 700 }}>QuoteShark App</span> — It’s
                Free!
              </h3>
            </div>

            <div style={{ padding: 10 }}>
              <h2>
                <u>HOW IT WORKS </u>
              </h2>

              <h3>Create</h3>
              <p>
                Build your own custom mobile Apps – without writing a line of
                code.
              </p>
              <h3>Collect</h3>
              <p>
                Enter exactly the data you need, right from your preferred
                mobile device.
              </p>

              <h3>Share</h3>
              <p>
                Send your checklists to clients, customers, or anyone else who
                needs the info.
              </p>
            </div>

            <div
              style={{
                height: 55,
                backgroundColor: "#003956",
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}
