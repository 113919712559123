import { Field, useFormikContext, ErrorMessage } from 'formik'
import React, {useState, useEffect, useContext} from 'react'
import Grid from '@mui/material/Grid';
import {Form} from 'react-bootstrap';
import { UserContext } from '../../../providers/UserProvider';
import { QuoteContext } from '../../../providers/QuoteProvider';
import {
  Row,
  Col,
  FormCheckbox,
} from "shards-react";

function SummaryComponent() {
  const { errors, touched, values, resetForm, setFieldValue } = useFormikContext()
  const {user} = useContext(UserContext)
  const {localeCurrencyFormat, localeCurrencySign} = useContext(QuoteContext)
  const [isChecked, setIsChecked] = useState(false)
  const [requiredDeposite, setRequiredDeposite] = useState(false)
  const [initialValues, setInitialValues] = useState({
    savePreference: true,
    expire_in_days: 30,
    terms_is_deposit_required: false,
    terms_is_deposit_fixed: false,
    terms_deposit_percentage: 0,
    terms_deposit_amount: 0,
    terms_other_conditions: "",
  })

  const currencyFormatter = new Intl.NumberFormat(localeCurrencyFormat[user.data.currency_name ? user.data.currency_name : "en-US"], {
    style: 'currency',
    currency: user.data.currency_name ? user.data.currency_name : "USD",
  });

  useEffect(() => {
    setFieldValue("expire_in_days", user.data.quote_expire_in_days ? user.data.quote_expire_in_days : 30)
    setFieldValue("terms_is_deposit_required", user.data.terms_is_deposit_required  === true ? "true" : "false")
    setFieldValue("terms_is_deposit_fixed", user.data.terms_is_deposit_fixed  === true ? "true" : "false")
    setFieldValue("terms_deposit_percentage", user.data.terms_deposit_percentage ? user.data.terms_deposit_percentage : 10)
    setFieldValue("terms_deposit_amount", user.data.terms_deposit_amount ? user.data.terms_deposit_amount : 0)
    setFieldValue("terms_other_conditions", user.data.terms_other_conditions ? user.data.terms_other_conditions : "")
    setFieldValue('terms_save', true)
  }, [])

  useEffect(() => {
    console.log("from on action save draftQuote")
    localStorage.setItem("draftQuoteTerms", JSON.stringify(values))
  }, [values])

  return (
    <div>
    <Row >
        <Col lg={12}>
          <Row style={{marginLeft: 2}}>
             <span className="mr-1">Quote to expire in </span><Field type="number" name="expire_in_days" style={{width: 50, height: 30, marginTop: -5}} /><span style={{marginLeft: 4}}>Days.</span>
          </Row>
        </Col>
    </Row>
      <Row className="mt-4">
        <Col lg={3}> <span>Is a deposit required?</span></Col>
        <Col lg={6} className="">
          <div role="group" aria-labelledby="my-radio-group">
            <label>
                <Field type="radio" name="terms_is_deposit_required" value="false" checked="checked"  />
                 <span> No Deposit</span>
              </label>
              <label className="ml-2">
                <Field type="radio" name="terms_is_deposit_required" value="true" />
                <span> Deposit Required</span>
              </label>
          </div>
        </Col>
      </Row>
      <Row className="mt-4" hidden={values.terms_is_deposit_required == "true" ? false: true}>
        <Col lg={3}> <span>Deposit method</span></Col>
        <Col lg={6} className="">
          <div role="group" aria-labelledby="my-radio-group">
            <label>
              <Field type="radio" name="terms_is_deposit_fixed" value="false" checked="checked" />
              <span> Percentage Total</span>
            </label>
            <label className="ml-2">
            <Field type="radio" name="terms_is_deposit_fixed" value="true" />
            <span> Fixed Amount</span>
            </label>
          </div>
        </Col>
      </Row>

      <div className="mt-4"  hidden={values.terms_is_deposit_required == "true" ? false: true}>
        <Row hidden={values.terms_is_deposit_fixed == "true" ? true: false}>
          <Col lg={12}>
            <Row className="ml-1">
              <span className="mr-1">Percentage deposit </span><Field type="number" name="terms_deposit_percentage" style={{width: 50, height: 30, marginTop: -5}} /><span style={{marginLeft: 4}}>%</span>
            </Row>
          </Col>
        </Row>
        <Row hidden={values.terms_is_deposit_fixed == "true" ? false: true}>
          <Col lg={12}>
            <Row className="ml-1">
              <span className="mr-1">Fixed deposit </span><span style={{marginLeft: 4}}>{localeCurrencySign(user.data.currency_name)}</span><Field type="number" name="terms_deposit_amount" style={{width: 80, height: 30, marginTop: -5}} />
            </Row>
          </Col>
        </Row>
      </div>

      <Row className="mt-4" >
          <Col lg={6}>
            <Form.Group controlId="exampleForm.ControlTextarea1">
              <Form.Label>Other Conditions</Form.Label>

              <Form.Control as="textarea" rows={3} value={values.terms_other_conditions}  onChange={(e) => {
                setFieldValue('terms_other_conditions', e.target.value)
              }}/>
            </Form.Group>
          </Col>
      </Row>

      <div className="form-group container mt-3 ml-2">
          <div className="form-check">
          <Field type="checkbox" id="terms_save" name="terms_save" className="form-check-input" />
          <label htmlFor="terms_save" className="form-check-label">Save these preferences</label>
          </div>
      </div>
    </div>
  )
}

export default SummaryComponent
