import React, {useContext } from "react";
import { UserContext } from '../../providers/UserProvider';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import * as Yup from "yup";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  FormInput,
  FormFeedback,
  Button
} from "shards-react";
import { Formik, Form } from 'formik';

const ChangePasswordForm = ({ title}) => {
  const {user, changePassword} = useContext(UserContext)

  const validationSchema = Yup.object({
    old_password: Yup.string().required('Password is required'),
    new_password: Yup.string().min(6, "New password must be at least 6 characters").required('New password is required'),
    confirm_password: Yup.string().required('Confirm password is required').oneOf([Yup.ref('new_password'), null], 'Passwords must match')
  })

  return (
    <Formik
      initialValues={{
        old_password: "",
        new_password: "",
        confirm_password: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, {resetForm}) => {
        changePassword(values, user.data.auth_token);
        resetForm({
             values: {
               // the type of `values` inferred to be Blog
              old_password: "",
              new_password: "",
              confirm_password: "",
             },
             // you can also set the other form states here
           });
      }}
    >
    {({handleSubmit, handleChange, values, errors}) => (<Card data-testid="mainLayout" small className="mb-4">
      <CardHeader className="border-bottom">
        <h6 className="m-0">{title}</h6>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <Form>
                <Row form>
                  <Col className="form-group">
                    <label className="d-none d-sm-block d-md-block" htmlFor="old_password">Old Password</label>
                    <FormInput
                      data-testid="inputOldPassword"
                      className="form-control"
                      id="old_password"
                      name="old_password"
                      invalid={errors.old_password}
                      onChange={handleChange}
                      placeholder="Old Password"
                      type="password"
                    />
                    {errors.old_password? <FormFeedback>{errors.old_password}</FormFeedback> : null}
                  </Col>
                </Row>
                <Row form>
                  <Col className="form-group">
                    <label className="d-none d-sm-block d-md-block" htmlFor="new_password">New Password</label>
                    <FormInput
                      data-testid="inputNewPassword"
                      className="form-control"
                      id="new_password"
                      name="new_password"
                      invalid={errors.new_password}
                      onChange={handleChange}
                      placeholder="New Password"
                      type="password"
                    />
                    {errors.new_password? <FormFeedback>{errors.new_password}</FormFeedback> : null}
                  </Col>
                </Row>
                <Row form>
                  <Col className="form-group">
                    <label className="d-none d-sm-block d-md-block" htmlFor="confirm_password">Confirm Password</label>
                    <FormInput
                      data-testid="inputConfirmPassword"
                      className="form-control"
                      id="confirm_password"
                      name="confirm_password"
                      invalid={errors.confirm_password}
                      onChange={handleChange}
                      placeholder="Confirm Password"
                      type="password"
                    />
                    {errors.confirm_password? <FormFeedback>{errors.confirm_password}</FormFeedback> : null}
                  </Col>
                </Row>
                <Button data-testid="linkCancel" theme="secondary" type="button" tag={Link} to="/dashboard">Cancel</Button>
                <Button data-testid="buttonSave" theme="accent" className="ml-2" type="submit">Save</Button>
              </Form>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>)}
    </Formik>
  );
};

ChangePasswordForm.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

ChangePasswordForm.defaultProps = {
  title: "Account Details"
};

export default ChangePasswordForm;
