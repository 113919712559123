import React, { useEffect, useContext, useState } from 'react';
// import './Login.css'
import { signInWithGoogle, signInWithFacebook } from '../../api/firebase';
import { UserContext } from '../../providers/UserProvider';
import {useLocation, Redirect, Link } from "react-router-dom";
import {Button} from 'react-bootstrap'
import logo from '../../images/logo.png'
import styled from 'styled-components';
import PropTypes from "prop-types";
import * as Yup from "yup";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  FormInput,
  FormFeedback
} from "shards-react";
import { Formik, Form } from 'formik';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

export default function ResetPassword() {
  const {user, requestResetPassword, resetPassword} = useContext(UserContext)
  const [email, setemail] = useState("")
  const [password, setpassword] = useState("")
  const [confirmPassword, setConfirmpassword] = useState("")
  const query = useQuery();

  const validationSchema = Yup.object({
    new_password: Yup.string().min(6, "New password must be at least 6 characters").required('New password is required'),
    confirm_password: Yup.string().required('Confirm password is required').oneOf([Yup.ref('new_password'), null], 'Passwords must match')
  })

    const BtnFacebook = styled.button`
    width: 100%;
    height:35px;
    border-radius: 4px;
    background: #3b5998;
    color:white;
    border:0px transparent;
    text-align: center;
    margin:5px;
    display: inline-block;

    &:hover{
        background: #3b5998;
        opacity: 0.6;
    }
    `;
    const BtnGoogle = styled.button`
    margin:5px;
    width: 100%;
    height:35px;
    border-radius: 4px;
    background: #db3236;
    color:white;
    border:0px transparent;
    text-align: center;

    &:hover{
        background: #3b5998;
        opacity: 0.6;
    }
    `


  useEffect(() => {
    console.log(query.get("email"))
    console.log(query.get("reset_password_token"))
    // getPublicQuote(query.get("quote_id"), query.get("qtoken"))
  }, [])

  return (
    <div className="container mt-lg-5" data-testid="mainLayout">
      <div className="row justify-content-center align-items-center">
        <div className="col-lg-5 col-md-7 col-sm-12 col-xs-12 p-0">
        <div className="w-100 bg-primary p-5">
        <img className="mb-4 img-fluid " src={logo} alt="QuoteShark Logo"/>
        {/* <pre>{user}</pre> */}
        <h5 className="h5 mb-3 fw-normal text-center text-white">Forgot Password</h5>
          <Formik
                data-testid="formFormik"
                initialValues={{
                  new_password: "",
                  confirm_password: "",
                }}
                validationSchema={validationSchema}
                onSubmit={async (values, {resetForm}) => {
                  resetPassword(query.get("email"), query.get("reset_password_token"), values.new_password);
                  resetForm({
                  values: {
                    new_password: "",
                    confirm_password: "",
                  },
                });
                }}
              >
              {({handleSubmit, handleChange, touched, errors}) => (
                        <Form>
                          <Row form>
                            <Col className="form-group">
                              <label className="text-white d-none d-sm-block d-md-block" htmlFor="new_password">New Password</label>
                              <FormInput
                                data-testid="inputNewPassword"
                                className="form-control"
                                id="new_password"
                                name="new_password"
                                invalid={errors.new_password}
                                onChange={handleChange}
                                placeholder="New Password"
                                type="password"
                              />
                              {errors.new_password? <FormFeedback>{errors.new_password}</FormFeedback> : null}
                            </Col>
                          </Row>
                          <Row form>
                            <Col className="form-group">
                              <label className="text-white d-none d-sm-block d-md-block" htmlFor="confirm_password">Confirm Password</label>
                              <FormInput
                                data-testid="inputConfirmPassword"
                                className="form-control"
                                id="confirm_password"
                                name="confirm_password"
                                invalid={errors.confirm_password}
                                onChange={handleChange}
                                placeholder="Confirm Password"
                                type="password"
                              />
                              {errors.confirm_password? <FormFeedback>{errors.confirm_password}</FormFeedback> : null}
                            </Col>
                          </Row>
                            <Button data-testid="buttonSubmit" variant="light" type="submit" className="mt-1" block onClick={() => {
                            }}>
                          Send Reset Password
                          </Button>
                        </Form>)}
            </Formik>

        </div>
        </div>
      </div>
    </div>
  );
}
