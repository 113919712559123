import React, {useContext ,useState, useEffect} from "react";
import { QuoteContext } from '../../providers/QuoteProvider';
import { UserContext } from '../../providers/UserProvider';
import NotyfContext from '../../providers/NotificationContext';
import {Form} from 'react-bootstrap';
import {useLocation} from "react-router-dom";
import PublicSavePdf from '../admin/quote/pdf/PublicSavePdf'
import { PDFDownloadLink } from '@react-pdf/renderer';
import IMAGE_ASSETS from "../../assets/image-list";
import LinearProgress from '@mui/material/LinearProgress';
import {
  Card,
  CardHeader,
  Row,
  Col,
  Container,
  Button
} from "shards-react";
import moment from "moment";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}


const UnSubscribePage = () => {
  // const {getPublicQuote, publicQuoteDetail, acceptQuote, rejectQuote, localeCurrencyFormat, loading, setLoading} = useContext(QuoteContext)
  const {loading, setLoading, emailUnSubscribe} = useContext(UserContext)
  const currentDate = moment().startOf('day');
  const query = useQuery();
  const MySwal = withReactContent(Swal)
  const notyf = useContext(NotyfContext);



  useEffect(() => {
    setLoading(true);
    console.log(query.get("customer_id"))
    emailUnSubscribe(query.get("customer_id"));
    // getPublicQuote(query.get("quote_id"), query.get("qtoken"))
  }, [])

  return (
   <div>
    {loading == true ? <LinearProgress /> :
    <Container fluid className="main-content-container px-4">
    <Row className="justify-content-center align-items-center">
      {/* <Col lg={8}>
          <h1>
           You are now subscribed to QuoteShark emails.
          </h1>
      </Col> */}
    </Row>
  </Container>}

   </div>
  );
};

export default UnSubscribePage;
